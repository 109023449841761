import React from 'react';
import { connect } from 'react-redux'
import store from 'main/store/store'
import * as module from './module';
import * as stepperModule from 'warranties/stepper/module';
import { withRouter } from 'react-router-dom'

import Grid from '@material-ui/core/Grid';
import EmailIcon from '@material-ui/icons/Email';
import classes from 'classnames'

import FormGroup from 'assets/forms/FormGroup'
import FormPanel from 'assets/forms/FormPanel'
import {FormCaption} from 'assets/forms/FormText'
import {Caption as CaptionText} from 'assets/Text'

import style from '../forms.module.scss'

import {form as formSpecs, schema as formSchema} from './form'
import generateFormComponent from 'warranties/forms/FormComponent'

import {setWarrantyFromDraft} from 'warranties/stepper/utility'

const FormComponent = generateFormComponent(formSpecs, formSchema)

const Caption = (props) => (
  <CaptionText className={style['caption']} {...props}/>
)

class SummaryFormComponent extends FormComponent {

  render = () => {
    const CheckboxField = this.CheckboxField
    const EmailField = this.EmailField

    const currentState = store.getState()
    const {program, program_upgrades, coverage, coverage_upgrades, draft, covered} = stepperModule.selector(currentState).data
    const client = currentState['Auth']['user']

    if (program && draft && covered && client ) {
      const Blank = () => (<div>&nbsp;</div>)

      // Lets get our selected program upgrade, if one exists
      const program_upgrades_obj = program_upgrades.reduce((result, upgrade) => {
        result[upgrade.pk] = upgrade
        return result
      }, {})

      const selected_upgrade = (draft.program_upgrade &&
        program_upgrades_obj[draft.program_upgrade]
      )

      // Lets get our selected coverage upgrade, if one exists
      const coverage_upgrades_obj = coverage_upgrades.reduce((result, upgrade) => {
        result[upgrade.pk] = upgrade
        return result
      }, {})

      const selected_coverage_upgrade = (draft.coverage_upgrade &&
        coverage_upgrades_obj[draft.coverage_upgrade]
      ) || coverage

      const term = selected_upgrade ? selected_upgrade.term : program.term
      const mileage = selected_upgrade ? selected_upgrade.mileage : program.mileage

      const programName = selected_upgrade ? selected_upgrade.name : program.name
      const programYears = term / 12
      const programMiles = mileage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      const programPrice = draft.quote


      const detailColumnsLeft = [
        ['Warranty', programName],
        ['Coverage', selected_coverage_upgrade.name],
        ['Term', `${term} Months`],
        ['Mileage', `${mileage.toLocaleString('en-US')} Miles`],
        ['VIN', draft.vehicle_vin],
        ['Vehicle', `${draft.vehicle_year} ${draft.vehicle_make} ${draft.vehicle_model} ${draft.vehicle_trim}`],
        ['Restyler', client.company],
        [<Blank/>, `${client.street}, ${client.city}, ${client.state} ${client.zip}`],
        ['Dealer', draft.dealer_name],
        [<Blank/>, `${draft.dealer_street}, ${draft.dealer_city}, ${draft.dealer_state} ${draft.dealer_zip}`],
        ['Consumer', draft.consumer_name || 'N/A'],
        [<Blank/>, ([
          draft.consumer_street,
          draft.consumer_city,
          draft.consumer_state,
          draft.consumer_zip].every((element) => (!!element)) && (
           `${draft.consumer_street}, ${draft.consumer_city}, ${draft.consumer_state} ${draft.consumer_zip}`
         )) || <Blank/>],
      ]

      const detailColumnsRight = [
        ['Suspension', (covered.Suspension && (covered.Suspension.join(', '))) || 'N/A'],
        ['Tire', (covered.Tire && (covered.Tire.join(', '))) || 'N/A'],
        ['Wheel', (covered.Wheel && (covered.Wheel.join(', '))) || 'N/A'],
        ['Engine', (covered.Engine && (covered.Engine.join(', '))) || 'N/A'],
        ['Exhaust', (covered.Exhaust && (covered.Exhaust.join(', '))) || 'N/A'],
        ['Accessories', (covered.Accessories && (covered.Accessories.join(', '))) || 'N/A'],
      ]

      return (
        <Grid container spacing={24} justify="center" className={classes(style['form'], style['summary'])}>
          <Grid item xs={12}>
            <h6>Confirm Warranty Details</h6>
          </Grid>
          <FormPanel xs={12} title={`Warranty:  ${programName}`}>
            <Grid container>
              <Grid item xs={12} lg={6}>
                {detailColumnsLeft.map((item, index) => (
                  <Grid key={index} container spacing={8}>
                    <Grid className={style['warranty-column-1']} item xs={4}>
                      <Caption key={index}>{item[0]}</Caption>
                    </Grid>
                    <Grid className={style['warranty-column-2']} item xs={8}>
                      <Caption key={index}>{item[1]}</Caption>
                    </Grid>
                  </Grid>
                ))}
              </Grid>

              <Grid item xs={12} lg={6}>
                {detailColumnsRight.map((item, index) => (
                  <Grid key={index} container spacing={8}>
                    <Grid className={style['warranty-column-1']} item xs={4}>
                      <Caption key={index}>{item[0]}</Caption>
                    </Grid>
                    <Grid className={style['warranty-column-2']} item xs={8}>
                      <Caption key={index}>{item[1]}</Caption>
                    </Grid>
                  </Grid>
                ))}
              </Grid>

              <Grid item xs={12} lg={6}>
              <br/>
                <Grid container spacing={8}>
                  <Grid className={style['warranty-column-1']} item xs={4}>
                    <Caption>Price</Caption>
                  </Grid>
                  <Grid className={style['warranty-column-2']} item xs={8}>
                    <Caption bold>{programPrice}</Caption>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FormPanel>
          <FormPanel xs={12}
            title={<Grid container alignItems='center'>
              <Grid item>
                <EmailIcon fontSize='default'/>
              </Grid>
              <Grid item>
                <span>Email Options</span>
              </Grid>
            </Grid>}>
            <Grid container spacing={8}>
              <FormCaption xs={12}>Send Warranty Document To:</FormCaption>
              <FormGroup type='checkbox' md={12} spacing={0}>
                {(draft.dealer_email &&
                  <CheckboxField xs={12} name='email_send_to_dealer'>
                    <FormCaption>Dealer</FormCaption>
                      <Caption>{draft.dealer_email.split(',').join(', ')}</Caption>
                  </CheckboxField>
                ) ||
                  <Caption>Warranty Document will not be sent to the Dealership.  (No Dealership emails specified)</Caption>
                }
                {(draft.consumer_email &&
                  <CheckboxField xs={12} name='email_send_to_consumer'>
                    <FormCaption>Consumer</FormCaption>
                    <Caption>{draft.consumer_email.split(',').join(', ')}</Caption>
                  </CheckboxField>
                ) ||
                  <Caption>Warranty Document will not be sent to the Consumer.  (No Consumer emails specified)</Caption>
                }
              </FormGroup>
              <EmailField xs={12} lg={8} type="text" multiline={true} rows={3} name='email_additional_emails'/>
            </Grid>
          </FormPanel>
        </Grid>
      )
    }
    else {
      return (
        <Grid container spacing={24} justify="center" className={classes(style['form'], style['summary'])}>
          <Grid item md={12}>
            <h6>Confirm Warranty Details</h6>
          </Grid>
          <FormPanel md={10}>
          </FormPanel>
        </Grid>
      )
    }
  }
}
const form = connect(module.mapState, module.mapDispatch)(withRouter(SummaryFormComponent))
export {module, form}
export default form
