import React, {Component} from 'react';
import $ from "jquery";
import * as Sentry from '@sentry/browser';
import Grid from '@material-ui/core/Grid';
import logo from 'static/logo_large.png'
import ConfirmDialog from 'assets/dialogs/Confirm'
import Button from 'assets/Button'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import store from 'main/store/store'
import * as authModule from 'auth/module'
import {persistor} from 'main/store/store';

if (!process.env.REACT_APP_DISABLE_SENTRY) {
  Sentry.init({dsn: process.env.REACT_APP_SENTRY_DSN});
}

class SentryBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      eventId: null,
      env: process.env.NODE_ENV,
      showFeedback: false
    };
  }


  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error};
  }


  isDevelop() {
    return this.state.env != 'production'
  }

  componentDidCatch(error, errorInfo) {
    const authState = authModule.selector(store.getState())
    Sentry.withScope(scope => {
      scope.setUser({"email": authState.user.email});
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId })
    });
  }

  onLogout = async (e) => {
    authModule.mapDispatch(store.dispatch).clear_version_mismatch()
    await persistor.flush()
    localStorage.clear()
    window.location = '/logout'
  }

  reportFeedback = (e) => {
    const authState = authModule.selector(store.getState())
    const authDispatch = authModule.mapDispatch(store.dispatch)
    authDispatch.logout()
    persistor.purge()

    this.setState({
      showFeedback: true
    })

    Sentry.showReportDialog({
      eventId: this.state.eventId,
      user: {
        email: authState.user.email,
        name: authState.user.company
      }
    })
  }

  render() {
    const showError = !!this.state.error && !this.state.showFeedback
    const versionMismatch = this.state.error == 'version mismatch'

    let title = 'An Error Occurred'
    let message = <div>
      Apologies, it appears something went wrong.<br/>
      Our development team has been notified.<br/><br/>
      Please try
      <ul>
        <li>Refreshing the page</li>
        <li>Logging out and logging back in.</li>
        <li>Or if this reoccurs, please click Report Feedback.</li>
      </ul>
    </div>

    let showLogout = true
    let logoutText = 'Logout'
    let showFeedback = true

    if (versionMismatch) {
      title = 'IDA Application Update'
      message = <div>We recently updated our application.<br/>
        Please click 'Update' and relogin.<br/>
        Apologies for the inconvenience.
      </div>

      logoutText = 'Update'
      showFeedback = false
    }

    return (
      <div>
        {!showError && this.props.children}
        <Dialog style={{zIndex: 900}}
          fullScreen={false}
          disableBackdropClick={true}
          open={showError}
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <DialogContentText>
                {message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {showLogout &&
              <Button onClick={this.onLogout} color="secondary">
                {logoutText}
              </Button>
            }
            {showFeedback &&
              <Button
                onClick={this.reportFeedback}
                color="secondary"
                >
                  Report Feedback
              </Button>
            }
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default SentryBoundary
