import * as DealershipModule from 'warranties/forms/dealership/module'
import * as VehicleModule from 'warranties/forms/vehicle/module'
import * as ConsumerModule from 'warranties/forms/consumer/module'
import * as ModificationsModule from 'warranties/forms/modifications/module'
import * as WarrantyModule from 'warranties/forms/warranty/module'
import * as SummaryModule from 'warranties/forms/summary/module'

export default {
  DealershipModule,
  VehicleModule,
  ConsumerModule,
  ModificationsModule,
  WarrantyModule,
  SummaryModule
}
