import settings from './settings'
import store from 'main/store/store'
import {mapDispatch as asyncMapDispatch} from 'main/utils/async/module'
import {mapDispatch as authMapDispatch} from 'auth/module'

import {selector} from 'auth/module'
import * as token from 'auth/token'

import * as Sentry from '@sentry/browser';

const APIbase = `/${settings.urlBase}/${settings.version}`
const Async = asyncMapDispatch(store.dispatch)

const parseResult = (result) => {
  let message = ''
  if (result && typeof result === 'object') {
    for (let [key, value] of Object.entries(result)) {
      message += `--- ${key}: ${value}`
    }
  }
  return message
}

const logToSentry = ({error, url, options, response, result}) => {
  const extra = {
    url,
    options,
    response: {
      url: response.url,
      status: response.status,
      statusTexT: response.statusText,
      result: result
    }
  }

  const authState = selector(store.getState())
  Sentry.withScope(scope => {
    scope.setUser({"email": authState.user.email});
    scope.setExtras(extra);
    const eventId = Sentry.captureException(error);
  });
}


const getAuthHeaders = async () => {
  let authState = selector(store.getState())
  const isAuthenticated = await token.isAuthenticated(authState)
  if (isAuthenticated) {
    authState = selector(store.getState())
    return {Authorization: `JWT ${token.accessToken(authState)}`}
  }
  else {
    window.location = '/'
  }
}

const doFetch = async ({url, data, options, auth=true, raw=false}) => {
  let result
  let response
  Async.begin_request()

  options['headers'] = {
    ...(options['headers'] || {}),
    "Content-Type": "application/json",
  }

  if (auth) {
    const authHeaders = await getAuthHeaders()

    options['headers'] = {
      ...(options['headers']),
      ...authHeaders,
    }
  }

  response = await fetch(`${APIbase}${url}`, options)

  if (raw) {
    Async.end_request({response, error:{}})
    return {response, result: {}}
  }
  try {
    result = await response.text()
  } catch (error) {
    // There was probably an error with fetch usage above
    logToSentry({error, url, options, response})
    Async.end_request({response, error})
    return {response, result}
  }

  try {
    result = JSON.parse(result)
  } catch (error) {
    // response was not JSON
    logToSentry({error, url, options, response})
    Async.end_request({response, error})
    return {response, result: {}}
  }

  // Server probably returned JSON
  Async.end_request({response})

  // Log client-side HTTP errors to sentry
  if ( response.status === 400 || response.status >= 500 ) {
    const message = parseResult(result)
    const error = new Error(`Client HTTP error ${response.status} ${response.statusText} ${message}`)
    logToSentry({error, url, options, response, result})
  }
  return {response, result}

}

const get = ({url, data={}, auth=true, raw=false}) => {
  const options = {

  }
  return doFetch({url, data, options, auth, raw})
}

const post = ({url, data={}, auth=true}) => {
  const options = {
    method: "POST",
    body: JSON.stringify(data)
  }
  return doFetch({url, data, options, auth})
}

const del = ({url, data={}, auth=true}) => {
  const options = {
    method: "DELETE",
    body: JSON.stringify(data)
  }
  return doFetch({url, data, options, auth})
}

export {doFetch, get, post, del}
