import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { CloudDownload, Warning } from '@material-ui/icons';

import { mapState, mapDispatch } from './module';
import * as authModule from 'auth/module'
import * as configModule from 'config/module'

import style from './home.module.scss'
import {H6, Caption, Overline} from 'assets/Text'

import Paper from 'assets/panels/Panel'
import {NavButton} from 'assets/Button'

// Control Context Panel
import store from 'main/store/store'
import {mapDispatch as contextPanelMapDispatch} from 'main/utils/contextPanel/module'

import {selector as authSelector} from 'auth/module'

const contextPanelDispatch = contextPanelMapDispatch(store.dispatch)
const markup = ReactDOMServer.renderToStaticMarkup

class Home extends Component {
  constructor(props) {
    super(props)
    contextPanelDispatch.set_left_panel({
      title: markup(<p>Getting<br/>Started</p>),
      content:  markup(
<p>Welcome to the IDA warranties site.<br/><br/>

This site allows you to quickly create new warranties for your restyled projects</p>
      ),
    })

    contextPanelDispatch.set_right_panel({
      title: markup('Need Help?'),
      content: markup(
<p>Questions / comments / concerns? <br/>
Contact us now:<br/>
support@innovativedealeralliance.com<br/>
844.IDA.0568</p>
      )
    })
  }

  componentDidMount = async () => {
    const {response, result} = await window.IDA.api.me()
    const me = result
    const authDispatch = authModule.mapDispatch(store.dispatch)
    authDispatch.set_user(me)

    contextPanelDispatch.set_left_header(
      <p>Welcome,<br/>
        {me.client.name}
      </p>
    )
  }

  page = "Home"


  render() {
    const {user} = authSelector(store.getState())
    const config = configModule.selector(store.getState())

    let openOrder = <Caption>No Open Orders</Caption>

    if (!!config.open_order) {
      const order = config.open_order
      const order_date = new Date(order.created)
      const label = order.warranties.length > 1 ? 'Warranties' : 'Warranty'

      openOrder = (
        <NavButton key={order.id} to={`/orders/${order.id}`} color='secondary'>
          <Grid container alignItems='center' spacing={8}>
            <Grid item>
              <Warning/>
            </Grid>
            <Grid item>
              <Caption>You have a pending order containing {order.warranties.length} {label}</Caption>
            </Grid>
          </Grid>
        </NavButton>
      )
    }

    const userInfo = [
      {label: 'Company', value: user.company},
      {label: 'Account #', value: user.account},
      {label: 'Address', value: <div>{`${user.street}`}<br/>{`${user.city}, ${user.state} ${user.zip}`}</div>},
      {label: 'Primary Email', value: user.email},
      {label: 'Additional Emails', value: user.alternate_emails.join(', ')},
    ]

    return (
      <Grid container direction="row" justify="center" spacing={16}>
        <Grid item xs={12}>
          <h5>{this.page}</h5>
        </Grid>

        <Grid item xs={10} lg={5}>
          <Paper title="Your Account">
            {userInfo.map((item, index) => (
                <Grid key={index} container spacing={8}>
                  <Grid className={style['account-column-1']} item xs={4}>
                    <Caption>{item.label}:</Caption>
                  </Grid>
                  <Grid className={style['account-column-2']} item xs={8}>
                    <Caption>{item.value}</Caption>
                  </Grid>
                </Grid>
            ))}
            <Grid container alignItems="center" justify="center" spacing={8}>
              <Grid className={style['account-column-1']} item xs={12}>
                <NavButton to="/account" color="secondary">
                  <Caption> Edit Account</Caption>
                </NavButton>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={10} lg={7}>
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Paper title="Open Orders">
                {openOrder}
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper title="Recent Warranties">
                <Grid container spacing={16}>
                  <Grid className={style['recentWarrantyHeader']} item xs={3}><Overline>Date</Overline></Grid>
                  <Grid className={style['recentWarrantyHeader']} item xs={6}><Overline>Warranty</Overline></Grid>
                  <Grid className={style['recentWarrantyHeader']} item xs={3}><Overline>PDF</Overline></Grid>
                  <Grid item xs={12}>
                  {config.recent_warranties.length > 0 ?
                    config.recent_warranties.slice(0, 5).map((warranty, index) => {
                      const warranty_date = new Date(warranty.created)
                      const href = warranty.document && warranty.document.file
                      return (
                        <Grid container spacing={8} key={warranty.warranty_number}>
                          <Grid item xs={3}><Caption>{warranty_date.toLocaleDateString()}</Caption></Grid>
                          <Grid item xs={6}>
                            <Caption>
                              {warranty.program.name} <br/>{warranty.vehicle.year} {warranty.vehicle.make} {warranty.vehicle.model} {warranty.vehicle.trim} {warranty.warranty_number}
                            </Caption>
                          </Grid>
                          <Grid item xs={3}>
                            <Button
                              href={href}
                              disabled={!href}
                              color='secondary'
                              target='_blank'
                            ><CloudDownload/>
                            </Button>
                          </Grid>
                        </Grid>
                      )
                    })
                    :
                    <Caption>No Recent Warranties</Caption>
                  }
                </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default connect( mapState, mapDispatch)(Home);
