import React, {Component} from 'react'
import { connect } from 'react-redux'
import { mapState, mapDispatch } from './module';
import styles from './async.module.scss'


class AsyncComponent extends Component {
  constructor(props) {
    super(props);
    this.onBegin = this.props.onBegin
    this.onEnd = this.props.onEnd
    this.onError = this.props.onError
  }

  componentDidUpdate = (prevProps) => {
    const asyncStarted = this.props.active && !prevProps.active
    const asyncEnded = !this.props.active && prevProps.active

    if (asyncStarted) {
      this.onBegin && this.onBegin(this.props)
    }
    else if (asyncEnded) {
      if (!this.props.response || this.props.response.status !== 200 || this.props.error) {
        this.onError && this.onError(this.props)
      }
      this.onEnd && this.onEnd(this.props)
    }
  }

  render = () => {
    const hiddenMessage = this.props.hiddenMessage || 'Loading....'
    return (
      <div>
        {this.props.hidden && this.props.hiddenMessage !== false &&
          <span>{hiddenMessage}</span>
        }
        <div className={(this.props.hidden ? styles['hidden'] : '') + ' ' +
                        (this.props.superHidden ? styles['super-hidden'] : '')}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

AsyncComponent.propTypes = {

}

export default connect(mapState, mapDispatch)(AsyncComponent)
