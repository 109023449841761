import store from 'main/store/store'
import FormModules from 'warranties/forms/Forms'
import * as StepperModule from 'warranties/stepper/module'
import MomentUtils from '@date-io/moment';

export const setWarrantyFromDraft = async ({draft, activeOnly=true, edit=false, onlySpecified=false}) => {

  const currentState = store.getState()

  if (edit) {
    StepperModule.mapDispatch(store.dispatch).set_edit_draft(draft)

    if (draft.processed) {
      draft['vehicle_vin_validated'] = true
      draft['confirmation'] = true
    }
  }

  for (const [key, module] of Object.entries(FormModules)) {
    const {mapDispatch, form, selector} = module
    const formDispatcher = mapDispatch(store.dispatch)
    const formState = selector(currentState)
    if (!formState.active && activeOnly) {
      continue
    }

    if (edit) {
      formDispatcher.clear_state()
    }

    if (draft.processed && edit) {
      if (!draft.vehicle_sold && module.slice === 'FormConsumer') {
        formDispatcher.set_skip()
      }
      else {
        formDispatcher.set_completed()
      }
    }

    let draftValues = Object.keys(form).map((field) => ({field, value: draft[field]}))

    if (onlySpecified) {
      draftValues = Object.keys(draft).map((field) => ({field, value: draft[field]}))
    }

    for (const {field, value} of draftValues) {
      const schemaDescription = form[field].joi.describe()
      const tags = schemaDescription.tags || []
      switch(schemaDescription.type) {
        case 'string':
          if (tags.includes('auto')) {
            if (tags.includes('async') || draft.processed && edit) {
              formDispatcher.set_autocomplete_value({field, value: {value, label: value} })
              break;
            }
            else {
              const suggestions = form[field].suggestions
              const selection = suggestions.find((suggestion) => (value.trim().toLowerCase() === suggestion.value.trim().toLowerCase()))
              formDispatcher.set_autocomplete_value({field, value: selection })
              break;
            }
          }
          formDispatcher.set_value({field, value: value || ''})
          break
        case 'date':
          const moment = new MomentUtils()
          const dateValue = moment.parse(value)
          if (dateValue.isValid()) {
            formDispatcher.set_date_value({field, value: dateValue})
          }
          else {
            formDispatcher.set_value({field, value: null})
            formDispatcher.set_entries_value({field, value: undefined})
          }
          break
        case 'array':
          formDispatcher.load_email_value({field, value: value || ''})
          break
        case 'boolean':
          formDispatcher.set_select_value({field, value: !!value})
          break
        default:
          formDispatcher.set_value({field, value: value || null})
          break;
      }
    }
  }
}
