import React, {Component} from 'react';
import ReactDOMServer from 'react-dom/server';
import { connect } from 'react-redux'
import * as module from './module';
import { withRouter } from 'react-router-dom'
import store from 'main/store/store'

import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormPanel from 'assets/forms/FormPanel'
import {FormCaption, FormSubtitle} from 'assets/forms/FormText'
import {Caption} from 'assets/Text'
import FormGroup from 'assets/forms/FormGroup'

import style from '../forms.module.scss'
import {initializeForm} from 'warranties/forms/utilities'

import {selector as stepperSelector} from 'warranties/stepper/module';

import {form as formSpecs, schema} from './form'
import generateFormComponent from 'warranties/forms/FormComponent'
import _ from 'underscore'

const FormComponent = generateFormComponent(formSpecs, schema)

const markup = ReactDOMServer.renderToStaticMarkup


class WarrantyFormComponent extends FormComponent {

  componentDidMount = () => {
    this.setState({
        valid: false,
        program: {
          name: '',
          term: 0,
          mileage: 0,
          price: 0
        },
        draft: {},
        covered: {
          Engine: [],
          Exhaust: [],
          Suspension: [],
          Tire: [],
          Wheel: [],
          Accessories: [],
        },
        coverage: {
          name: ''
        },
        selected_coverage: {
          name: '',
          default: false
        }
      }
    )
    const data = stepperSelector(store.getState()).data
    const {program, program_upgrades, coverage, coverage_upgrades, coverage_pricing, draft, covered, valid, message} = data

    this.setState({program, program_upgrades, coverage, coverage_upgrades, coverage_pricing, draft, covered, valid, message})

    if (valid) {
      const programYears = program.term / 12
      const programMiles = program.mileage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      const context = this.props.context
      context['left']['content'] = markup(
        <div>
          <p>Based on the vehicle and the mods chosen, we have selected <b>{program.name} - {programYears} Years / {programMiles} miles</b> for your product.</p>
          <p></p>
        </div>
      )
      this.props.set_context(context)
    }
    else {
      const context = this.props.context
      context['left']['content'] = markup(
        <div>
          <p>{message}</p>
        </div>
      )
      this.props.set_context(context)
    }

    // set default coverage
    const default_coverage = coverage_upgrades.find( coverage => coverage['default'] === true)

    if ( this.props.values.coverage_upgrade === null) {
      this.props.set_value({field: 'coverage_upgrade', value: default_coverage['pk']})
    }

    this.props.set_active()
    // Set panel context
    initializeForm(this.props)

    //Create debounced validation functions for each field
      this.validationDebouncers = {}
      for (const [field, fieldSpec] of Object.entries(this.state.formSpecs)) {
        this.validationDebouncers[field] = _.debounce((resolve) => {
          this.props.VALIDATE_FORM()
          this.props.SET_FIELD_ERRORS(field)
          resolve(this.props.values[field])
        }, 250)
      }
  }

  render = () => {
    const {valid, message} = this.state
    const RadioField = this.RadioField
    const CheckboxField = this.CheckboxField

    if (valid) {
      const program = this.state.program
      const program_upgrades = this.state.program_upgrades

      // Lets get our selected upgrade, if one exists
      //  start by creating an object array indexed by pk
      const program_upgrades_obj = program_upgrades.reduce((result, upgrade) => {
        result[upgrade.pk] = upgrade
        return result
      }, {})

      const selected_upgrade = (this.props.values.program_upgrade.value &&
        program_upgrades_obj[this.props.values.program_upgrade.value]
      )

      const coverage = this.state.coverage
      const coverage_upgrades = this.state.coverage_upgrades
      const coverage_pricing = this.state.coverage_pricing

      // Do the same as above for Coverage
      const coverage_upgrades_obj = coverage_upgrades.reduce((result, coverage) => {
        result[coverage.pk] = coverage
        return result
      }, {})

      const selected_coverage = (this.props.values.coverage_upgrade.value &&
        coverage_upgrades_obj[this.props.values.coverage_upgrade.value]
      )

      const coverage_name = selected_coverage ? selected_coverage.name : coverage.name

      // Logic for updating Quote text
      const draft = this.state.draft
      const {Suspension, Tire, Wheel, Exhaust, Engine, Accessories} = this.state.covered

      const coveredItems = [
        !!Engine.length && <Caption key='engine'>Engine: {Engine}</Caption>,
        !!Exhaust.length && <Caption key='exhaust'>Exhaust: {Exhaust}</Caption>,
        !!Suspension.length && <Caption key='suspension'>Suspension: {Suspension}</Caption>,
        !!Tire.length && <Caption key='tire'>Tire: {Tire.join(' x ')}</Caption>,
        !!Wheel.length && <Caption key='wheel'>Wheel: {Wheel.join(' x ')}</Caption>,
        !!Accessories.length && <Caption key='accessories'>Accessories: {Accessories.join(', ')}</Caption>,
      ]

      const reducer = (accumulator, currentValue) => (accumulator + (currentValue !== false ? 1 : 0))
      const coveredItemsLength = coveredItems.reduce(reducer, 0)

      const term = selected_upgrade ? selected_upgrade.term : program.term
      const mileage = selected_upgrade ? selected_upgrade.mileage : program.mileage

      const programName = selected_upgrade ? selected_upgrade.name : program.name
      const programYears = term / 12
      const programMiles = mileage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      // alternate pricing system when selected_coverage is true
      //  we are calculating it here on the frontend as this step in the form
      //  is not synchronizing with the server on form changes.  perhaps in the
      //  future this can change.
      let programPrice = selected_upgrade ? selected_upgrade.price : program.price
      if ( selected_coverage ) {

        // search for correct coverage based on selection
        const coverage_pricing_array = coverage_pricing.reduce( (result, pricing) => {
          if ( pricing.coverage.pk === selected_coverage.pk ) {
            result.push(pricing)
          }
          return result
        }, [])

        let search_result

        // search for correct pricing for program_upgrade, if selected
        if (selected_upgrade ) {
          search_result = coverage_pricing_array.find( (pricing_element) => {
            return (
              pricing_element.program_upgrade &&
              pricing_element.program_upgrade.pk === selected_upgrade.pk
            )
          })
        }

        // else default to correct pricing for program, if no selected upgrade
        else {
          search_result = coverage_pricing_array.find( (pricing_element) => {
            return (
              pricing_element.program.pk === program.pk
            )
          })
        }

        programPrice = search_result && search_result.price
      }


      const vehicleName = `${draft.vehicle_year} ${draft.vehicle_make} ${draft.vehicle_model} ${draft.vehicle_trim}`
      return (
        <Grid container spacing={24} justify="center" className={style['form']}>
          <Grid item md={12}>
            <h6>Your Warranty</h6>
          </Grid>
          <FormPanel xs={12} title="Quote" accentBorder>
            <Grid container spacing={8}>
              <FormSubtitle xs={8}>{programName} - {programYears} Years / {programMiles} Miles</FormSubtitle>
                { (selected_upgrade || selected_coverage) &&
                  <Grid item xs={3} className='text-align-right'>
                    <FormCaption className="text-align-right">(Upgraded Warranty)</FormCaption>
                  </Grid>
                }
              <Grid item xs={12}></Grid>
              <Grid item xs={12}>
                <Caption>{vehicleName}</Caption>
              </Grid>

              <Grid className={style['warranty-column-1']} item xs={4} lg={2}>
                <Caption className={style['covered']}>Coverage</Caption>
                <Caption className={style['covered']}>Term</Caption>
                <Caption className={style['covered']}>Mileage</Caption>
                {[...Array(coveredItemsLength)].map((e, i) => (<Caption key={i} className={style['covered']}>Approved Mods</Caption>))}
              </Grid>
              <Grid className={style['warranty-column-2']} item xs={8} lg={10}>
                <Caption>{coverage_name}</Caption>
                <Caption>{term} Months</Caption>
                <Caption>{mileage.toLocaleString('en-US')} Miles</Caption>
                {coveredItems}
              </Grid>
              <Grid item xs={12}><br/></Grid>
              <Grid item xs={11}>
                <FormCaption className="text-align-right"> Total: ${programPrice}</FormCaption>
              </Grid>
            </Grid>

          </FormPanel>
          {coverage_upgrades.length > 0 &&
            <FormPanel xs={12} md={6} title="Coverage Upgrades">
              <Grid container spacing={8}>
                <FormGroup xs={12} type='radio' spacing={0}>
                  {coverage_upgrades.map((coverage, index) => {

                    return (
                      <RadioField nullable labelclassname={style['warranty-form-label']} rootclassname={style['warranty-form-label']} key={index} xs={12} value={coverage.pk} name='coverage_upgrade'>
                        <Grid container spacing={0}>
                          <Grid item xs={12}>
                            <FormSubtitle xs={12}>{coverage.name}<br/></FormSubtitle>
                          </Grid>
                        </Grid>
                      </RadioField>
                    )
                  })
                }
                </FormGroup>
              </Grid>

            </FormPanel>
          }
          {program_upgrades.length > 0 &&
            <FormPanel xs={12} md={6} title="Term & Mileage Upgrades">
              <Grid container spacing={8}>
                <FormGroup xs={12} type='radio' spacing={0}>
                  {program_upgrades.map((upgrade, index) => {

                    const term = upgrade.term / 12;
                    const mileage = upgrade.mileage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                    return (
                      <RadioField nullable labelclassname={style['warranty-form-label']} rootclassname={style['warranty-form-label']} key={index} xs={12} value={upgrade.pk} name='program_upgrade'>
                        <Grid container spacing={0}>
                          <Grid item xs={12}>
                            <FormSubtitle xs={12}>{upgrade.name} - {term} Years / {mileage} Miles<br/></FormSubtitle>
                          </Grid>
                        </Grid>
                      </RadioField>
                    )
                  })
                }
                </FormGroup>
              </Grid>
            </FormPanel>
          }
          <FormPanel noBg noBorder xs={12}>
              <Grid container justify='flex-start'>
                <FormGroup type='checkbox' xs={12} lg={6}>
                  <CheckboxField xs={6} lg={12} name='confirmation' value='True'>I Confirm All Warranty Information Is Correct</CheckboxField>
                  <FormHelperText error>{this.props.values['confirmation'].message}</FormHelperText>
                </FormGroup>
              </Grid>
          </FormPanel>
        </Grid>
      )
    }
    else {
      return (
        <Grid container spacing={24} justify="center" className={style['form']}>
          <Grid item xs={12}>
            <h6>Your Warranty</h6>
          </Grid>
          <FormPanel xs={12} title="Quote">
            <Grid container spacing={8}>
              <FormSubtitle xs={12}>{message}</FormSubtitle>
            </Grid>
          </FormPanel>

        </Grid>
      )
    }
  }
}

const form = connect(module.mapState, module.mapDispatch)(withRouter(WarrantyFormComponent))
export {module, form}
export default form
