import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';


const theme = createMuiTheme({
    palette: {
      default: 'white',
      primary: {
        main: '#255884',
        light: '#A7A9AC',
        error: '#f44336'
      },
      secondary: {
        main: '#0086C0',
        light: '#BABBB1',
      },
    },
    typography: {
      useNextVariants: true,
      fontFamily: [
        'Roboto',
        'Arial',
        'Helvetica',
        'sans-serif',
      ].join(','),
      fontWeightMedium: 500,
      /*
      h1: {
        fontSize: 48
      },
      h2: {
        fontSize: 60,
      },
      h3: {
        fontSize: 48,
      },
      h4: {
        fontSize: 34,
      },
      h5: {
        fontSize: 24,
      },
      h6: {
        fontFamily: 'Montserrat',
        fontSize: 20
      },
      body: {
        fontSize: 18,
      },
      subtitle1: {
        fontFamily: 'Montserrat',
        fontSize: 16,
        lineHeight: 18
      },
      subtitle2: {
        fontSize: 14,
      },
      button: {
        fontFamily: 'Montserrat',
        fontSize: 14,
        lineHeight: 16,
        fontWeight: 'bold',
        textTransform: 'uppercase',
      },
      caption: {
        fontSize: 12,
        lineHeight: 14,
        letterSpacing: 0.4,
      },
      overline: {
        fontSize: 10,
        lineHeight: 16,
        textTransform: 'uppercase',
      }
      */
    },
  });

const IDATheme = (props) => {
  return (
    <MuiThemeProvider theme={theme}>
      {props.children}
    </MuiThemeProvider>
  )
}

const createSnackbarStyle = (theme) => {
  return {
    success: {
      backgroundColor: green[600],
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    info: {
      backgroundColor: theme.palette.secondary.main,
    },
    warning: {
      backgroundColor: amber[700],
    },
    icon: {
      fontSize: 20,
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing.unit,
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
  }
}
export const snackbarStyle = createSnackbarStyle(theme)

export default IDATheme
