import React, { useEffect, useState, Component } from 'react';
import { connect } from 'react-redux'
import { Redirect, Switch, NavLink, useHistory, withRouter } from 'react-router-dom'
import Route from 'main/utils/route/Route'

import { mapState, mapDispatch } from './module';
import store from 'main/store/store'
import {mapDispatch as contextPanelMapDispatch} from 'main/utils/contextPanel/module'

import classNames from 'classnames'
import style from '../orders.module.scss'
import Grid from '@material-ui/core/Grid';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import CreateIcon from '@material-ui/icons/Create';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormButton from 'assets/forms/FormButton';

import {NavButton, FatNavButton} from 'assets/Button'

import {Caption, Subtitle2} from 'assets/Text'
import ConfirmDialog from 'assets/dialogs/Confirm'
import PaymentForm from 'orders/payments/square/loader'

import {setWarrantyFromDraft} from 'warranties/stepper/utility'

const contextPanelDispatch = contextPanelMapDispatch(store.dispatch)


class OrderDetailComponent extends Component {
  state = {
    expanded: null,
    paymentOpen: false,
  }

  componentDidMount = async () => {
    contextPanelDispatch.set_left_panel(this.props.context.left)
    contextPanelDispatch.set_right_panel(this.props.context.right)
    const orderId = this.props.match.params['id']
    const {response, result} = await window.IDA.api.orders.get(orderId)

    switch(response.status) {
      case 200:
        this.props.set_order(result)
        break;
      default:
        // Probably not an order
        this.props.history.push(`/orders`)
        break;
    }
  }

  handleExpansion = (panel) => (event, expanded) => {
    this.setState({ expanded: expanded ? panel : false })
  }

  handleOrder = async (event) => {
    this.props.open_payment()
  }

  handleTransaction = (transaction) => {
    const {response, result} = transaction
    switch(response.status) {
      case 200:
        const orderId = this.props.match.params['id']
        this.props.close_payment()
        this.props.history.push(`/orders/${orderId}/receipt`)
        break;
      case 400:
        this.props.set_error(result.messages)
      default:
        this.props.set_error(['There was an issue processing your payment.  Please contact support.'])
        break;
    }

  }

  onPaymentClose = () => {
    this.props.clear_error()
    this.props.close_payment()
  }

  onPaymentOpen = () => {

  }

  removeWarranty = ({order, warranty}) => async () => {
    const {response, result} = await window.IDA.api.orders.remove_warranty({
      order,
      warranty
    })
    this.props.set_order(result.order)
    if (!result.order) {
      this.props.history.push(`/orders/`)
    }
  }

  editWarranty = ({order, warranty}) => async () => {
    const {response, result} = await window.IDA.api.orders.get_warranty({
      order,
      warranty
    })
    const draft = result
    setWarrantyFromDraft({draft, activeOnly:false, edit:true})
    this.props.history.push(`/warranties/new`)
  }


  render() {


    const expanded = this.state.expanded
    const order = this.props.order || {}
    const warranties = order.warranties || []
    const orderHasWarranties = warranties.length > 0

    return (
      <Grid container spacing={8} alignItems='flex-end' justify='center'>
        <Grid item xs={12} md={12}>
          <h5>Order Summary</h5>
        </Grid>
        <Grid item xs={12} md={12} className='text-align-right'>
          <NavButton color="secondary" fullWidth={false} to='/warranties/new'>
            <NoteAddIcon/>Add New Warranty to Order
          </NavButton>
        </Grid>
        <Hidden smDown>
          <Grid item xs={12} md={12}>
            <Grid container alignItems='flex-end' justify='center'>
              <Grid item xs={12} md={10}>
                <Grid container className={style['header-container']}>
                  <Grid item md={3}><Caption className={style['header']}>Warranty</Caption></Grid>
                  <Grid item md={5}><Caption className={style['header']}>VIN</Caption></Grid>
                  <Grid item md={4}><Caption className={style['header']}>Vehicle</Caption></Grid>
                </Grid>
              </Grid>
              <Grid item md={1}>
                <Caption className={classNames(style['header'], 'text-align-right')}>Price</Caption>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
        {warranties.map(({id, price, program, coverage, vehicle, dealer, consumer}, index) => {
            const details1 = [
              //{label: 'Coverage', value: coverage.name},
              {label: 'Dealer', value: <div>{dealer.name}<br/>{dealer.location}</div>},
              {label: 'Consumer', value: (consumer && !!consumer.name &&
                  <div>
                      {consumer.name}<br/>
                      {consumer.location}
                  </div>
                ) || 'N/A'
              },
              {label: <span>&nbsp;</span>, value: <span>&nbsp;</span>},
              {label: 'Email To', value: dealer.emails.join(', ')},
            ]
            const details2 = [
              {label: 'Suspension', value: vehicle.suspension || 'N/A'},
              {label: 'Tire', value: vehicle.tire_diameter && vehicle.tire_width && `${vehicle.tire_diameter} ${vehicle.tire_width}` || 'N/A'},
              {label: 'Wheel', value: vehicle.wheel_diameter && vehicle.wheel_width && `${vehicle.wheel_diameter} ${vehicle.wheel_width}` || 'N/A'},
              {label: 'Engine', value: vehicle.engine || 'N/A'},
              {label: 'Exhaust', value: vehicle.exhaust || 'N/A'},
              {label: 'Accessories', value: vehicle.accessories.join(', ') || 'N/A'},
            ]
            return (
              <Grid key={index} item xs={12} md={12}>
                <Grid container alignItems='flex-end' justify='center'>
                  <Grid item  xs={12} md={10}>
                    <ExpansionPanel className={style['expansion-panel']} expanded={expanded === index} onChange={this.handleExpansion(index)}>
                       <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className={style['details']}/>}>
                         <Grid container>
                           <Grid item xs={12} md={3}><Caption>{program.name}</Caption><Caption>({coverage.name})</Caption></Grid>
                           <Grid item xs={12} md={5}><Caption>{vehicle.vin}</Caption></Grid>
                           <Grid item xs={12} md={3}><Caption>{vehicle.year} {vehicle.make} {vehicle.model}</Caption></Grid>
                           <Hidden smDown>
                             <Grid item xs={12} md={1}><Caption className={classNames('text-align-right', style['details'])}>Details</Caption></Grid>
                           </Hidden>
                         </Grid>
                       </ExpansionPanelSummary>
                       <ExpansionPanelDetails>
                         <Grid container spacing={16}>
                           <Grid item xs={6}>
                             { details1.map((detail, index) => (
                               <Grid key={index} container spacing={8}>
                                 <Grid item md={4} className={style['warranty-column-1']}>
                                   <Caption>{detail.label}</Caption>
                                 </Grid>
                                 <Grid item md={8} className={style['warranty-column-2']}>
                                   <Caption>{detail.value}</Caption>
                                 </Grid>
                               </Grid>
                             ))}
                           </Grid>
                           <Grid item xs={6}>
                             { details2.map((detail, index) => (
                               <Grid key={index} container spacing={8}>
                                 <Grid item md={4} className={style['warranty-column-1']}>
                                   <Caption>{detail.label}</Caption>
                                 </Grid>
                                 <Grid item md={8} className={style['warranty-column-2']}>
                                   <Caption>{detail.value}</Caption>
                                 </Grid>
                               </Grid>
                             ))}
                           </Grid>
                           <Grid item xs={12}>
                             <Grid container spacing={16} justify='space-between'>
                               <Grid item xs={12}>
                                 <Divider className={style['order-divider']} light variant='middle'/>
                               </Grid>
                               <Grid item xs={6} className='text-align-left'>
                                 <NavButton color='secondary' onClick={this.editWarranty({order: this.props.order.id, warranty: id})}><CreateIcon/> Edit Warranty</NavButton>
                               </Grid>
                               <Grid item xs={6} className='text-align-right'>
                                 <ConfirmDialog
                                   title='Remove Warranty from Order?'
                                   onConfirm={this.removeWarranty({order: this.props.order.id, warranty: id})}
                                   component={(props)=>(
                                     <NavButton
                                       className={style['order-delete-button']}
                                       {...props}
                                     >
                                        <DeleteForeverIcon/>Remove from Order
                                     </NavButton>
                                   )}
                                   >
                                     Are you sure you want to remove the warranty with VIN {vehicle.vin} from the order?
                                 </ConfirmDialog>
                               </Grid>
                             </Grid>
                           </Grid>
                         </Grid>
                       </ExpansionPanelDetails>
                     </ExpansionPanel>
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <Caption className='text-align-right'>{price}</Caption>
                  </Grid>
                </Grid>
              </Grid>
            )
        })}
        <Grid item xs={12} md={12}>&nbsp;</Grid>
        <Grid item xs={12} md={10} className='text-align-right'>
          <Caption bold>Total:</Caption>
        </Grid>
        <Grid item xs={12} md={1} className='text-align-right'>
          <Caption>{order.total}</Caption>
        </Grid>
        <Grid item xs={12} md={12}>&nbsp;</Grid>
        <Grid item xs={12} md={12} className='text-align-right'>
          <FatNavButton
              id='proceed-to-payment'
              type='button'
              fullWidth={false}
              onClick={this.handleOrder}
            >Proceed to Payment
          </FatNavButton>
        </Grid>
        <PaymentForm
          open={this.props.payment.open}
          onTransaction={this.handleTransaction}
          onClose={this.onPaymentClose}
        />
      </Grid>
    )
  }
}

export default connect(mapState, mapDispatch)(withRouter(OrderDetailComponent))
