import React from 'react';
import ReactDOMServer from 'react-dom/server';
import {createModule} from 'main/utils/reducers';
import commonReducers from '../commonReducers'

import {form, schema, defaults as formDefaults} from './form'


const markup = ReactDOMServer.renderToStaticMarkup

const module = createModule({
  slice: 'FormSummary',
  initialState: {
    name: 'Summary',
    nextText: 'Add Warranty to Order',
    active: false,
    completed: false,
    error: false,
    disabled: true,
    locked: false,
    skip: false,
    message: '',
    optional: false,
    entries: {},
    valueDefaults: formDefaults,
    values: formDefaults,
    context: {
      left: {
        title: markup(<p>Step 6: <br/>Warranty Summary</p>),
        content: markup(
          <div>
            <p>Confirm your warranty details to add them to your order.</p>
          </div>
        )
      },
      right: {
        title: markup('E-Mailing Your Warranty'),
        content: markup(
  <p>To E-Mail your warranty document to other parties, specify your preferences in the Email Options.

  Your E-mail Dealer preference is saved for future orders.</p>
        )
      }
    }
  },
  reducers: {
    ...commonReducers(schema),
  },
  extraReducers: {
    'WarrantySteps/setDraft': (state, action) => {
      const valid = action.payload.valid
      state.locked = !valid
      state.disabled = !valid
    }
  }
})

export const { mapState, mapDispatch, slice, reducer, selector } = module
export {form, schema, formDefaults}

export default module.reducer
