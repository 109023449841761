import {createModule} from 'main/utils/reducers';

const module = createModule({
  slice: 'Route',
  initialState: {
    authState: {}
  },
  reducers: {}
})

export const { mapState, mapDispatch, slice, reducer, selector } = module

export default module.reducer
