import React, {Component} from 'react';
import { connect } from 'react-redux'
import * as module from './module';

import Grid from '@material-ui/core/Grid';
import {FormEmail} from 'assets/forms/FormInput'

import style from '../forms.module.scss'
import {form as formSpecs, schema} from './form'
import generateFormComponent from 'warranties/forms/FormComponent'


const FormComponent = generateFormComponent(formSpecs, schema)


class ConsumerFormComponent extends FormComponent {

  render = () => {
    const TextField = this.TextField
    const EmailField = this.EmailField
    const StatesField = this.AutocompleteField

    return (
      <Grid container spacing={24} className={style['form']}>
        <Grid item md={12}>
          <h6>Enter Consumer Information</h6>
        </Grid>
        <TextField xs={12} lg={8} name='consumer_name'/>
        <TextField xs={6} lg={5} name='consumer_street'/>
        <TextField xs={6} lg={3} name='consumer_city'/>
        <StatesField xs={6} lg={2} name='consumer_state'/>
        <TextField xs={6} lg={2} name='consumer_zip'/>
        <EmailField xs={12} lg={5} name='consumer_email'/>

      </Grid>
    )
  }
}

const form = connect(module.mapState, module.mapDispatch)(ConsumerFormComponent)
export {module, form}

export default form
