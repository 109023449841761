import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import FileIcon from '@material-ui/icons/InsertDriveFile';

import style from './docs.module.scss'
// Control Context Panel
import store from 'main/store/store'
import {mapDispatch as contextPanelMapDispatch} from 'main/utils/contextPanel/module'

import Panel from 'assets/panels/Panel'

import Button from 'assets/Button'
import {Caption} from 'assets/Text'

import moment from 'moment'

const contextPanelDispatch = contextPanelMapDispatch(store.dispatch)
const markup = ReactDOMServer.renderToStaticMarkup


class Docs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: []
    }

    contextPanelDispatch.set_left_header('')
    contextPanelDispatch.set_left_panel({
      title: markup(<p>Documents</p>),
      content:  markup(
<p>General Information and relevant documents from Innovative Dealer Alliance.
</p>
      ),
    })

    contextPanelDispatch.set_right_panel({})
  }


  componentDidMount = async () => {
    const {response, result} = await window.IDA.api.docs()
    this.setState({
      data: result
    })
  }

  sortIt = (elements) => {
    // elements must have an order and created property
    return elements.sort((sectionOne, sectionTwo) => {
      const compareOne = sectionOne.order || moment(sectionOne.created).unix()
      const compareTwo = sectionTwo.order || moment(sectionTwo.created).unix()

      return compareOne - compareTwo;
    })
  }

  render () {
    const sectionsList = this.sortIt(this.state.data.map((doc) => (doc.section)))
    const sectionSet = [...new Set(sectionsList.map((section) => (section.name)))]
    const sectionData = {}

    // Initialize
    sectionSet.forEach((section) => {
      sectionData[section] = []
    })


    // Fill data
    this.state.data.forEach((doc) => {
      sectionData.hasOwnProperty(doc.section.name) && sectionData[doc.section.name].push(doc)
    })

    // Sort it
    sectionSet.forEach((section) => {
      sectionData[section] = this.sortIt(sectionData[section])
    })

    return (
      <Grid container direction="row" justify="center" spacing={8}>
        <Grid item xs={12}>
          <h5>Documents</h5>
        </Grid>
          {sectionSet.map((section, index) => (
            <Grid item md={12} xs={12} key={index}>
              <Panel title={section}>
                <Grid item xs={12}>
                  <Grid container>
                    {sectionData[section].map((doc, index) => (
                      <Grid item xs={6} md={4} className='text-align-center'>
                        <Button className={style['link']} target='_blank' href={doc.file}>
                          <FileIcon color='secondary'  className={style['icon']} fontSize='large'/>
                          <Caption>{doc.filename}</Caption>
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Panel>
            </Grid>
        ))}
      </Grid>
    )
  }
}

export default Docs;
