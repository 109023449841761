import React from 'react'
import font from './scss/fonts.module.scss'
import classes from 'classnames'
import Typography from '@material-ui/core/Typography';

export const Subtitle = ({className, ...props}) => (
  <div className={classes(font['subtitle'], className)} {...props} />
)
export const Subtitle2 = ({className, ...props}) => (
  <div className={classes(font['subtitle-2'], className)} {...props} />
)

export const Caption = ({className, bold, ...props}) => (
  <div className={classes(font['caption'], bold ? 'bold' : '', 'caption', className)} {...props} />
)

export const Overline = ({className, ...props}) => (
  <div className={classes(font['overline'], className)} {...props} />
)

export const H6 = ({className, ...props}) => (
  <div className={classes(font['h6'], className)} {...props} />
)

export const PanelTitle = ({className, ...props}) => (
  <div className={classes(font['panel-title'], className)} {...props} />
)

export const FormCaption = ({className, ...props}) => (
  <div className={classes(font['form-caption'], className)} {...props} />
)

export const ButtonText = ({className, ...props}) => (
  <div className={classes(font['button'], className)} {...props} />
)

/*

export const Subtitle = (props) => (
  <Typography variant='subtitle' {...props} />
)
export const Subtitle_2 = (props) => (
  <Typography variant='subtitle2' {...props} />
)

export const Caption = (props) => (
  <Typography variant='caption' {...props} />
)

export const Overline = (props) => (
  <Typography variant='overline' {...props} />
)

export const H6 = (props) => (
  <Typography variant='h6' {...props} />
)
*/
