import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import withWidth from '@material-ui/core/withWidth';

import Navigation from 'main/navigation/nav'
import {LeftPanel, RightPanel} from 'main/utils/contextPanel/Panels'

import LinearAsync from 'main/utils/async/Linear'
import IDATheme from 'main/utils/theme'
import Snackbar from 'main/utils/snackbar/Snackbar'

import font from 'assets/scss/fonts.module.scss'


const Nav = (props) => (
  <Grid item {...props}>
    <Navigation/>
  </Grid>
)

const ContentPanel = (props) => (
  <Grid item className="flex-grow" {...props}/>
)

const Footer = () => (
  <Grid item xs={12} className="App-footer">
    <div className={font.caption}>Contact - By Email support@innovativedealeralliance.com - By Phone 844.432.0568</div>
    <div className={font.caption}>Copyright © 2019 INNOVATIVE DEALER ALLIANCE, PO Box 14789, Long Beach, CA 90853, 844.IDA.0568</div>
    <div className={font.caption}>Your use of this website constitutes acceptance of the <a target='_blank' href='https://innovativedealeralliance.com/terms-of-service/'>Terms of Service</a>, <a target='_blank' href='https://innovativedealeralliance.com/privacy-policy/'>Privacy Policy</a> and <a target='_blank' href='https://innovativedealeralliance.com/disclaimer/'>Disclaimer</a></div>
  </Grid>
)

// Adjusts a Grid direction property based on @media
const gridColumnOrRow = {
  xs: 'column',
  sm: 'column',
  md: 'row',
  lg: 'row',
  xl: 'row',
}

class LayoutComponent extends Component {

  onAsyncError = (asyncState) => {
    const {response, error} = asyncState
    if (!response || response.status >= 500) {
      const status = response ? response.status : "No response"
      window.IDA.snack.error('Server Error: ' + status)
    }
  }

  render() {
    return (
        <IDATheme>
          <Switch>
            <Route path="/login" render={props => (
              <Grid container direction="column" className="App">
                <LinearAsync onError={this.onAsyncError}/>
                <ContentPanel columns={12}>
                  {this.props.children}
                </ContentPanel>
                <Footer />
              </Grid>
            )}/>
            <Route path="/account/password/reset/" render={props => (
              <Grid container direction="column" className="App">
                <LinearAsync onError={this.onAsyncError}/>
                <ContentPanel columns={12}>
                  {this.props.children}
                </ContentPanel>
                <Footer />
              </Grid>
            )}/>
            <Route path="/" render={props => (
              <Grid container direction="column" alignItems="stretch" className="App">
                <LinearAsync onError={this.onAsyncError}/>
                <Nav xs={12}/>
                <Grid className="flex-grow App-content" container direction={gridColumnOrRow[this.props.width]}>
                  <LeftPanel xs={12} md={3} />
                  <ContentPanel xs={12} md={6}>
                     {this.props.children}
                  </ContentPanel>
                  <RightPanel xs={12} md={3} />
                </Grid>
                <Grid item xs={12}>
                      <div className={font.caption}>Version {process.env.REACT_APP_VERSION}</div>
                </Grid>
                <Footer />
              </Grid>
            )}/>
          </Switch>
          <Snackbar/>
        </IDATheme>
    );
  }
}

export default withWidth()(LayoutComponent);
