
import Login from 'auth/Login'
import Logout from 'auth/Logout'
import PasswordReset from 'auth/PasswordReset'
import PasswordResetConfirm from 'auth/PasswordResetConfirm'
import Home from 'home/Home'
import WarrantyList from 'warranties/WarrantyList/WarrantyList'
import NewWarranty from 'warranties/New'
import NotFound from './NotFound'

import Orders from 'orders/Orders'
import OrderList from 'orders/OrderList/OrderList'
import OrderDetail from 'orders/OrderDetail/OrderDetail'
import OrderReceipt from 'orders/OrderReceipt/OrderReceipt'

import * as DealershipForm from 'warranties/forms/dealership/Dealership'
import * as VehicleForm from 'warranties/forms/vehicle/Vehicle'
import * as ConsumerForm from 'warranties/forms/consumer/Consumer'
import * as ModificationsForm from 'warranties/forms/modifications/Modifications'
import * as WarrantyForm from 'warranties/forms/warranty/Warranty'
import * as SummaryForm from 'warranties/forms/summary/Summary'

import * as AccountForm from 'account/Account'

import Faq from 'faq/Faq'
import Docs from 'docs/Docs'

export default [
  {
    name: 'Login',
    path: "/login/:state?",
    component: Login
  },
  {
    name: 'Logout',
    path: "/logout",
    component: Logout
  },
  {
    name: 'Password Reset',
    path: "/account/password/reset/",
    component: PasswordReset,
    exact: true,
  },
  {
    name: 'Password Reset Confirmation',
    path: "/account/password/reset/confirm/:uid/:token",
    component: PasswordResetConfirm
  },
  {
    name: 'Home',
    path: "/",
    component: Home,
    authRequired: true,
    exact: true,
    nav: true,
  },
  {
    name: 'Home',
    path: "/home",
    component: Home,
    authRequired: true,
  },
  {
    name: 'Account Details',
    path: "/account",
    component: AccountForm.form,
    module: AccountForm.module,
    authRequired: true,
  },
  {
    name: 'Order a New Warranty',
    path: "/warranties/new",
    component: NewWarranty,
    authRequired: true,
    nav: true,
    routes: [
      {
        name: 'Dealership',
        path: '/warranties/new/dealership',
        component: DealershipForm.form,
        module: DealershipForm.module,
      },
      {
        name: 'Vehicle',
        path: '/warranties/new/vehicle',
        component: VehicleForm.form,
        module: VehicleForm.module,
      },
      {
        name: 'Consumer',
        path: '/warranties/new/consumer',
        component: ConsumerForm.form,
        module: ConsumerForm.module,
      },
      {
        name: 'Modifications',
        path: '/warranties/new/modifications',
        component: ModificationsForm.form,
        module: ModificationsForm.module,
      },
      {
        name: 'Warranty',
        path: '/warranties/new/warranty',
        component: WarrantyForm.form,
        module: WarrantyForm.module,
      },
      {
        name: 'Summary',
        path: '/warranties/new/summary',
        component: SummaryForm.form,
        module: SummaryForm.module,
      },
    ]
  },
  {
    name: 'My Warranties',
    path: "/warranties",
    exact: true,
    component: WarrantyList,
    authRequired: true,
    nav: true,
  },
  {
    name: 'My Orders',
    path: "/orders",
    component: Orders,
    authRequired: true,
    nav: true,
    routes: [
      {
        name: 'Order Detail',
        path: '/orders',
        component: OrderList,
        exact: true
      },
      {
        name: 'Order Detail',
        path: '/orders/:id',
        component: OrderDetail,
        exact: true
      },
      {
        name: 'Order Receipt',
        path: '/orders/:id/receipt',
        component: OrderReceipt
      },
    ]
  },
  {
    name: 'Docs',
    path: "/docs",
    component: Docs,
    authRequired: true,
    nav: true,
    disabled: false
  },
  {
    name: 'FAQ',
    path: "/faq",
    component: Faq,
    authRequired: true,
    nav: true,
    disabled: false
  },
  {
    name: '404',
    path: '/',
    component: NotFound
  }
];
