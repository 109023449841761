import React, { Component } from 'react';
import PaymentForm from './PaymentForm';

import { connect } from 'react-redux'
import { mapState, mapDispatch } from 'orders/OrderDetail/module';
import store from 'main/store/store'

import Async from 'main/utils/async/Async'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';
import style from './square.module.scss'
import {Caption} from 'assets/Text'
import logo from 'static/logo_square.png';

import Cards from './Cards.jsx'
import Paper from '@material-ui/core/Paper';
import { withTheme } from '@material-ui/core/styles';

import * as snackbarModule from 'main/utils/snackbar/module'
const snackbarDispatch = snackbarModule.mapDispatch(store.dispatch)
/**
 *  Loader class handles Payment dialog contents.  Logic is dependent on
 *  whether customer has saved a credit card or not.
 *
 *  If no saved credit card, display PaymentForm component.
 *
 *  If saved credit card(s), display Cards component.
 *
 *  chosenCard:  'new',  no saved cards, display PaymentForm and enter card info
 *                undefined, saved cards exist, display Cards component
 *                token (string), Square token relating to the saved or entered
 *                                card
 *
 */
class Loader extends Component {
  constructor(props){
    super(props)
    this.state = {
      scriptLoaded: false,
      customerInitialized: false,
      chosenCard: 'new',
      cards: [],
      message: ''
    }
  }

  handleClose = () => {
    this.setState({
      open: false,
      customerInitialized: false,
    });
  };

  componentWillMount(){
    if (!window.Square) {
      const componentContext = this;
      let sqPaymentScript = document.createElement('script');
      sqPaymentScript.src = process.env.REACT_APP_SQUARE_SRC;
      sqPaymentScript.type = "text/javascript"
      sqPaymentScript.async = false;
      sqPaymentScript.onload = () => { componentContext.setState({
        scriptLoaded: true
      })};
      document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
    }
    else {
      this.setState({
        scriptLoaded: true
      })
    }
  }

  onOpen = async () => {
    const {result, response} = await window.IDA.api.square.get_cards()
    switch (response.status) {
      case 200:
        const cards = result.cards || []
        this.setState({
          customerInitialized: true,
          cards: cards,
          chosenCard: cards.length > 0 ? undefined : 'new'
        })

        break;
      default:
        this.setState({
          customerInitialized: true,
          cards: [],
          chosenCard: 'new'
        })
    }

  }

  onClose = () => {
    this.handleClose()
    this.props.onClose()
  }

  setChosenCard = async (value) => {
    this.setState({
      chosenCard: value
    })

    if (value && value !== 'new') {
      const transaction = await window.IDA.api.orders.pay({
        card_id: value,
        id: this.props.order.id
      })
      this.props.onTransaction(transaction)
    }
  }

  onBack = async () => {
    this.setChosenCard(undefined)
    this.props.clear_error();
    this.props.payment.squareCard && await this.props.payment.squareCard.clear()
  }

  get isDialogReady() {
    return this.state.scriptLoaded &&
           this.state.customerInitialized
  }

  render() {
    const {open, onClose, ...props} = this.props

    return (
      <Dialog
        open={open}
        onClose={this.onClose}
        onEnter={this.onOpen}
        keepMounted={false}
        className={style['dialog']}
        PaperComponent={this.isDialogReady ? Paper : (props)=>(<div style={{display: 'none'}} {...props}></div>)}
        fullWidth
      >
        <DialogTitle>
          <Grid container>
            <Grid item xs={12} sm={2} className='text-align-center'>
              <img src={logo} className={style['logo']} alt="logo" />
            </Grid>
            <Grid item xs={12} sm={10}>
              <h5 className={style['dialogTitle']}>Payment</h5>
            </Grid>
          </Grid>
        </DialogTitle>
        {(this.isDialogReady &&
            <DialogContent>
              {this.state.chosenCard === undefined &&
                <Cards
                  cards={this.state.cards}
                  onNext={this.setChosenCard}
                  refreshCards={this.onOpen}
                  order={this.props.order}
                />
              }
              <Async superHidden={this.state.chosenCard !== 'new'} hiddenMessage={false}>
                <PaymentForm
                  onBack={this.state.cards.length > 0 ? this.onBack : null}
                  hidden={this.state.chosenCard == 'new'}
                  {...props}
                />
              </Async>
            </DialogContent>
          ) ||
            <div>Loading...</div>
          }
      </Dialog>
    );
  }
}

export default connect(mapState, mapDispatch)(withTheme()(Loader));
