import Joi from 'joi-browser';
import {processForm} from 'warranties/forms/formUtilities'
import states from 'assets/utility/states'

const form = {
  consumer_name: {
    label: 'Consumer Name',
    joi: Joi.string().allow('').min(3).max(30),
    message: 'Optional'
  },
  consumer_street: {
    label: 'Street Address',
    joi: Joi.string().allow('').min(3).max(60),
    message: 'Optional'
  },
  consumer_city: {
    label: 'City',
    joi: Joi.string().allow('').min(3).max(30),
    message: 'Optional'
  },
  consumer_state: {
    label: 'State',
    joi: Joi.string().alphanum().min(2).max(2).error(
      (errors) => {
        return {
          message: "US State Required."
        }
      }
    ).tags('auto'),
    suggestions: states,
    message: 'Optional'
  },
  consumer_zip: {
    label: 'Zip',
    joi: Joi.string().allow('').alphanum().min(5).max(5),
    message: 'Optional'
  },
  consumer_email: {
    label: 'E-Mail',
    joi: Joi.array().items(Joi.string().email({ minDomainAtoms: 2 }).allow('')),
    message: 'Optional'
  }
}

// Create Joi validation schema and form defaults for redux
const {schema, defaults} = processForm(form)

export {form, schema, defaults}
