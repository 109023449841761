import React, {Component} from 'react'
import { connect } from 'react-redux'
import Async from './Async'
import LinearProgress from '@material-ui/core/LinearProgress';
import { mapState, mapDispatch } from './module';
import styles from './async.module.scss'

class LinearAsyncComponent extends Component {

  render = () => {
    return (
      <Async onBegin={this.props.onBegin} onEnd={this.props.onEnd} onError={this.props.onError}>
        {this.props.active &&
          <LinearProgress className={styles.LinearAsync} color="secondary" />
        }
      </Async>
    )
  }
}

export default connect(mapState, mapDispatch)(LinearAsyncComponent)
