import React from 'react'

import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import RadioGroup from '@material-ui/core/RadioGroup';

import style from './form.module.scss'

const FormGroupComponent = ({
  xs,
  sm,
  md,
  lg,
  xl,
  spacing = 0,
  type,
  children,
  ...props}) => {
  const breakpoints = {xs, sm, md, lg, xl}
  //const Group = type === 'radio'? RadioGroup : FormGroup
  return (
    <Grid item {...breakpoints} className={style[type]}>
      <FormControl fullWidth={true} {...props}>
        <Grid container spacing={spacing}>
              {children}
        </Grid>
      </FormControl>
    </Grid>
  )
}

export default FormGroupComponent
