import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Redirect, Switch, NavLink, withRouter } from 'react-router-dom'
import Route from 'main/utils/route/Route'

import { mapState, mapDispatch } from './module';
import style from './orders.module.scss'

import Grid from '@material-ui/core/Grid';


class OrdersComponent extends Component {

  render() {
    return (
      <Grid container direction="row" justify="flex-end">
        <Grid item xs={12}>
          <Switch>
            {this.props.routes.map((route, i) => {
              return <Route key={i} {...route} ></Route>
            })}
          </Switch>
        </Grid>
      </Grid>
    )
  }
}

export default connect(mapState, mapDispatch)(OrdersComponent)
