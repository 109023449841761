import Joi from 'joi-browser';
import {processForm} from 'warranties/forms/formUtilities'

const form = {
  vehicle_vin: {
    label: 'VIN Number',
    joi: Joi.string().regex(/^(?<wmi>[A-HJ-NPR-Z\d]{3})(?<vds>[A-HJ-NPR-Z\d]{5})(?<check>[\dX])(?<vis>(?<year>[A-HJ-NPR-Z\d])(?<plant>[A-HJ-NPR-Z\d])(?<seq>[A-HJ-NPR-Z\d]{6}))$/).required().error(
      (errors) => {
        return {
          message: "Valid VIN Required."
        }
      }
    ),
  },
  vehicle_vin_validated: {
    label: 'VIN Validated',
    default: null,
    joi: Joi.boolean().required().equal(true).error(
      (errors) => {
        return {
          message: "Apologies, we could not validate this VIN.  Please double check and re-enter the VIN."
        }
      }
    ).tags('auto'),
  },
  vehicle_year: {
    label: 'Year',
    joi: Joi.string().min(1).max(4).required().error(
      (errors) => {
        return {
          message: "Vehicle Year Required."
        }
      }
    ).tags('auto'),
    suggestions: [],
  },
  vehicle_make: {
    label: 'Make',
    joi: Joi.string().min(1).max(30).required().error(
      (errors) => {
        return {
          message: "Vehicle Make Required."
        }
      }
    ).tags('auto'),
    suggestions: [],
  },
  vehicle_model: {
    label: 'Model',
    joi: Joi.string().min(1).max(30).required().error(
      (errors) => {
        return {
          message: "Vehicle Model Required."
        }
      }
    ).tags('auto').tags('async'),
    suggestions: [],
  },
  vehicle_trim: {
    label: 'Trim',
    joi: Joi.string().max(80).allow(''),
  },
  vehicle_gvwr: {
    label: 'Gross Vehicle Weight Rating',
    joi: Joi.string().min(1).max(50).allow('').tags('auto'),
    message: 'To find the appropriate weight rating, please see manufacturer guidelines or manufacturer VIN decoder.',
    suggestions: [],
  },
  vehicle_odometer: {
    label: 'Odometer',
    joi: Joi.number().integer().positive().required().error(
      (errors) => {
        return {
          message: "Odometer Required. (Whole Number)"
        }
      }
    ),
  },
  vehicle_msrp: {
    label: 'MSRP',
    joi: Joi.number().positive().required().error(
      (errors) => {
        return {
          message: "MSRP Required."
        }
      }
    ),
  },
  vehicle_build_date: {
    label: 'Build Date',
    default: null,
    joi: Joi.date().required().error(
      (errors) => {
        return {
          message: "Build Date Required."
        }
      }
    ),
  },
  vehicle_4x4: {
    label: '4x4 / All Wheel Drive',
    joi: Joi.boolean(),
  },
  vehicle_diesel: {
    label: 'Diesel Engine',
    joi: Joi.boolean(),
  },
  vehicle_turbo: {
    label: 'Turbo / SuperCharger',
    joi: Joi.boolean(),
  },
  vehicle_one_ton: {
    label: 'One Ton Vehicle',
    joi: Joi.boolean(),
  },
  vehicle_sold: {
    label: 'Vehicle was Sold',
    default: null,
    joi: Joi.boolean().required().error(
      (errors) => {
        return {
          message: "Vehicle was Sold Required."
        }
      }
    ),
  },
  vehicle_sold_date: {
    label: 'Sold Date',
    joi: Joi.date(),
    default: null
  },
  vehicle_used: {
    label: 'NEW or Used?',
    default: null,
    joi: Joi.boolean().required().error(
      (errors) => {
        return {
          message: "Vehicle 'NEW or Used?' required"
        }
      }
    ),
  },
  vehicle_notes: {
    label: 'Additional Notes',
    joi: Joi.string().allow(''),
    message: 'Optional'
  }
}

// Create Joi validation schema and form defaults for redux
let {schema, defaults} = processForm(form)

schema = schema.when(Joi.object({
  vehicle_sold: Joi.equal(true).required()
}).unknown(),
{
  then: Joi.object({
    vehicle_sold_date: Joi.required()
  })
})



export {form, schema, defaults}
