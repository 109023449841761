import Joi from 'joi-browser';
import {processForm} from 'warranties/forms/formUtilities'

const form = {
  program_upgrade: {
    label: 'Program Upgrade',
    default: null,
    joi: Joi.number().integer().allow(null),
  },
  coverage_upgrade: {
    label: 'Coverage',
    default: null,
    joi: Joi.number().integer().allow(null),
  },
  confirmation: {
    label: 'I Confirm All Warranty Information Is Correct',
    default: false,
    joi: Joi.boolean().required().valid(true).error(
      (errors) => {
        return {
          message: "Please confirm warranty information is correct before proceeding."
        }
      }
    ),
  },
}

// Create Joi validation schema and form defaults for redux
const {schema, defaults} = processForm(form)

export {form, schema, defaults}
