import processError from 'warranties/forms/errorMessages'


export default (schema) => (
  {
    clear_state(state, action) {
      state.active = false
      state.completed = false
      state.error = false
      state.disabled = true
      state.skip = false
      state.message = ''
      state.optional = false
      state.entries = {}
      state.values = state.valueDefaults
      state.locked = false
    },
    set_active(state, action) {
      state.active = true
      state.disabled = false
    },
    set_inactive(state, action) {
      state.active = false
    },
    set_completed(state, action) {
      state.completed = true
      state.disabled = false
    },
    set_skip(state, action) {
      state.skip = true
    },
    set_context(state, action) {
      state.context = action.payload
    },
    set_errors(state, action) {
      state.error = true
      for (const {key, message} of action.payload) {
        state.values[key].message = message
        state.values[key].error = true
      }
    },
    clear_errors(state, action) {
      state.error = false
      for (const [key, value] of Object.entries(state.values)) {
        state.values[key].message = state.valueDefaults[key].message
        state.values[key].error = false
      }
    },
    create_value(state, action) {
      const {field, value} = action.payload
      state.values[field] = value
    },
    set_value(state, action) {
      const {field, value} = action.payload
      state.values[field].value = value
      state.entries[field] = value
    },
    set_default_value(state, action) {
      const field = action.payload
      state.values[field] = state.valueDefaults[field]
      delete state.entries[field]
    },
    set_entries_value(state, action) {
      const {field, value} = action.payload
      state.entries[field] = value
    },
    set_email_value(state, action) {
      const {field, value, list} = action.payload
      state.values[field].value = value
      state.entries[field] = list
    },
    load_email_value(state, action) {
      const {field, value} = action.payload
      const emailArray = value.trim().split(',')
      state.values[field].value = emailArray.join(', ')
      state.entries[field] = emailArray
    },
    set_date_value(state, action) {
      const {field, value} = action.payload
      state.values[field].value = value
      state.entries[field] = (value && value.toJSON()) || undefined
    },
    set_autocomplete_value(state, action) {
      const {field, value} = action.payload
      state.values[field].value = value
      state.entries[field] = (value && value.value) || undefined
    },
    set_select_value(state, action) {
      const {field, value} = action.payload
      state.values[field].value = value
      state.entries[field] = value
    },
    VALIDATE_FORM(state, action) {
      const validationSchema = schema
      const options = {abortEarly: false}
      const validation = validationSchema.validate(state.entries, options)

      if (validation.error) {
        state.errorDetails = validation.error.details
        if (state.completed) {
          state.error = true
          state.completed = false
        }
      }
      else {
        state.errorDetails = null
        state.error = false
        state.message = ''
        state.completed = true
      }
    },
    SET_FIELD_ERRORS(state, action) {
      const field = action.payload
      Object.keys(state.values).forEach((field) => {
        state.values[field].error = false
        state.values[field].message = state.valueDefaults[field].message
      })

      if(state.errorDetails) {
        for (const error of state.errorDetails) {
          if (error.context.key === field) {
            processError({error, state})
            break;
          }
        }
      }
    },
    SET_ALL_ERRORS(state, action) {
      Object.keys(state.values).forEach((field) => {
        state.values[field].error = false
        state.values[field].message = state.valueDefaults[field].message
      })
      if(state.errorDetails) {
        for (const error of state.errorDetails) {
          processError({error, state})
          break;
        }
      }
    }
  }
)
