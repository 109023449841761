import React from 'react';
import ReactDOMServer from 'react-dom/server';
import {createModule} from 'main/utils/reducers';
import commonReducers from 'warranties/forms/commonReducers'
import {form, schema, defaults as formDefaults} from './form'


const markup = ReactDOMServer.renderToStaticMarkup

const module = createModule({
  slice: 'FormAccount',
  initialState: {
    name: 'Dealership',
    nextText: 'Proceed to Step 2: Vehicle Information',
    error: false,
    completed: false,
    message: '',
    entries: {},
    valueDefaults: formDefaults,
    values: formDefaults,
    context: {
      left: {
        title: markup(<p>Account Details</p>),
        content: markup(
          <div>
            <p>You may update your account information here.</p>
          </div>
        )
      },
      right: {
        title: null,
        content: null
      }
    }
  },
  reducers: {
    ...commonReducers(schema),
  },
})


export const { mapState, mapDispatch, slice, reducer, selector } = module
export {form, schema, formDefaults}
export default module.reducer
