import React, { Component } from 'react';
import {mapState, mapDispatch} from 'orders/OrderDetail/module'
import { connect } from 'react-redux'
//import './square.scss'
import style from './square.module.scss'
import { withTheme } from '@material-ui/core/styles';
import logo from 'static/logo_square.png';

import {FormText, FormCheckbox} from 'assets/forms/FormInput'
import Grid from '@material-ui/core/Grid';
import FormButton from 'assets/forms/FormButton';
import Button from 'assets/Button'
import {Caption} from 'assets/Text'
import classNames from 'classnames'

import Async from 'main/utils/async/Async'

/**
 *  PaymentForm handles the Square WebPayments API and hands the card token
 *  back to the IDA server API for payment processing.
 *
*/
class PaymentForm extends Component {
  constructor(props){
    super(props);
    this.cardPaymentRef = React.createRef();
    this.state = {
      message: '',
      cardBrand: "",
      token: undefined,
      googlePay: false,
      applePay: false,
      masterpass: false,
      loaded: false,
      processing: false,
      saveCard: false,
    }
  }

  requestCardToken = async () => {
    this.setState({processing:true})
    const tokenResult = await this.squareCard.tokenize();
    const errors = tokenResult.errors;
    const status = tokenResult.status;

    if (status === 'OK') {
      this.setState({
        token: tokenResult.token
      })

    } else {
      let errorMessages = []
      if (errors) {
        errors.forEach((error) => {
          errorMessages.push(error.message)
        });
        this.props.set_error(errorMessages)
        this.setState({processing:false})
        return;
      }
    }

    const transaction = await window.IDA.api.orders.pay({
      id: this.props.order.id,
      nonce: this.state.token,
      save_card: this.state.saveCard
    })
    this.props.onTransaction(transaction)
  }

  componentDidMount(){

    this.props.clear_error();
    this.loadSquareCard()

  }

  componentDidUpdate() {
    if (this.props.payment.squareFormLoaded && this.props.payment.squareCard){
      this.props.payment.squareCard.recalculateSize()
    }
  }

  loadSquareCard = async () => {
    this.squarePayments = await window.Square.payments(process.env.REACT_APP_SQUARE_APP_ID, process.env.REACT_APP_SQUARE_LOCATION_ID);

    const cardOptions = {
      style: {
        ".input-container" : {
          "borderColor": "rgba(0, 0, 0, 0.23)",
          "borderRadius": "4px",
        },
        ".input-container.is-focus":{
          "borderColor": "#0086C0",
          "borderWidth": "1px"
        },
        ".input-container.is-error":{

        },
        ".message-text": {
          "color": "#255884"
        },
        ".message-icon": {
          "color": "#0086C0"
        },
        "input": {
          "color": "#255884",
          "fontSize": "16px",
          "fontWeight": "normal"
        },
        "input::placeholder": {

        }
      }
    }
    this.squareCard = await this.squarePayments.card(cardOptions)
    await this.squareCard.attach(this.cardPaymentRef.current)
    this.props.load_square_form(this.squareCard)
  }

  onBeginTransaction = () => {
    this.setState({processing:true})
  }

  onEndTransaction = () => {
    //this.setState({processing:false})
  }

  onSaveCardChange = (e) => {
    this.setState({saveCard: e.target.checked})
  }

  render() {
    const PaymentInput = this.PaymentInput

    let errorMessage = 'We could not process this credit card.  Please verify your credit card number, CVV code, expiration date, and zip are correct.'

    if (this.props.payment.messages) {
      errorMessage = this.props.payment.messages.map((message, key) => (
        <div key={key}>{message}</div>
      ))
    }
    return (
      <div className="container">
        <div id="form-container" className={style['form-container']}>
          <div id="sq-ccbox" className='text-align-center'>
            <span>Enter Card Info Below </span>
            <div id="cc-field-wrapper">
              <Grid container alignItems='center' justify='center' spacing={16} className={style['cc-field-container']}>

                <Grid item xs={12}>
                  <Async onBegin={this.onBeginTransaction} onEnd={this.onEndTransaction} hidden={!this.props.payment.squareFormLoaded} hiddenMessage='Loading Payment Form...'>
                    <Grid container alignItems='center' justify='center' spacing={16} className={style['cc-field-container']}>
                      <Grid item xs={12}>
                        {this.props.payment.error &&
                          <Caption style={{color: this.props.theme.palette.primary.error}}>
                            {errorMessage}
                          </Caption>
                        }
                      </Grid>
                      <Grid item xs={12}>
                        <div ref={this.cardPaymentRef}/>
                      </Grid>
                      <FormCheckbox
                        xs={12}
                        onChange={this.onSaveCardChange}
                        label="Save Card"
                        name="save-card"
                        checked={this.state.saveCard}>
                          Save Card for future Payments
                      </FormCheckbox>
                      <FormButton
                          type='button'
                          variant="contained"
                          color="secondary"
                          disabled={this.state.processing}
                          onClick={this.requestCardToken}
                        >Pay ${this.props.order.total}
                      </FormButton>
                      {this.props.onBack &&
                        <Grid item xs={12} className='text-align-left'>
                          <Button onClick={this.props.onBack}>
                            <Caption>Go Back</Caption>
                          </Button>
                        </Grid>
                      }
                    </Grid>
                  </Async>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapState, mapDispatch)(withTheme()(PaymentForm))
