import store from 'main/store/store'
import {mapDispatch as snackbarMapDispatch} from 'main/utils/snackbar/module'

const Snackbar = snackbarMapDispatch(store.dispatch)


const SNACK = {
  info: (message) => {
    Snackbar.OPEN({message, variant:'info'})
  },
  success: (message) => {
    Snackbar.OPEN({message, variant:'success'})
  },
  warning: (message) => {
    Snackbar.OPEN({message, variant:'warning'})
  },
  error: (message) => {
    Snackbar.OPEN({message, variant:'error'})
  },
}

export default SNACK
