import React from 'react';
import { connect } from 'react-redux'
import * as module from './module';
import * as authModule from 'auth/module'
import * as snackbarModule from 'main/utils/snackbar/module'

import store from 'main/store/store'

import Grid from '@material-ui/core/Grid';
import {FormStepperNext} from 'assets/forms/FormButton'

import style from './account.module.scss'

import {form as formSpecs, schema} from './form'
import generateFormComponent from 'warranties/forms/FormComponent'

const FormComponent = generateFormComponent(formSpecs, schema)
const snackbarDispatch = snackbarModule.mapDispatch(store.dispatch)

class AccountFormComponent extends FormComponent {

  componentDidMount = () => {
    const user = authModule.selector(store.getState()).user
    this.props.set_value({field: 'account_name', value: user.company})
    this.props.set_value({field: 'account_street', value: user.street})
    this.props.set_value({field: 'account_city', value: user.city})

    const suggestions = formSpecs['account_state'].suggestions
    const selection = suggestions.find((suggestion) => (user.state.trim().toLowerCase() === suggestion.value.trim().toLowerCase()))

    this.props.set_autocomplete_value({field: 'account_state', value: selection})
    this.props.set_value({field: 'account_zip', value: user.zip})
    this.props.set_value({field: 'account_phone', value: user.phone})
    this.props.load_email_value({field: 'account_email', value: user.alternate_emails.join(',') || ''})
    super.componentDidMount()
  }

  onSave = async () => {
    this.props.VALIDATE_FORM()
    this.props.SET_ALL_ERRORS()

    if (this.props.completed) {
      const { account_name,
              account_street,
              account_city,
              account_state,
              account_zip,
              account_email,
              account_phone } = this.props.entries
      const {result, response} = await window.IDA.api.account.update({data: this.props.entries})
      switch(response.status) {
        case 200:
          authModule.mapDispatch(store.dispatch).set_user({client: result})
          snackbarDispatch.OPEN({message: 'Account Updated', variant: 'success'})
          break;
        default:
          snackbarDispatch.OPEN({message: 'Unable to Update Account', variant: 'error'})
          break;
      }
    }
  }

  render = () => {
    const TextField = this.TextField
    const StatesField = this.AutocompleteField
    const EmailField = this.EmailField

    return (
      <Grid container direction="row" justify="start" spacing={16}>
        <Grid item xs={12}>
          <h5>Account Information</h5>
        </Grid>
        <TextField required xs={12} lg={8} name='account_name'/>
        <TextField required xs={6} lg={5} name='account_street'/>
        <TextField required xs={6} lg={3} name='account_city'/>
        <StatesField required xs={6} lg={2} name='account_state'/>
        <TextField required xs={6} lg={2} name='account_zip'/>
        <TextField required xs={6} lg={2} name='account_phone'/>
        <EmailField
          label='Additional Emails'
          xs={12}
          lg={5}
          name='account_email'
          helperText='Warranty Documents will be sent to emails specified above.'
        />
        <Grid item xs={12} className='text-align-right'>
          <FormStepperNext
            fullWidth={false}
            onClick={this.onSave}
            disabled={!this.props.completed}
          >
              Save Account Information
          </FormStepperNext>
        </Grid>

      </Grid>
    )
  }
}
const form = connect(module.mapState, module.mapDispatch)(AccountFormComponent)
export {module, form}
export default form
