import React from 'react';

import { connect } from 'react-redux'
import { mapState, mapDispatch } from './module';
import * as configModule from 'config/module'
import store from 'main/store/store'

import { withRouter } from 'react-router-dom'

import classes from 'classnames'
import style from './panel.module.scss'
import Grid from '@material-ui/core/Grid';

import {Subtitle2, Caption} from 'assets/Text'
import font from 'assets/scss/fonts.module.scss'
import Divider from '@material-ui/core/Divider';

import Panel from 'assets/panels/Panel'

import { Warning } from '@material-ui/icons';
import {NavButton} from 'assets/Button'

const PanelComponent = (
  { className,
    ...props
  }) => (
    <Grid item className={classes(className, style['context-panel'])}>
      <div className={classes(style['title'], font['button'])} dangerouslySetInnerHTML={{__html:props.title}}/>
      <div className='text-align-center'>
        <Divider variant='fullWidth' className={style['divider']} />
      </div>
      <Subtitle2 className={style['content']} dangerouslySetInnerHTML={{__html:props.content}}/>
      <div>
        {props.children}
      </div>
    </Grid>
)

const LeftPanelComponent = ({xs, sm, md, lg, xl, ...props}) => {
  const breakpoints = {xs, sm, md, lg, xl}
  const hasContent = !!props.leftPanel.title || !!props.leftPanel.content || !!props.pendingOrder
  return (
    <Grid item {...breakpoints}>
      { hasContent &&
        <Grid className={style['context-panel-container']} container direction="row" alignItems="flex-start" justify="center">
          <Grid item xs={12}>
            <h6>{props.leftHeader}</h6>
          </Grid>
          <PanelComponent
            className={style['left-panel']}
            title={props.leftPanel.title}
            title-justify="flex-start"
            content={props.leftPanel.content}
          />
        </Grid>
      }
    </Grid>
  )
}

const PendingOrderComponent = connect(configModule.mapState, configModule.mapDispatch)((props) => {

  if (props.open_order) {
    const order = props.open_order
    const order_date = new Date(order.created)
    const label = order.warranties.length > 1 ? 'Warranties' : 'Warranty'

    return (
      <PanelComponent
        xs={12}
        className={classes(style['right-panel'], style['pending-order'])}
        title='Pending Order'
      >
        <NavButton key={order.id} to={`/orders/${order.id}`} color='secondary'>
          <Grid container alignItems='center' spacing={8}>
            <Grid item>
              <Caption>You have a pending order containing {order.warranties.length} {label}.</Caption>
            </Grid>
          </Grid>
        </NavButton>
      </PanelComponent>
    )
  }
  else {
    return null
  }
})

const RightPanelComponent = ({xs, sm, md, lg, xl, ...props}) => {
  const breakpoints = {xs, sm, md, lg, xl}
  const hasContent = !!props.rightPanel.title || !!props.rightPanel.content

  return (
    <Grid item {...breakpoints}>
      { hasContent &&

        <Grid className={style['context-panel-container']} container direction="column" alignItems="center" justify="flex-start">
          <PendingOrderComponent/>
          <PanelComponent
            className={style['right-panel']}
            title={props.rightPanel.title}
            content={props.rightPanel.content}
          />
        </Grid>
      }
    </Grid>
  )
}

export const LeftPanel = connect(mapState, mapDispatch)(withRouter(LeftPanelComponent))
export const RightPanel = connect(mapState, mapDispatch)(withRouter(RightPanelComponent))
