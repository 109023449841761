import React from 'react'
import TextField from './TextField'

const EmailFieldComponent = ({
  label = 'Email',
  type = 'email',
  name = 'email',
  ...props
}) => (<TextField {...{label, type, name, ...props}} />)


export default EmailFieldComponent
