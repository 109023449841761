import {createModule} from 'main/utils/reducers';

const module = createModule({
  slice: 'Snackbar',
  initialState: {
    open: false,
    message: '',
    variant: 'info',
    duration: 5000
  },
  reducers: {
    OPEN(state, action) {
      const {message, variant, duration=10000} = action.payload
      state.open = true
      state.message = message
      state.variant = variant
      state.duration = duration
    },
    CLOSE(state, action) {
      state.open = false
    }
  }
})

export const { mapState, mapDispatch, slice, reducer, selector } = module

export default module.reducer
