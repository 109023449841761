
export default ({error, state}) => {
  let field = ''
  let label = ''
  let message = ''

  switch(error.type) {
    case 'object.and':
      field = error.context['missing'][0]
      label = error.context['missingWithLabels'][0]
      state.values[field].error = true
      state.values[field].message = `${label} is required.`
      break
    case 'string.email':
      if (error.path.length > 1) {
        const value = error.context.value
        field = error.path[0]
        state.values[field].error = true
        state.values[field].message = `"${value}" is not a valid e-mail address.`
      }
      break
    case 'any.empty':
      if (typeof(error.context.key) === 'number') {
        field = error.path[0]
        message =  error.message
        state.values[field].error = true
        state.values[field].message = message
        break;
      }
    default:
      field = error.context.key
      message =  error.message
      state.values[field].error = true
      state.values[field].message = message
      break;
  }
}
