import {createModule} from 'main/utils/reducers';

const module = createModule({
  slice: 'Config',
  initialState: {
    'original_equipment': {},
    'vehicle_models': {},
    'open_order': null,
    'recent_warranties': [],
    'years': []
  },
  reducers: {
    update_config(state, {payload}) {
      state = {...state, ...payload};
      return state
    }
  },
  extraReducers: {
    'Auth/set_user': (state, {payload}) => {
      state = {...state, ...payload.config}
      return state
    },
    'OrderDetail/set_order': (state, {payload}) => {
      state.open_order = payload
    },
    'OrderReceipt/set_order': (state, {payload}) => {
      state.open_order = null
    },
  }
})

export const { mapState, mapDispatch, slice, reducer, selector } = module

export default module.reducer
