import React from 'react'
import Grid from '@material-ui/core/Grid'
import Panel from 'assets/panels/Panel'

const FormPanelComponent = ({
  xs,
  sm,
  md,
  lg,
  xl,
  title,
  ...props
}) => {
  const breakpoints = {xs, sm, md, lg, xl}
  return (
    <Grid item {...breakpoints}>
      <Panel title={title} {...props}/>
    </Grid>
  )
}

export default FormPanelComponent
