import React from 'react';
import ReactDOMServer from 'react-dom/server';
import {createModule} from 'main/utils/reducers';
import commonReducers from '../commonReducers'

import {form, schema, defaults as formDefaults} from './form'


const markup = ReactDOMServer.renderToStaticMarkup

const module = createModule({
  slice: 'FormWarranty',
  initialState: {
    name: 'Warranty',
    nextText: 'Proceed to Order Summary',
    active: false,
    completed: false,
    error: false,
    disabled: true,
    locked: false,
    skip: false,
    message: '',
    optional: false,
    entries: {},
    valueDefaults: formDefaults,
    values: formDefaults,
    context: {
      left: {
        title: markup(<p>Step 5: <br/>Your Warranty</p>),
        content: markup(
          <div>
            <p>Based on the vehicle and the mods chosen, we have selected PowerPlus Standard - 5 Years / 60k miles for your product.</p>
          </div>
        )
      },
      right: {
        title: 'Warranty Details',
        content: markup(
  <p>Click the links below for product comparison

  PowerPlus Standard
  PowerPlus Performance
  PowerPlus HP</p>
        )
      }
    }
  },
  reducers: {
    ...commonReducers(schema),
  },
  extraReducers: {
    'WarrantySteps/setDraft': (state, action) => {
      const valid = action.payload.valid
      state.locked = !valid
      state.disabled = !valid
    }
  }
})

export const { mapState, mapDispatch, slice, reducer, selector } = module
export {form, schema, formDefaults}

export default module.reducer
