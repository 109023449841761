import React from "react";
import { DatePicker } from "material-ui-pickers";
import TextField from './TextField'
import fonts from 'assets/scss/fonts.module.scss'


const DateComponent = ({
  margin = "normal",
  variant = "outlined",
  fullWidth = true,
  InputProps = {className: fonts['textfield']},
  InputLabelProps = {className: fonts['textfield-label']},
  TextFieldComponent = TextField,
  ...props
}) => {

  return (
    <DatePicker
      {...{margin, variant, fullWidth, InputProps, InputLabelProps, TextFieldComponent, ...props}}
      disableOpenOnEnter
      animateYearScrolling={false}
      autoOk={true}
      clearable
      emptyLabel=""
      allowKeyboardControl
      keyboard
      mask={value =>
       // handle clearing outside if value can be changed outside of the component
       value ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/] : []
     }
     format="MM/DD/YYYY"
     placeholder="mm/dd/yyyy"
    />
  )
}

export default DateComponent
