import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import style from './square.module.scss'
import {Caption} from 'assets/Text'

import {FormRadio} from 'assets/forms/FormInput'
import {FormStepperNext} from 'assets/forms/FormButton'
import FormGroup from 'assets/forms/FormGroup'
import Button from 'assets/Button'

class CardsComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cardId: undefined
    }
  }

  onCardChange = (e) => {
    this.setState({cardId: e.target.value})
  }

  onNext = (e) => {
    e.preventDefault()
    this.props.onNext(this.state.cardId)
  }

  removeCard = (cardId) => async () => {
    const {result, response} = await window.IDA.api.square.remove_card({cardId: cardId})
    await this.props.refreshCards()
  }

  render() {
    return (<Grid container alignItems='center' justify='center' spacing={8}>
      <Grid item xs={12}>
        <Caption>Select a saved payment method.</Caption>
      </Grid>
      <FormGroup type='radio' spacing={0}>
        {
          this.props.cards.map((card, index) => {
            return (<Grid key={index} item xs={12}>
              <Grid container justify='center' alignItems='center'>
                <Grid item xs={9}>
                  <FormRadio value={card.id} name='card_value' xs={12} checked={this.state.cardId == card.id} onChange={this.onCardChange}>
                    Pay {this.props.order.total} with {card.card_brand} card ending in {card.last_4}
                  </FormRadio>
                </Grid>
                <Grid item xs={3}>
                  <Button fullWidth onClick={this.removeCard(card.id)}>
                    <Caption>Remove Card</Caption>
                  </Button>
                </Grid>
              </Grid>
            </Grid>)
          })
        }
        <FormRadio value='new' name='card_value' xs={12} checked={this.state.cardId == 'new'} onChange={this.onCardChange}>
          Pay with New Card
        </FormRadio>
      </FormGroup>
      <FormStepperNext xs={12} md={6} disabled={!this.state.cardId} onClick={this.onNext}>
        {this.state.cardId === 'new' ? 'Enter New Card Information' : 'Proceed with Payment'}
      </FormStepperNext>
    </Grid>)
  }
}

export default CardsComponent
