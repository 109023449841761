import React, { Component } from 'react';

import style from 'assets/tables/tables.module.scss'

import Grid from '@material-ui/core/Grid';
import Panel from 'assets/panels/Panel';

import {FormDate, FormText} from 'assets/forms/FormInput'
import {FormCaption} from 'assets/forms/FormText'
import FormButton from 'assets/forms/FormButton'
import {NavButton} from 'assets/Button'
import {Caption} from 'assets/Text'
import Hidden from '@material-ui/core/Hidden';
import NextIcon from '@material-ui/icons/ChevronRight';
import PrevIcon from '@material-ui/icons/ChevronLeft';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import SortAscIcon from '@material-ui/icons/ExpandLess';
import SortDescIcon from '@material-ui/icons/ExpandMore';

import Button from '@material-ui/core/Button';

//import URL from 'url';
import _ from 'lodash'

const Header = (props) => {
  const {xs, sm, md, lg, xl} = {...props}
  const breakpoints = {xs, sm, md, lg, xl}

  return (
    <FormCaption className={style['header']} {...breakpoints }>
      {props.children}
    </FormCaption>
  )
}

const Cell = (props) => {
  return (
    <FormCaption className={style['cell']} {...props}/>
  )
}

const Row = ({header, ...props}) => {
  return (
    <Grid container alignItems='center' justify='center' className={header ? style['header-row'] : ''} spacing={8} {...props}/>
  )
}

class TableComponent extends Component {
  constructor(props) {
    super(props);
  }

  pageNext = (e) => {
    const {offset, limit, count} = {...this.props.data}
    this.props.onPaginate({offset: offset + limit, limit})
  }

  pagePrev = (e) => {
    const {offset, limit, count} = {...this.props.data}
    this.props.onPaginate({offset: Math.max(offset - limit, 0), limit})
  }

  pageFirst = (e) => {
    const {offset, limit, count} = {...this.props.data}
    this.props.onPaginate({offset: 0, limit})
  }

  pageLast = (e) => {
    const {offset, limit, count} = {...this.props.data}
    const lastOffset = Math.floor(count / limit) * limit
    this.props.onPaginate({offset: lastOffset, limit})
  }

  getOrderings = () => {
    const ordering = this.props.data.ordering
    if (ordering) {
      const orderings = ordering.split(',')
      return orderings
    }
    else {
      return []
    }
  }

  onOrder = (field) => (e) => {
    /*
    Ordering parameter is of the format ?ordering=field1,-field2 where
    a negative means descending.

    When a column is clicked, we must parse and modify the state according
    to this format.
    */
    const ordering = this.props.data.ordering
    // We use Map to preserve order
    let orderings = new Map()
    if (ordering) {
      const orderingList = ordering.split(',')
      for (let value of orderingList) {
        const valueField = value.startsWith('-') ? value.slice(1) : value
        orderings.set(valueField, value)
      }
    }

    const fieldValue = orderings.get(field)

    // Cycle through asc, desc, and no order every time column is clicked
    let newFieldValue
    switch(fieldValue) {
      case field:
        newFieldValue = `-${field}`
        break;
      case `-${field}`:
        newFieldValue = null
        break;
      default:
        newFieldValue = field
        break;
    }

    // We want to put the clicked column to the highest priority in ordering list
    //  so we remove it from our Map and readd it to our Array
    orderings.delete(field)
    const newOrderingString = [newFieldValue, ...Array.from(orderings.values())].join(',')
    this.props.onOrder(newOrderingString)
  }

  render() {
    const rows = this.props.data.results
    const headers = this.props.headers

    const {offset, limit, count} = {...this.props.data}
    const page = Math.floor(offset / limit) + 1
    const pages = Math.ceil(count / limit)

    const isFirstPage = offset < limit
    const isLastPage = (offset + limit) >= count

    const rowData = rows.map((row, index) => {
      return headers.map((cell, index) => (
        { value: cell.get(row), grid: cell.grid }
      ))
    })

    const Column = (props) => {
      const ordering = props.ordering

      const field = props.orderByField

      let SortIcon
      if (ordering.includes(field)) {
        SortIcon = SortAscIcon
      }
      else if (ordering.includes(`-${field}`)) {
        SortIcon = SortDescIcon
      }
      else {
        SortIcon = () => (null)
      }

      return (
        <Button
          disabled={!field}
          fullWidth
          className={style['header-button']}
          color='secondary'
          onClick={this.onOrder(field)}
        >
          <SortIcon/>
          <span>
            {props.children}
          </span>
        </Button>
      )
    }

    const paginationControls = (
      <Grid item xs={12}>
        <Grid container spacing={16} className={style['paging']} alignItems='center' justify="center">
          <Hidden smDown>
            <Grid item xs={12} md={2} className="text-align-center">
              <Button disabled={isFirstPage} onClick={this.pageFirst} color='secondary'>
                <FirstPageIcon/><Caption>First</Caption>
              </Button>
            </Grid>
          </Hidden>
          <Grid item xs={4} md={2} className="text-align-center">
            <Button disabled={isFirstPage} onClick={this.pagePrev} color='secondary'>
              <PrevIcon/><Caption>Previous</Caption>
            </Button>
          </Grid>
          <Grid item xs={4} md={2} className="text-align-center">
            <Caption>Page {page}/{pages}</Caption>
          </Grid>
          <Grid item xs={4} md={2} className="text-align-center">
            <Button disabled={isLastPage} onClick={this.pageNext} color='secondary'>
              <Caption>Next</Caption><NextIcon/>
            </Button>
          </Grid>
          <Hidden smDown>
            <Grid item xs={4} md={2} className="text-align-center">
              <Button disabled={isLastPage} onClick={this.pageLast} color='secondary'>
                <Caption>Last</Caption><LastPageIcon/>
              </Button>
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
    )

    return (
      <Grid container spacing={24}>
        {!this.props.simple &&
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} md={8}>
                <Grid container spacing={24}>
                  <FormDate
                    value={this.props.data.fromDate}
                    onChange={this.props.onFromDate}
                    InputProps={{className: style['table-input']}}
                    xs={6}
                    md={4}
                    name='order_from_date'
                    label='From Date'
                    variant='standard'
                    disableFuture
                    initialFocusedDate={this.props.data.toDate || undefined}
                    maxDate={this.props.data.toDate || undefined}
                  />
                  <FormDate
                    value={this.props.data.toDate}
                    onChange={this.props.onToDate}
                    InputProps={{className: style['table-input']}}
                    xs={6}
                    md={4}
                    name='order_from_date'
                    label='To Date'
                    variant='standard'
                    disableFuture
                    initialFocusedDate={this.props.data.fromDate || undefined}
                    minDate={this.props.data.fromDate || undefined}
                  />
                  <FormText value={this.props.data.search} onChange={this.props.onSearch} InputProps={{className: style['table-input']}} xs={6} md={4} name='order_from_date' label='Search' variant='standard'/>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid container spacing={8}>
                  <Grid item xs={12} className='text-align-right'>
                    <Button color="secondary" onClick={this.props.onClear}>Clear Search</Button>
                  </Grid>
                  <Grid item xs={12} className='text-align-right'>
                    <Button disabled={this.props.data.exporting} onClick={this.props.onExport} color="secondary">Export to CSV</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        <Grid item xs={12}>
          <Panel noBorder={this.props.noBorder}>
            <Grid container spacing={16} className={style['table']}>
              <Grid item xs={12}>
                <Hidden smDown>
                  <Row header>
                    {headers.map((row, index) => (
                      <Header
                        key={index}
                        {...row.grid}
                      >
                        <Column
                          ordering={this.getOrderings()}
                          orderByField={row.orderByField}
                        >
                          {row.name}
                        </Column>
                      </Header>
                    ))}
                  </Row>
                </Hidden>
                {rowData.length > 0 && (rowData.map((row, index) => {
                  return (
                    <Row key={index}>
                      {row.map((cell, cellIndex) => (
                        <Cell key={cellIndex} {...cell.grid}>
                          <Hidden mdUp>
                            <Header>{headers[cellIndex].name}</Header>
                          </Hidden>
                          {cell.value}
                        </Cell>
                      ))}
                    </Row>
                )})) ||
                  <Caption> No data </Caption>
                }
              </Grid>
              {!this.props.simple && paginationControls}
            </Grid>
          </Panel>
        </Grid>
      </Grid>
    )
  }
}


const Table = TableComponent

export default TableComponent
export {Table, Row, Header, Cell}
