import React from 'react'
import { NavLink } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import font from 'assets/scss/fonts.module.scss'
import style from 'assets/scss/button.module.scss'
import classNames from 'classnames'

const types = {
  'nav': NavLink,
}

const ButtonComponent =  ({
  type,
  className,
  classes,
  ...props
}) => {
  const Component = types[type] || 'button'

  const typeAttr = type == 'submit' ? 'submit' : 'button'

  return (
    <Button type={typeAttr} component={Component} classes={classes} className={classNames(font.button, className)} {...props}/>
  )
}

const IDAButton = (props) => (
  <ButtonComponent type="button" color="primary" {...props} />
)

export const NavButton = (props) => (
  <ButtonComponent
    type="nav"
    to="#"
    {...props} />
)

export const FatNavButton = (props) => (
  <ButtonComponent
    type="nav"
    className={style['fat']}
    variant="contained"
    color="secondary"
    fullWidth={true}
    {...props} />
)

export default IDAButton
