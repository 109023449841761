import React from 'react';
import ReactDOMServer from 'react-dom/server';
import {createModule} from 'main/utils/reducers';
import commonReducers from '../commonReducers'

import {form, schema, defaults as formDefaults} from './form'


const markup = ReactDOMServer.renderToStaticMarkup

// Take out set_value as we need to set a custom reducer for it.
const {set_value, ...reducers} = commonReducers(schema)

const module = createModule({
  slice: 'FormVehicle',
  initialState: {
    name: 'Vehicle',
    nextText: 'Proceed to Step 3: Consumer',
    active: false,
    completed: false,
    error: false,
    disabled: true,
    locked: false,
    skip: false,
    message: '',
    optional: false,
    entries: {},
    valueDefaults: formDefaults,
    values: formDefaults,
    context: {
      left: {
        title: markup(<p>Step 2: <br/>Vehicle Information</p>),
        content: markup(
          <div>
            <p>Enter the Vehicle information for which the modifications were done.</p>
            <p>The vehicle information will be connected with your warranty.  A copy of the warranty should be attached to the vehicle by the dealership for the consumer.</p>
          </div>
        )
      },
      right: {
        title: markup('Enter the VIN first'),
        content: markup(
  <p>A completed VIN field will autofill the Year, Make, Model, and Trim.</p>
        )
      }
    }
  },
  reducers: {
    ...reducers,
    set_value(state, action) {
      const {field, value} = action.payload
      state.values[field].value = value
      state.entries[field] = value

      if (field == 'vehicle_sold') {
        const consumerDisabled = !value
        if (consumerDisabled) {
          state.nextText = 'Proceed to Step 4: Modifications'
        }
        else {
          state.nextText = 'Proceed to Step 3: Consumer'
        }
      }
    },
  }
})

export const { mapState, mapDispatch, slice, reducer, selector } = module
export {form, schema, formDefaults}

export default module.reducer
