// main app object

import api from 'main/global/api/api'
import snack from 'main/global/snack'

const IDA = {
  api,
  snack
}

window.IDA = IDA
export default IDA
