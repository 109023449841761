import Joi from 'joi-browser';
import {processForm} from 'warranties/forms/formUtilities'
import states from 'assets/utility/states'

const form = {
  dealer_name: {
    label: 'Dealership Name',
    joi: Joi.string().min(3).max(30).required().error(
      (errors) => {
        return {
          message: "Dealership Name Required."
        }
      }
    ).tags('auto').tags('async'),
    suggestions: [],
    message: 'Begin typing the dealer name to create a new dealer'

  },
  dealer_street: {
    label: 'Street Address',
    joi: Joi.string().min(3).max(60).required().error(
      (errors) => {
        return {
          message: "Street Address Required."
        }
      }
    ),
  },
  dealer_city: {
    label: 'City',
    joi: Joi.string().min(3).max(30).required().error(
      (errors) => {
        return {
          message: "US City Required."
        }
      }
    ),
  },
  dealer_state: {
    label: 'State',
    joi: Joi.string().alphanum().min(2).max(2).required().error(
      (errors) => {
        return {
          message: "US State Required."
        }
      }
    ).tags('auto'),
    suggestions: states
  },
  dealer_zip: {
    label: 'Zip',
    joi: Joi.string().regex(/^[0-9]{5}(?:-[0-9]{4})?$/).required().error(
      (errors) => {
        return {
          message: "US Zip Code Required."
        }
      }
    ),
  },
  dealer_email: {
    label: 'E-Mail',
    joi: Joi.array().items(Joi.string().email({ minDomainAtoms: 2 }).allow('')),
    message: 'Optional'
  }
}

// Create Joi validation schema and form defaults for redux
const {schema, defaults} = processForm(form)

export {form, schema, defaults}
