
const wsPort = process.env.REACT_APP_WS_PORT

const settings = {
  version: 'v1',
  urlBase: 'api',
  wsBase: 'ws',
  wsPort: wsPort
}

export default settings
