import React from 'react';
import { connect } from 'react-redux'
import store from 'main/store/store'
import * as snackbarModule from 'main/utils/snackbar/module'
import * as module from './module';
import * as configModule from 'config/module'
import * as schemaModule from 'warranties/schema/module'

import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormGroup from 'assets/forms/FormGroup'

import {FormCaption} from 'assets/Text'

import style from '../forms.module.scss'

import {form as formSpecs, schema as formSchema} from './form'
import Joi from 'joi-browser';
import {processForm} from 'warranties/forms/formUtilities'
import generateFormComponent from 'warranties/forms/FormComponent'

import {setWarrantyFromDraft} from 'warranties/stepper/utility'

const FormComponent = generateFormComponent(formSpecs, formSchema)
const snackbarDispatch = snackbarModule.mapDispatch(store.dispatch)

class VehicleFormComponent extends FormComponent {

  initializeState = () => {
    this.setState({
      isProcessingVIN: false,
      failedProcessingVIN: false,
      oemOptions: {},
      vehicleOptions: {},
      vehicleMakes: [],
      vehicleModels: [],
      years: []

    })
  }

  componentDidMount = () => {
    /*
      Grab our custom defined oem and vehicle options from backend,
        then set the correct Joi schema for these options.
    */
    const config = configModule.selector(store.getState())
    const schemas = schemaModule.mapDispatch(store.dispatch)
    const oemOptions = config.original_equipment
    const vehicleOptions = config.vehicle_options
    const gvwrOptions = config.gvwrs

    const newForm = formSpecs
    for (const [key, label] of Object.entries(oemOptions)) {
      newForm[`vehicle_${key}`] = {
        label: label,
        joi: Joi.boolean(),
        default: null,
      }
    }
    const {schema, defaults} = processForm(newForm)
    for (const [field, value] of Object.entries(defaults)) {
      if (!this.props.values.hasOwnProperty(field)) {
        this.props.create_value({field, value})
      }
    }

    newForm['vehicle_year'].suggestions = config.years.map((value) => ({value, label: value}))
    newForm['vehicle_make'].suggestions = Object.keys(config.vehicle_models).map((value) => ({value, label: value}))
    newForm['vehicle_gvwr'].suggestions = gvwrOptions.map((gvwr) => ({value: gvwr.name, label: gvwr.name}))

    const vehicleMakeValue = this.props.values['vehicle_make'].value
    if (vehicleMakeValue) {
      newForm['vehicle_model'].suggestions = config.vehicle_models[vehicleMakeValue.value].map((value) => ({value, label: value}))
    }

    schemas.set_schema({form: module.slice, schema})
    this.setState({
      formSpecs: newForm,
      schema: schema,
      oemOptions,
    })
    super.componentDidMount()
  }

  onVINChange = async (event) => {
    /*
      When the VIN changes, we want to hit the server to autofill as much vehicle
        info as possible.
    */

    const field = event.target.name
    const validation = await this.onTextChange(field)(event)
    if (!validation.error) {
      this.setState({ isProcessingVIN: true})

      const formKeys = Object.keys(this.props.valueDefaults)
      formKeys.splice(formKeys.indexOf('vehicle_vin'), 1)
      formKeys.forEach(key => this.props.set_default_value(key))

      const {response, result} = await window.IDA.api.vin({vin: this.props.entries[field]})

      // if this fails, lets fail silently.
      this.setState({ failedProcessingVIN: response.status != 201 })
      if (!this.state.failedProcessingVIN) {
        const vehicleInfo = result
        const driveType = vehicleInfo.info.Results[0].DriveType
        const fuelType = vehicleInfo.info.Results[0].FuelTypePrimary
        const isDiesel = (fuelType && fuelType.trim().toLowerCase().includes('diesel')) || false
        const is4x4 = (driveType && driveType.trim().toLowerCase().includes('4x4')) || false
        const values = {
          'vehicle_vin_validated': true,
          'vehicle_year': vehicleInfo.info.Results[0].ModelYear,
          'vehicle_make': vehicleInfo.info.Results[0].Make,
          'vehicle_model': vehicleInfo.info.Results[0].Model,
          'vehicle_trim': vehicleInfo.info.Results[0].Trim + ' ' + vehicleInfo.info.Results[0].Series,
          'vehicle_4x4': is4x4,
          'vehicle_diesel': isDiesel,
          'vehicle_gvwr': vehicleInfo.info.Results[0].GVWR
        }

        setWarrantyFromDraft({draft: {...this.props.entries, ...values}, onlySpecified: true})
      }
      else {
        const values = {
          'vehicle_vin_validated': false,
          'vehicle_year': this.props.valueDefaults['vehicle_year']['value'],
          'vehicle_make': this.props.valueDefaults['vehicle_make']['value'],
          'vehicle_model': this.props.valueDefaults['vehicle_model']['value'],
          'vehicle_trim': this.props.valueDefaults['vehicle_trim']['value'],
          'vehicle_4x4': this.props.valueDefaults['vehicle_4x4']['value'],
          'vehicle_diesel': this.props.valueDefaults['vehicle_diesel']['value'],
          'vehicle_gvwr': this.props.valueDefaults['vehicle_gvwr']['value'],
        }
        setWarrantyFromDraft({draft: {...this.props.entries, ...values}, onlySpecified: true})

        // if VIN VPIC validation server is not responding
        if (response.status != 406) {
          this.showSnack({messages: ['VIN Validation is temporarily unavailable.  Please try again later.']})
        }
      }

      this.props.VALIDATE_FORM()
      this.props.SET_ALL_ERRORS()
    }
    this.setState({ isProcessingVIN: false})
  }

  onMakeChange = async (value) => {
    const formSpecs = this.state.formSpecs
    const config = configModule.selector(store.getState())
    formSpecs['vehicle_model'].suggestions = config.vehicle_models[value.value].map((value) => ({value, label: value}))
    this.setState({formSpecs})
    this.onAutocompleteChange('vehicle_make')(value)
    this.onAutocompleteChange('vehicle_model')(null)
  }

  showSnack = ({messages, variant='error', duration=null}) => {
    snackbarDispatch.OPEN(
      {message: <ul>
        {messages.map((message, index) => (
          <li>{message}</li>
        ))}
      </ul>,
       variant: 'error',
       duration: null
     }
   )
  }

  render = () => {
    const TextField = this.TextField
    const CheckboxField = this.CheckboxField
    const RadioField = this.RadioField
    const DateField = this.DateField
    const AutocompleteField = this.AutocompleteField

    const VINInputProps = {
      disabled: this.state.isProcessingVIN,
      InputProps: {
        startAdornment: (
          <InputAdornment position="start" classes={{positionStart: style['spinner']}}>
            <div>
              {this.state.isProcessingVIN &&
                <CircularProgress color="secondary" size={20} />
              }
            </div>
          </InputAdornment>
        ),
      }
    }

    const OEMOptions = []
    if (this.state.oemOptions) {
      for (const [key, label] of Object.entries(this.state.oemOptions)) {
        OEMOptions.push(
          <CheckboxField xs={6} lg={12} key={key} name={`vehicle_${key}`}>{label}</CheckboxField>
        )
      }
    }

    return (
      <Grid container spacing={24} className={style['form']}>
        <Grid item xs={12}>
          <h6>Enter Vehicle Information</h6>
        </Grid>
        <TextField xs={12} sm={6} lg={4} maxLength={17} onChange={this.onVINChange} name='vehicle_vin'
          error={this.props.values['vehicle_vin'].error || this.props.values['vehicle_vin_validated'].error}
          helperText={this.props.values['vehicle_vin'].message || this.props.values['vehicle_vin_validated'].message}
        />
        <Grid item xs={12}>
          <Grid container spacing={16}>
            <AutocompleteField xs={6} lg={2} {...VINInputProps} name='vehicle_year'/>
            <AutocompleteField onChange={this.onMakeChange} xs={6} lg={3} {...VINInputProps} name='vehicle_make'/>
            <AutocompleteField xs={6} lg={3} {...VINInputProps} name='vehicle_model'/>
            <TextField xs={6} lg={4} {...VINInputProps} name='vehicle_trim'/>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={16}>
            <TextField xs={6} lg={2} name='vehicle_odometer' maxLength={7}/>
            <TextField
              xs={6}
              lg={2}
              name='vehicle_msrp'
              maxLength={6}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}/>

            <DateField xs={6}  lg={3} name='vehicle_build_date'/>

          </Grid>
        </Grid>

        <FormGroup type='checkbox' xs={12} lg={5} spacing={0}>
          <Grid item xs={12}>
            <FormHelperText xs={12} component={FormCaption}>
              OEM Options
            </FormHelperText>
          </Grid>
          {OEMOptions}
        </FormGroup>
        <Grid item xs={12} lg={5}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <FormHelperText component={FormCaption} error={this.props.values['vehicle_sold'].error}>
                Has this vehicle been sold?
              </FormHelperText>
            </Grid>
            <Grid item xs={6} lg={4}>
              <FormGroup type='radio' spacing={0}>
                <RadioField xs={12} value={true} name='vehicle_sold'>Yes</RadioField>
                <RadioField xs={12} value={false} name='vehicle_sold'>No</RadioField>
              </FormGroup>
              {this.props.values['vehicle_sold'].error &&
                <FormHelperText error>{this.props.values['vehicle_sold'].message}</FormHelperText>
              }
            </Grid>
            <DateField xs={6} lg={8} name='vehicle_sold_date' className={style['sold-date']}/>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={5}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <FormHelperText component={FormCaption} error={this.props.values['vehicle_used'].error}>
                Is this vehicle NEW or Used?
              </FormHelperText>
            </Grid>
            <Grid item xs={12} lg={12}>
              <FormGroup type='radio' spacing={0}>
                <RadioField xs={12} value={false} name='vehicle_used'>This vehicle is NEW</RadioField>
                <RadioField xs={12} value={true} name='vehicle_used'>This vehicle is Used</RadioField>
              </FormGroup>
              {this.props.values['vehicle_used'].error &&
                <FormHelperText error>{this.props.values['vehicle_used'].message}</FormHelperText>
              }
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={2}/>
        <TextField xs={12} lg={8} multiline rows={3} name='vehicle_notes'/>
      </Grid>
    )
  }
}
const form = connect(module.mapState, module.mapDispatch)(VehicleFormComponent)
export {module, form}
export default form
