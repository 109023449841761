import React, {Component} from 'react';
import ReactDOM from 'react-dom';

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';

import style from './input.module.scss'

class SelectFieldComponent extends Component {

  render = () => {
    const {label, children, helperText, classes, ...props} = this.props
    return (
      <FormControl variant="outlined" fullWidth={true}>
        <InputLabel
          ref={ref => {
            this.labelRef = ReactDOM.findDOMNode(ref);
          }}
        >
          {label}
        </InputLabel>
        <Select
          input={
            <OutlinedInput
              fullWidth={true}
              labelWidth={this.labelRef ? this.labelRef.offsetWidth : 0}
              {...props}
            />
          }
          variant="outlined"
          className={style['selectfield']}
         >
           {children}
        </Select>
        <FormHelperText
          classes={{...classes}}
          error={props.error}
          disabled={props.disabled}
          className={style['formhelper-text']}
        >{helperText}
        </FormHelperText>
      </FormControl>
    )
  }
}

export default SelectFieldComponent
