import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import { mapState, mapDispatch } from './module';

import { withTheme } from '@material-ui/core/styles';
import Button from 'assets/Button';
import Grid from '@material-ui/core/Grid';
import style from './auth.module.css'
import Logo from 'assets/Logo';
import Async from 'main/utils/async/Async'
import EmailField from 'assets/input/EmailField'
import {Caption} from 'assets/Text'

class PasswordResetComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      errors: [],
      success: false
    }
  }

  onEmailChange = (e) => {
    this.setState({email: e.target.value})
  }

  onSubmit = async (e) => {
    e.preventDefault()
    const username = this.state.email
    const {response, result} = await window.IDA.api.passwordReset({username})
    switch (response.status) {
      case 204:
        this.setState({success: true})
        this.props.set_messages(['Password reset link sent.  Please check your e-mail.'])
        break;
      default:
        this.setState({errors: ['Unable to send reset link.']})
        throw(`Unable to send reset link.  Response ${response.status}.`)
        break;
    }
  }

  render() {
    if (this.state.success) {
      return <Redirect push={false} to='/login' />
    }
    return (
      <Grid
        container direction="row" alignItems="stretch" justify="center"
        >
        <Grid item xs={12} className="App-header">
          <Button href='/'>
            <Logo />
          </Button>
        </Grid>
        <Grid item xs={10} sm={6} md={4} lg={3} xl={2}>
          <Async>
            <form className={style.form} onSubmit={this.onSubmit} autoComplete="new-password">
              <h5> Forgot Password </h5>
              {this.state.errors.map((message, key) => (
                <Caption key={key} style={{color: this.props.theme.palette.primary.error}}>
                  {message}
                </Caption>
              ))}
              <Grid item>
                <EmailField
                  name='username'
                  required={true}
                  onChange={this.onEmailChange}
                  value={this.state.email}
                  disabled={this.props.asyncState.active}
                  label='Email'
                />
              </Grid>
              <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-end"
                >
                <Button disabled={this.props.asyncState.active || this.state.success} type="submit" color="primary">Send Password Reset Link</Button>
              </Grid>
            </form>
          </Async>
        </Grid>
      </Grid>
    );
  }
}

export default connect(mapState, mapDispatch)(withRouter(withTheme()(PasswordResetComponent)));
