import React, { Component } from 'react';
import 'assets/scss/app.scss';
import 'assets/scss/fonts.module.scss';
import { connect } from 'react-redux'
import * as authModule from 'auth/module'

import { Redirect, Switch } from 'react-router-dom'

import LayoutComponent from './Layout'
import Login from 'auth/Login'
import Home from 'home/Home'

import Route from 'main/utils/route/Route'
import JssProvider from 'main/utils/jss'
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';

import routes from './routes'
import Sentry from './sentry'


const VersionTracker = connect(authModule.mapState, authModule.mapDispatch)((props) => {
  const mismatch = props.versionMismatch

  if (mismatch) {
    localStorage.clear()
    throw('version mismatch')
  }
  return null
})

class App extends Component {
  render() {
    return (
      <JssProvider>
        <Sentry>
          <VersionTracker/>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <LayoutComponent>
              <Switch>
                <Redirect exact from='/warranties/new' to='/warranties/new/dealership'/>
                {routes.map((route, i) => {
                  return <Route key={i} {...route} />
                })}
              </Switch>
            </LayoutComponent>
          </MuiPickersUtilsProvider>
          </Sentry>
        </JssProvider>
    );
  }
}

export default App;
