import React from 'react'
import Checkbox from '@material-ui/core/Checkbox';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import {FormCaption} from 'assets/Text'

import style from './input.module.scss'

const CheckboxComponent = ({
  children,
  ...props
}) => {
  const options = {
    'control': <Checkbox className={style['checkbox']} {...props} />,
    'label': <FormCaption>{children}</FormCaption>
  }
  return (
    <FormControlLabel {...options} />
  )
}


export default CheckboxComponent
