import React from 'react'
import fonts from 'assets/scss/fonts.module.scss'
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles'
import style from './input.module.scss'
import classNames from 'classnames'


const TextFieldComponent = ({
  className = style['textfield'],
  margin = "normal",
  variant = "outlined",
  fullWidth = true,
  InputProps = {},
  InputLabelProps = {className: fonts['textfield-label']},
  FormHelperTextProps={className: style['formhelper-text']},
  classes,
  maxLength,
  ...props
}) => (
    <TextField {...{
      className,
      margin,
      variant,
      fullWidth,
      InputProps: {...InputProps, className: classNames(InputProps['className'], fonts['textfield'], style['textfield']), classes: {...classes}},
      inputProps: {maxLength, autoComplete:'new-password', className: style['textfield-input']}, // new-password value is specified by mdn
      InputLabelProps: {...InputLabelProps, classes: {...classes}},
      FormHelperTextProps: {...FormHelperTextProps, classes: {...classes}},
      ...props}}
    />
)


export default TextFieldComponent
