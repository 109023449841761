import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import {persistor} from 'main/store/store';

import { withTheme } from '@material-ui/core/styles';
import Button from 'assets/Button';
import Grid from '@material-ui/core/Grid';

import Logo from 'assets/Logo';
import { mapState, mapDispatch } from './module';
import * as token from 'auth/token'
import fonts from 'assets/scss/fonts.module.scss'
import style from './auth.module.css'

import Async from 'main/utils/async/Async'

import EmailField from 'assets/input/EmailField'
import PasswordField from 'assets/input/PasswordField'

import {Caption} from 'assets/Text'
import {NavButton} from 'assets/Button'


class LoginComponent extends Component {

  state = {
    username: '',
    password: '',
    isFocused: false,
    asyncActive: false,
    isAuthenticated: null,
    isMounted: false
  }

  componentDidMount = async () => {
    this.setState({isMounted: true})
    if (this.props.match.params['state'] != 'showmessage') {
      this.props.clear_messages()
    }
    await this.setIsAuthenticated()
    if (this.state.isAuthenticated) {
      this.props.logout()
      await persistor.purge()
    }
  }

  componentWillUnmount = () => {
    this.setState({isMounted: false})
  }

  setIsAuthenticated = async () => {
    const isAuthenticated = await token.isAuthenticated(this.props)
    this.state.isMounted && this.setState({isAuthenticated})
  }

  onEmailChange = (event) => {
    const email = event.target.value
    this.setState({
      username: email,
      isFocused: true
    })
  }

  onPassChange = (event) => {
    const pass = event.target.value
    this.setState({
      password: pass,
      isFocused: true
    })
  }

  onFormFocus = (event) => {
    this.setState({
      isFocused: true
    })
  }

  onLogin = async (event) => {
    event.preventDefault()
    this.setState({
      isFocused: false
    })

    const {username, password} = this.state
    let {response, result} = await window.IDA.api.getToken({username, password})

    const loginToken = result
    switch(response.status) {
      case 200:
        // Probably a good login
        if (loginToken.access && loginToken.refresh) {
          this.props.login_success(loginToken)
          let {response, result} = await window.IDA.api.me()
          const me = result
          this.props.set_user(me)
          await this.setIsAuthenticated()
        }
        else {
          // This shouldn't happen on HTTP 200, but if it does, set login_failure
          this.props.login_failure(loginToken)
        }

        break;

      case 401:
        // Invalid account info
        this.props.login_failure(loginToken)
        break;

      default:
        break;
    }
  }

  onPassReset = async () => {
    const result = window.IDA.api.passwordReset()
  }

  get errors() {
    return this.props.errors
  }

  get shouldOutlineRed() {
    return this.props.errors.length > 0 && !this.state.isFocused
  }

  render() {
    if (this.state.isAuthenticated) {
      return <Redirect push={false} to='/' />
    }

    return (
      <Grid
        container direction="row" alignItems="stretch" justify="center"
        >
        <Grid item xs={12} className="App-header">
          <Logo />
        </Grid>
        <Grid item xs={10} sm={6} md={4} lg={3} xl={2}>
          <Async>
            <form className={style.form} onSubmit={this.onLogin} onFocus={this.onFormFocus} autoComplete="new-password">
              <h5> Warranty Service Login </h5>
              {this.errors &&
                <Caption style={{color: this.props.theme.palette.primary.error}}>
                  {this.errors.join(', ')}
                </Caption>
              }
              <Grid item>
                <EmailField
                  required={true}
                  onChange={this.onEmailChange}
                  error={this.shouldOutlineRed}
                  disabled={this.props.asyncState.active}
                />
              </Grid>
              <Grid item>
                <PasswordField
                 required={true}
                 onChange={this.onPassChange}
                 error={this.shouldOutlineRed}
                 disabled={this.props.asyncState.active}
                />
              </Grid>
              <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-end"
                >
                  {this.props.messages && this.props.messages.map((message, index) => (
                    <Caption key={index}>
                      {message}
                    </Caption>
                  ))}
                <Button disabled={this.props.asyncState.active} type="submit" color="primary">Login</Button>
                <NavButton disabled={this.props.asyncState.active} to='/account/password/reset/' color="primary">Forgot Password</NavButton>
              </Grid>
            </form>
          </Async>
        </Grid>
      </Grid>
    );
  }
}

export default connect(mapState, mapDispatch)(withRouter(withTheme()(LoginComponent)));
