import React, { Component } from 'react';
import styles from './nav.module.scss'
import Grid from '@material-ui/core/Grid';
import {persistor} from 'main/store/store';
import Button from '@material-ui/core/Button';

import { mapState, mapDispatch } from 'auth/module';
import { connect } from 'react-redux'

import {Subtitle, Caption} from 'assets/Text'
import { NavLink } from 'react-router-dom'

import DevDrawer from 'assets/drawers/DevDrawer'

class UserWidget extends Component {

  render() {
    return (
        <Grid container direction="row" justify="center" alignItems="flex-start">
          <div className={styles.userWidget}>
            <Subtitle>{this.props.user.company}</Subtitle>
            <Caption>{this.props.user.account}</Caption>
            <Caption>{this.props.user.street}, {this.props.user.city}, {this.props.user.state} {this.props.user.zip}</Caption>
            <Button component={NavLink} to='/logout' color="primary">Logout</Button>
            <DevDrawer/>
          </div>
        </Grid>
    )
  }
}

export default connect( mapState, mapDispatch)(UserWidget);
