import {createModule} from 'main/utils/reducers';

const module = createModule({
  slice: 'Home',
  initialState: {
    test: 'test2'
  },
  reducers: {

  },
})

export const { mapState, mapDispatch, slice, reducer, selector } = module

export default module.reducer
