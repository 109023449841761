import "@babel/polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import 'index.scss';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Provider } from 'react-redux'
import {store, persistor} from 'main/store/store'

import App from 'main/App';
import * as serviceWorker from './serviceWorker';

import { PersistGate } from 'redux-persist/integration/react'

import CssBaseline from '@material-ui/core/CssBaseline';

import 'main/ida'


import { TransitionGroup, CSSTransition } from "react-transition-group";

window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;


ReactDOM.render(

  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <CssBaseline />
         <App />
      </Router>
    </PersistGate>
  </Provider>
  ,
  document.getElementById('idaSite')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
