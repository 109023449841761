import {createModule} from 'main/utils/reducers';
import React from 'react';
import ReactDOMServer from 'react-dom/server';

const markup = ReactDOMServer.renderToStaticMarkup

const module = createModule({
  slice: 'OrderList',
  initialState: {
    orders: {
      results: [],
      count: 0,
      limit: 10,
      offset: 0,
      search: '',
      fromDate: null,
      toDate: null,
      completed_before: null,
      completed_after: null,
      ordering: null,
      exporting: null,
    },
    context: {
      left: {
        title: markup(<p>My<br/>Orders</p>),
        content: markup(
          <div>
            <p>All your purchased warranties are listed to the right.</p>
            <p>You can check order details or download your warranty PDF.</p>
          </div>
        )
      },
      right: {
        title: markup('Downloading Your Warranty'),
        content: markup(
          <div>
            <p>A download link will appear next to your order when it is ready.</p>
          </div>
        )
      }
    }
  },
  reducers: {
    set_orders(state, action) {
      const data = action.payload
      state.orders.results = data.results
      state.orders.count = data.count
    },
    set_page(state, action) {
      const {limit, offset} = {...action.payload}
      state.orders.limit = limit
      state.orders.offset = offset
    },
    set_search(state, action) {
      state.orders.search = action.payload
    },
    set_from_date(state, action) {
      const value = action.payload
      state.orders.fromDate = value
      state.orders.completed_after = value && value.startOf('day').toISOString(true)
    },
    set_to_date(state, action) {
      const value = action.payload
      state.orders.toDate = value
      state.orders.completed_before = value && value.endOf('day').toISOString(true)
    },
    set_ordering(state, action) {
      const value = action.payload
      state.orders.ordering = value
    },
    set_exporting(state, action) {
      state.orders.exporting = action.payload
    },
    clear_search(state, action) {
      state.orders = {
        ...state.orders,
        offset: 0,
        search: '',
        fromDate: null,
        toDate: null,
        completed_before: null,
        completed_after: null,
        ordering: null
      }
    }
  }
})

export const { mapState, mapDispatch, slice, reducer, selector } = module

export default module.reducer
