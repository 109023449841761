import Joi from 'joi-browser';
import {processForm} from 'warranties/forms/formUtilities'

const form = {
  mod_suspension_kits: {
    label: 'Lift Kits',
    joi: Joi.string().min(3).max(30).allow(''),
    default: ''
  },
  mod_engine_tune: {
    label: 'No Modification',
    joi: Joi.string().min(3).max(30).allow(''),
  },
  mod_tire_diameter: {
    label: 'Tire Diameter',
    joi: Joi.string().allow(''),
  },
  mod_tire_width: {
    label: 'Tire Width',
    joi: Joi.string().allow(''),
  },
  mod_tire_performance: {
    label: 'Performance Tire',
    joi: Joi.boolean(),
  },
  mod_wheel_diameter: {
    label: 'Wheel Diameter',
    joi: Joi.string().allow(''),
  },
  mod_wheel_width: {
    label: 'Wheel Width',
    joi: Joi.string().allow(''),
  },
  mod_wheel_performance: {
    label: 'Performance Wheel',
    joi: Joi.boolean(),
  },
  mod_exhaust_upgrades: {
    label: 'No Modification',
    joi: Joi.string().min(3).max(30).allow(''),
  },
  mod_accessories_front_bumper: {
    label: 'Front Bumper',
    joi: Joi.boolean(),
  },
  mod_accessories_rear_bumper: {
    label: 'Rear Bumper',
    joi: Joi.boolean(),
  },
  mod_accessories_tire_carrier: {
    label: 'Tire Carrier',
    joi: Joi.boolean(),
  },
  mod_accessories_winch: {
    label: 'Winch',
    joi: Joi.boolean(),
  },
  mod_accessories_side_steps: {
    label: 'Side Steps',
    joi: Joi.boolean(),
  },
  mod_accessories_bed: {
    label: 'Bed Accessories',
    joi: Joi.boolean(),
  },
  mod_accessories_interior: {
    label: 'Interior Upgrades',
    joi: Joi.boolean(),
  },
  mod_accessories_roof: {
    label: 'Roof Accessories',
    joi: Joi.boolean(),
  },
  mod_accessories_lighting: {
    label: 'Lighting',
    joi: Joi.boolean(),
  },
  mod_accessories_exhaust_tips: {
    label: 'Exhaust Tips',
    joi: Joi.boolean(),
  },
}

// Create Joi validation schema and form defaults for redux
let {schema, defaults} = processForm(form)

schema = schema.and(
  'mod_tire_diameter',
  'mod_tire_width',
).and('mod_wheel_diameter',
  'mod_wheel_width')

export {form, schema, defaults}
