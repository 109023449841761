import {get, post, del} from './rest'

import {wsGet} from './ws'

const API = {
  getToken: (data) => {
    const url = '/auth/token/'
    return post({url, data, auth: false})
  },
  refreshToken: (data) => {
    const url = '/auth/token/refresh/'
    return post({url, data, auth: false})
  },
  verifyToken: (data) => {
    const url = '/auth/token/verify/'
    return post({url, data, auth: false})
  },
  passwordReset: ({username}) => {
    const data = {username}
    const url = '/auth/password/reset/'
    return post({url, data, auth: false})
  },
  passwordResetConfirm: (data) => {
    const url = '/auth/password/reset/confirm/'
    return post({url, data, auth: false})
  },
  me: () => {
    const url = '/me/'
    return get({url})
  },
  faq: () => {
    const url = '/faq/'
    return get({url})
  },
  docs: () => {
    const url = '/docs/'
    return get({url})
  },
  vin: (data) => {
    const url = '/vin/'
    return post({url, data})
  },
  getRandomWarranty: () => {
    const url = '/test/random_warranty'
    return get({url})
  },
  account: {
    update: ({...data}) => {
      const url = '/me/'
      return post({url, data})
    }
  },
  dealers: {
    getByName: (data) => {
      const url = '/dealers/byName/'
      return post({url, data})
    }
  },
  warranties: {
    create: (data) => {
      const url = '/draftwarranties/'
      return post({url, data})
    },
    quote: (data) => {
      const url = '/draftwarranties/quote'
      return get({url})
    },
    list: ({offset, limit, search, ordering, created_before, created_after}={}) => {
      let url = `/warranties/`
      let paramValues = {}
      for (let [key, value] of Object.entries({offset, limit, search, ordering, created_before, created_after})) {
        if (value) {
          paramValues[key] = value
        }
      }
      const params = new URLSearchParams(paramValues)

      let paramString = params ? `?${params.toString()}` : ''
      url = url + paramString

      return get({url})
    },
    report: ({offset, limit, search, ordering, created_before, created_after}={}) => {
      let url = `/warranties/report/`
      let paramValues = {}
      for (let [key, value] of Object.entries({offset, limit, search, ordering, created_before, created_after})) {
        if (value) {
          paramValues[key] = value
        }
      }
      const params = new URLSearchParams(paramValues)

      let paramString = params ? `?${params.toString()}` : ''
      url = url + paramString

      return get({url, raw: true})
    }
  },
  orders: {
    create: (data) => {
      const url = '/orders/'
      return post({url, data})
    },
    get: (id) => {
      const url = `/orders/${id}`
      return get({url})
    },
    list: ({offset, limit, search, ordering, completed_before, completed_after}={}) => {
      let url = `/orders/`
      let paramValues = {}
      for (let [key, value] of Object.entries({offset, limit, search, ordering, completed_before, completed_after})) {
        if (value) {
          paramValues[key] = value
        }
      }
      const params = new URLSearchParams(paramValues)

      let paramString = params ? `?${params.toString()}` : ''
      url = url + paramString

      return get({url})
    },
    report: ({offset, limit, search, ordering, completed_before, completed_after}={}) => {
      let url = `/orders/report/`
      let paramValues = {}
      for (let [key, value] of Object.entries({offset, limit, search, ordering, completed_before, completed_after})) {
        if (value) {
          paramValues[key] = value
        }
      }
      const params = new URLSearchParams(paramValues)

      let paramString = params ? `?${params.toString()}` : ''
      url = url + paramString

      return get({url, raw: true})
    },
    pay: ({id, nonce, save_card, card_id}) => {
      const url = `/orders/${id}/pay/`
      const data = {nonce, save_card, card_id}
      return post({url, data})
    },
    retry_payment: ({id}) => {
      const url = `/orders/${id}/retry/`
      const data = {}
      return post({url, data})
    },
    receipt:(id) => {
      const url = `/orders/${id}/receipt`
      return get({url})
    },
    remove_warranty: ({order, warranty}) => {
      const url = `/orders/${order}/remove_warranty/`
      const data = {warranty}
      return del({url, data})
    },
    get_warranty: ({order, warranty}) => {
      const url = `/orders/${order}/get_warranty/`
      const data = {warranty}
      return post({url, data})
    },
    ws_get:(id) => {
      const url = `/orders/${id}/`
      return wsGet({url})
    }
  },
  square: {
    get_cards: () => {
      const url = `/square/get_cards/`
      return get({url})
    },
    remove_card: ({cardId}) => {
      const url = `/square/remove_card/`
      const data = {cardId}
      return post({url, data})
    }
  }
}

export default API
