import {createModule} from 'main/utils/reducers';

const module = createModule({
  slice: 'Orders',
  initialState: {

  },
  reducers: {

  }
})

export const { mapState, mapDispatch, slice, reducer, selector } = module

export default module.reducer
