import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';


class NotFoundComponent extends Component {

  render = () => {
    return (
      <Grid container direction="row" justify="start" spacing={16}>
        <Grid item xs={12}>
          <h5>404 Not Found</h5>
        </Grid>

        <Grid item xs={12} className='text-align-center'>

          <h6>This page does not exist.  Please check the URL and try again.</h6>
        </Grid>

      </Grid>
    )
  }
}
export default NotFoundComponent
