import store from 'main/store/store'
import {mapDispatch as authMapDispatch} from 'auth/module'

const appVersion = process.env.REACT_APP_VERSION
const Auth = authMapDispatch(store.dispatch)

export function accessToken(state) {
    if (state.access) {
        return  state.access.token
    }
}

export function refreshToken(state) {
    if (state.refresh) {
        return  state.refresh.token
    }
}

export function isAccessTokenExpired(state) {
  if (state.access && state.access.exp) {
    return 1000 * state.access.exp - (new Date()).getTime() < 5000
  }
  return true
}

export function isRefreshTokenExpired(state) {
  if (state.refresh && state.refresh.exp) {
    return 1000 * state.refresh.exp - (new Date()).getTime() < 5000
  }
  return true
}

export function wasAuthenticated(state) {
  return state.access && state.access.exp && isAccessTokenExpired(state)
}

export async function isAuthenticated(state) {
  if (state.version != appVersion) {
    Auth.version_mismatch()
    return false
  }

  const accessExpired = isAccessTokenExpired(state)
  const refreshExpired = isRefreshTokenExpired(state)
  if (!accessExpired) {
    return true
  }
  else if (!refreshExpired) {
    const refresh = refreshToken(state)
    const {response, result} = await window.IDA.api.refreshToken({refresh})
    const refreshed = result
    if (refreshed && refreshed.access) {
      const newTokenState = Auth.token_received(refreshed)
      return isAccessTokenExpired(newTokenState)
    }
    else {
      return false
    }
  }
  else {
    return false
  }
}

export function errors(state) {
   return  state.errors
}
