import { combineReducers } from 'redux'

import { configureStore } from 'redux-starter-kit'
import { persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from "redux-thunk";

import * as Config from 'config/module'
import * as Home from 'home/module'
import * as Warranty from 'warranties/module'
import * as Auth from 'auth/module'
import * as Route from 'main/utils/route/module'
import * as Async from 'main/utils/async/module'
import * as Snackbar from 'main/utils/snackbar/module'
import * as ContextPanel from 'main/utils/contextPanel/module'

import * as WarrantiesStepper from 'warranties/stepper/module'
import * as WarrantyList from 'warranties/WarrantyList/module'

import * as FormDealership from 'warranties/forms/dealership/module'
import * as FormConsumer from 'warranties/forms/consumer/module'
import * as FormModifications from 'warranties/forms/modifications/module'
import * as FormVehicle from 'warranties/forms/vehicle/module'
import * as FormWarranty from 'warranties/forms/warranty/module'
import * as FormSummary from 'warranties/forms/summary/module'
import * as FormSchema from 'warranties/schema/module'

import * as FormAccount from 'account/module'

import * as Orders from 'orders/module'
import * as OrderList from 'orders/OrderList/module'
import * as OrderDetail from 'orders/OrderDetail/module'
import * as OrderReceipt from 'orders/OrderReceipt/module'

import logger from 'redux-logger'

const formModules = [
  FormDealership,
  FormVehicle,
  FormConsumer,
  FormModifications,
  FormWarranty,
  FormSummary,
  WarrantiesStepper
]

const modules = [
  Config,
  Home,
  Auth,
  Route,
  Async,
  Snackbar,
  ContextPanel,
  Warranty,
  Orders,
  OrderList,
  OrderDetail,
  OrderReceipt,
  WarrantyList,
  FormSchema,
  FormAccount,
  ...formModules
]

const sliceToReducer = {}
modules.forEach((module) => {
  sliceToReducer[module.slice] = module.reducer
})

// Persist chosen modules into browser storage
const hotPersisted = {}
const persistedModules = []
persistedModules.forEach((module) => {
  const config = {key: module.slice, storage, debug: true}
  const reducer = module.reducer
  const persistedReducer = persistReducer(config, reducer)

  sliceToReducer[module.slice] = persistedReducer
  hotPersisted[module.slice] = {config, persistedReducer}
})

const middleware = [thunk]

if (process.env.NODE_ENV !== 'production') {
  middleware.push(logger)
}

const preloadedState = {}

const rootConfig = {
  key: 'root',
  storage,
  whitelist: [Auth.slice, Config.slice, ...(formModules.map((module) => (module.slice)))]
  //blacklist: modules.map((module) => (module.slice))
  //blacklist: persistedModules.map((module) => (module.slice))
}

const rootReducer = combineReducers(sliceToReducer)
const persistedRootReducer = persistReducer(rootConfig, rootReducer)


const store = configureStore({
  reducer: persistedRootReducer,
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState,
  enhancers: []
})

// hot loading support for persisted reducers
if (module.hot) {
  module.hot.accept('auth/module', () => {
    // This fetch the new state of the above reducers.
    const nextRootReducer = require('auth/module').default
    store.replaceReducer(
      persistReducer(hotPersisted[Auth.slice].config, hotPersisted[Auth.slice].reducer)
    )
  })
}

let persistor = persistStore(store)

export {store, persistor}
export default store
