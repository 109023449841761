import {createModule} from 'main/utils/reducers';
import React from 'react';
import ReactDOMServer from 'react-dom/server';

const markup = ReactDOMServer.renderToStaticMarkup

const module = createModule({
  slice: 'OrderReceipt',
  initialState: {
    order: {
      total: 0,
      warranties: []
    },
    context: {
      left: {
        title: markup(<p>Order<br/>Successful</p>),
        content: markup(
          <div>
            <p>Thank you for your order.</p>
            <p>You may view and download your order reciept.</p>
          </div>
        )
      },
      right: {
        title: markup('Your Warranty Documents Are Ready'),
        content: markup(
          <div>
            <p>Download your Warranty Documents by clicking the button under the PDF column.</p>
          </div>
        )
      },
      left_loading: {
        title: markup(<p>Order<br/>Processing</p>),
        content: markup(
          <div>
            <p>We are currently processing your order.</p>
          </div>
        )
      },
      right_loading: {},
      left_failed: {
        title: markup(<p>Order<br/>Unsuccessful</p>),
        content: markup(
          <div>
            <p>There was an issue with your order.</p>
          </div>
        )
      },
      right_failed: {}
    }
  },
  reducers: {
    set_order: (state, action) => {
      state.order = action.payload
    }
  }
})

export const { mapState, mapDispatch, slice, reducer, selector } = module

export default module.reducer
