import {createModule} from 'main/utils/reducers';
import React from 'react';
import ReactDOMServer from 'react-dom/server';

const markup = ReactDOMServer.renderToStaticMarkup

const module = createModule({
  slice: 'WarrantyList',
  initialState: {
    warranties: {
      results: [],
      count: 0,
      limit: 10,
      offset: 0,
      search: '',
      fromDate: null,
      toDate: null,
      created_before: null,
      created_after: null,
      ordering: null,
      exporting: null,
    },
    context: {
      left: {
        title: markup(<p>My<br/>Warranties</p>),
        content: markup(
          <div>
            <p>All your purchased warranties are listed to the right.</p>
            <p>You can check order details or download your warranty PDF.</p>
          </div>
        )
      },
      right: {
        title: markup('Downloading Your Warranty'),
        content: markup(
          <div>
            <p>A download link will appear next to your order when it is ready.</p>
          </div>
        )
      }
    }
  },
  reducers: {
    set_warranties(state, action) {
      const data = action.payload
      state.warranties.results = data.results
      state.warranties.count = data.count
    },
    set_page(state, action) {
      const {limit, offset} = {...action.payload}
      state.warranties.limit = limit
      state.warranties.offset = offset
    },
    set_search(state, action) {
      state.warranties.search = action.payload
    },
    set_from_date(state, action) {
      const value = action.payload
      state.warranties.fromDate = value
      state.warranties.created_after = value && value.startOf('day').toISOString(true)
    },
    set_to_date(state, action) {
      const value = action.payload
      state.warranties.toDate = value
      state.warranties.created_before = value && value.endOf('day').toISOString(true)
    },
    set_ordering(state, action) {
      const value = action.payload
      state.warranties.ordering = value
    },
    set_exporting(state, action) {
      state.warranties.exporting = action.payload
    },
    clear_search(state, action) {
      state.warranties = {
        ...state.warranties,
        offset: 0,
        search: '',
        fromDate: null,
        toDate: null,
        created_before: null,
        created_after: null,
        ordering: null
      }
    }
  }
})

export const { mapState, mapDispatch, slice, reducer, selector } = module

export default module.reducer
