import React from 'react'

import classNames from 'classnames'
import Grid from '@material-ui/core/Grid';

import TextField from 'assets/input/TextField'
import PassField from 'assets/input/PasswordField'
import EmailField from 'assets/input/EmailField'
import CheckboxField from 'assets/input/CheckboxField'
import RadioField from 'assets/input/RadioField'
import DateField from 'assets/input/DateField'
import SelectField from 'assets/input/SelectField'
import AutocompleteField from 'assets/input/AutocompleteField'

import style from './form.module.scss'

const cssClasses = {
  focused: style['focused'],
  disabled: style['disabled'],
  error: style['error'],
  required: style['required']
}

const types = {
  'text': TextField,
  'password': PassField,
  'email': EmailField,
  'checkbox': CheckboxField,
  'radio': RadioField,
  'date': DateField,
  'select': SelectField,
  'autocomplete': AutocompleteField
}

const FormInputComponent = ({
  xs,
  sm,
  md,
  lg,
  xl,
  type,
  ...props
}) => {
  const breakpoints = {xs, sm, md, lg, xl}
  const Component = types[type]

  return (
    <Grid item {...breakpoints}>
      <Component
        className={classNames(style['form-control'], style[type])}
        classes= {{...cssClasses}}
        {...props}
      />
    </Grid>
  )
}

export const FormText = (props) => (
  <FormInputComponent type='text' {...props}/>
)

export const FormEmail = (props) => (
  <FormInputComponent type='email' {...props}/>
)

export const FormPass = (props) => (
  <FormInputComponent type='pass' {...props}/>
)

export const FormCheckbox = (props) => (
  <FormInputComponent type='checkbox' {...props}/>
)

export const FormRadio = (props) => (
  <FormInputComponent type='radio' {...props}/>
)

export const FormDate = (props) => (
  <FormInputComponent type='date' {...props}/>
)

export const FormSelect = (props) => (
  <FormInputComponent type='select' {...props}/>
)

export const FormAutocomplete = (props) => (
  <FormInputComponent type='autocomplete' {...props}/>
)

export default FormInputComponent
