import React from 'react'
import logoLarge from 'static/logo_large.png';
import logoSmall from 'static/logo_small.png';
import styles from './logo.module.css'

const Logo = (props) => (
  <img className={styles.logo} src={logoLarge} />
)

export default Logo
