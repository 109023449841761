import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { mapState, mapDispatch } from './module';
import { mapState as authMapState, mapDispatch as authMapDispatch } from 'auth/module';

import * as token from 'auth/token'

class RouteComponent extends Component {

  state = {
    wasAuthenticated: token.wasAuthenticated(this.props),
    isAuthenticated: null,
    isMounted: false
  }

  componentDidMount = async () => {
    const isMounted = true
    this.setState({isMounted})

    const isAuthenticated = await token.isAuthenticated(this.props)
    if (this.state.isMounted) {
      this.setState({isAuthenticated})
    }
  }

  componentWillUnmount = () => {
    const isMounted = false
    this.setState({isMounted})
  }

  render = () => {
    const {component: Component, routes, path, exact, authRequired=false} = this.props
    const isLoggedIn = this.state.isAuthenticated
    const wasLoggedIn = this.state.wasAuthenticated

    return (
      <Route
        path={path}
        exact={exact}
        render={(props) => {
            if (authRequired && isLoggedIn) {
              return <Component {...props} routes={routes} />
            }
            else if (authRequired && isLoggedIn === false) {
              return <Redirect to={{pathname: '/login',}} />
            }
            else if (!authRequired) {
              return <Component {...props} routes={routes} />
            }
            return <div/>
          }
        }
      />
    )
  }
}

const RouteComponentConnected = connect(mapState, mapDispatch)(RouteComponent)
export default connect(authMapState, authMapDispatch)(RouteComponent)
