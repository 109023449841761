import React from 'react';
import { connect } from 'react-redux'
import * as module from './module';
import * as vehicleModule from 'warranties/forms/vehicle/module'
import * as configModule from 'config/module'
import store from 'main/store/store'
import { withRouter } from 'react-router-dom'

import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import FormGroup from 'assets/forms/FormGroup'
import FormPanel from 'assets/forms/FormPanel'

import { FormCaption} from 'assets/Text'

import style from '../forms.module.scss'
import {initializeForm} from 'warranties/forms/utilities'

import {form as formSpecs, schema} from './form'
import generateFormComponent from 'warranties/forms/FormComponent'


const FormComponent = generateFormComponent(formSpecs, schema)


class ModificationsFormComponent extends FormComponent {
  constructor(props) {
    super(props)
    initializeForm(this.props)
  }

  setToDefault = async (field, type) => {
    switch(type) {
      case 'select':
        this.props.set_select_value({field, value: module.formDefaults[field].value})
        await this.validateField(field)
        break;
      case 'checkbox':
        this.props.set_value({field, value: module.formDefaults[field].value})
        await this.validateField(field)
        break;
      default:
        break;
    }
  }

  resetTireAndWheelFields = () => {
    let field
    for (field of ['mod_tire_diameter', 'mod_tire_width', 'mod_wheel_diameter', 'mod_wheel_width']) {
      this.setToDefault(field, 'select')
    }

    for (field of ['mod_tire_performance', 'mod_wheel_performance']) {
      this.setToDefault(field, 'checkbox')
    }
  }

  componentDidUpdate = (prevProps) => {
    // lets reset some values to their defaults if lower was ever selected
    const wasLower = prevProps.values['mod_suspension_kits'].value === 'lower'
    const isLower = this.props.values['mod_suspension_kits'].value === 'lower'
    const wasNoMod = prevProps.values['mod_suspension_kits'].value === ''
    const isNoMod = this.props.values['mod_suspension_kits'].value === ''

    if ((wasLower || isLower) && (wasLower !== isLower) || (!wasNoMod && isNoMod)) {
      this.resetTireAndWheelFields()
    }
  }

  componentDidMount = () => {
    super.componentDidMount()

    const config = configModule.selector(store.getState())
    const vehicle_gvwr = vehicleModule.selector(store.getState()).entries['vehicle_gvwr']

    // Only class 2B+ vehicles should qualify here
    const qualifyingClasses = config.gvwrs.reduce((accumulator, gvwr) => {
      if ( [16, 17, 4].includes(gvwr.id) ) {
        accumulator.push(gvwr.name)
      }
      return accumulator
    }, [])

    const qualifiesBigTire = vehicle_gvwr && qualifyingClasses.includes(vehicle_gvwr)

    this.setState({qualifiesBigTire})

    if (!qualifiesBigTire && this.props.values['mod_tire_diameter'].value === '38') {
      this.setToDefault('mod_tire_diameter', 'select')
    }
  }

  render = () => {
    const SelectField = this.SelectField
    const CheckboxField = this.CheckboxField

    return (
      <Grid container spacing={16} className={style['form']}>
        <Grid item xs={12}>
          <h6>Select Vehicle Modifications</h6>
        </Grid>
        <FormPanel noBg title="Suspension" xs={12} lg={6}>
          <FormGroup xs={12}>
            <SelectField
              xs={12}
              name='mod_suspension_kits'
            >
              <MenuItem value=''>No Modification</MenuItem>
              <MenuItem value='lower'>Lowering Kit</MenuItem>
              <MenuItem value='lift1'>1.0'' - 3.5'' Lift</MenuItem>
              <MenuItem value='lift2'>4.0'' - 6'' Lift</MenuItem>
              <MenuItem value='lift3'>7.0'' Lift</MenuItem>
              <MenuItem value='lift4'>8.0'' Max</MenuItem>
            </SelectField>
          </FormGroup>
        </FormPanel>
        <FormPanel noBg title="Engine" xs={12} lg={6}>
          <FormGroup xs={12}>
            <SelectField
              xs={12}
              name='mod_engine_tune'
            >
              <MenuItem value=''>No Modification</MenuItem>
              <MenuItem value='odometer'>Odometer / Speedometer Only</MenuItem>
              <MenuItem value='tune'>Engine Tune</MenuItem>
              <MenuItem value='turbo'>Turbocharger</MenuItem>
              <MenuItem value='super'>Supercharger</MenuItem>
            </SelectField>
          </FormGroup>
          <Grid item xs={12}>
            <FormCaption>
              Must be C.A.R.B. approved in required states
            </FormCaption>
          </Grid>
        </FormPanel>
        <FormPanel noBg title="Tire" xs={12} lg={6}>
          {this.props.values['mod_suspension_kits'].value === 'lower' &&
            <Grid container spacing={16}>
              <CheckboxField xs={6} name='mod_tire_performance'>Performance Tires</CheckboxField>
            </Grid>
          ||
            <Grid container spacing={16}>
              <SelectField
                xs={12} lg={6}
                name='mod_tire_diameter'
              >
                <MenuItem value=''>No Modification</MenuItem>
                <MenuItem value='29'>29''-32''</MenuItem>
                <MenuItem value='33'>33''</MenuItem>
                <MenuItem value='35'>35''</MenuItem>
                <MenuItem value='37'>37''</MenuItem>
                { this.state.qualifiesBigTire &&
                  <MenuItem value='38'>38''</MenuItem>
                }
              </SelectField>
              <SelectField
                xs={12} lg={6}
                name='mod_tire_width'
              >
                <MenuItem value=''>No Modification</MenuItem>
                <MenuItem value='10.5'>10.5''</MenuItem>
                <MenuItem value='11.5'>11.5''</MenuItem>
                <MenuItem value='12.5'>12.5''</MenuItem>
                <MenuItem value='13.5'>13.5''</MenuItem>
                <MenuItem value='14.5'>14.5''</MenuItem>
              </SelectField>
            </Grid>
          }
        </FormPanel>
        <FormPanel noBg title="Exhaust" xs={12} lg={6}>
          <Grid container spacing={0}>
            <SelectField
              xs={12}
              name='mod_exhaust_upgrades'
            >
              <MenuItem value=''>No Modification</MenuItem>
              <MenuItem value='cat'>Cat-Back</MenuItem>
            </SelectField>
            <Grid item xs={12}>
              <FormCaption>
                Must be C.A.R.B. approved in required states
              </FormCaption>
            </Grid>

          </Grid>
        </FormPanel>
        <FormPanel noBg title="Wheel" xs={12} lg={6}>
          {this.props.values['mod_suspension_kits'].value === 'lower' &&
            <Grid container spacing={16}>
              <CheckboxField xs={6} name='mod_wheel_performance'>Performance Wheels</CheckboxField>
            </Grid>
          ||
            <Grid container spacing={16}>
              <SelectField
                xs={12} lg={6}
                name='mod_wheel_diameter'
              >
                <MenuItem value=''>No Modification</MenuItem>
                <MenuItem value='17'>17''</MenuItem>
                <MenuItem value='18'>18''</MenuItem>
                <MenuItem value='20'>20''</MenuItem>
                <MenuItem value='22'>22''</MenuItem>
                <MenuItem value='24'>24''</MenuItem>
              </SelectField>
              <SelectField
                xs={12} lg={6}
                name='mod_wheel_width'
              >
                <MenuItem value=''>No Modification</MenuItem>
                <MenuItem value='8'>8.0'' - 9.5''</MenuItem>
                <MenuItem value='10'>10.0'' - 11.0''</MenuItem>
                <MenuItem value='12'>12''</MenuItem>
                <MenuItem value='14'>14''</MenuItem>
              </SelectField>
            </Grid>
          }
        </FormPanel>
        <FormPanel noBg title="Accessories" xs={12} lg={6}>
          <FormGroup type='checkbox' xs={12} spacing={0}>
            <CheckboxField xs={6} name='mod_accessories_front_bumper'>Front Bumper</CheckboxField>
            <CheckboxField xs={6} name='mod_accessories_bed'>Bed Accessories</CheckboxField>
            <CheckboxField xs={6} name='mod_accessories_rear_bumper'>Rear Bumper</CheckboxField>
            <CheckboxField xs={6} name='mod_accessories_interior'>Interior Upgrades</CheckboxField>
            <CheckboxField xs={6} name='mod_accessories_tire_carrier'>Tire Carrier</CheckboxField>
            <CheckboxField xs={6} name='mod_accessories_roof'>Roof Accessories</CheckboxField>
            <CheckboxField xs={6} name='mod_accessories_winch'>Winch</CheckboxField>
            <CheckboxField xs={6} name='mod_accessories_lighting'>Lighting</CheckboxField>
            <CheckboxField xs={6} name='mod_accessories_side_steps'>Side Steps</CheckboxField>
            <CheckboxField xs={6} name='mod_accessories_exhaust_tips'>Exhaust Tips</CheckboxField>
          </FormGroup>
        </FormPanel>
      </Grid>
    )
  }
}

const form = connect(module.mapState, module.mapDispatch)(withRouter(ModificationsFormComponent))
export {module, form}
export default form
