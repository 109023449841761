import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Redirect, Switch, NavLink, withRouter } from 'react-router-dom'
import Route from 'main/utils/route/Route'

import { mapState, mapDispatch } from './module';
import store from 'main/store/store'
import {mapDispatch as contextPanelMapDispatch} from 'main/utils/contextPanel/module'
import {mapDispatch as asyncMapDispatch} from 'main/utils/async/module'
import * as snackbarModule from 'main/utils/snackbar/module'

import classNames from 'classnames'
import style from '../orders.module.scss'

import Grid from '@material-ui/core/Grid';
import {Table, Row, Header, Cell} from 'assets/tables/Table'
import Panel from 'assets/panels/Panel';

import Button from '@material-ui/core/Button';
import FormButton from 'assets/forms/FormButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import moment from 'moment'

import {Caption, Subtitle, Subtitle2} from 'assets/Text'

const contextPanelDispatch = contextPanelMapDispatch(store.dispatch)
const Async = asyncMapDispatch(store.dispatch)
const snackbarDispatch = snackbarModule.mapDispatch(store.dispatch)


function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

class OrderReceiptComponent extends Component {

  componentDidMount = async () => {
    const orderId = this.props.match.params['id']
    const request = await window.IDA.api.orders.receipt(orderId)
    this.props.set_order(request.result)

    switch(this.props.order.status) {
      case 'failed':
        contextPanelDispatch.set_left_panel(this.props.context.left_failed)
        contextPanelDispatch.set_right_panel(this.props.context.right_failed)
        break;
      case 'paid':
        contextPanelDispatch.set_left_panel(this.props.context.left)
        contextPanelDispatch.set_right_panel(this.props.context.right)
        break;
      default:
        contextPanelDispatch.set_left_panel(this.props.context.left_loading)
        contextPanelDispatch.set_right_panel(this.props.context.right_loading)
        break;
    }


    if (!this.props.order.processed && this.props.order.status !== 'failed') {
      const socket = await window.IDA.api.orders.ws_get(orderId)

      socket.onmessage = (e) => {
          var data = JSON.parse(e.data);
          this.props.set_order(data.order);

          if (this.props.order.processed){
            Async.end_request()
            socket.close()
            snackbarDispatch.OPEN({message: 'Order Processed', variant: 'success'})
            contextPanelDispatch.set_left_panel(this.props.context.left)
            contextPanelDispatch.set_right_panel(this.props.context.right)
          }
          else if (this.props.order.status === 'failed') {
            Async.end_request()
            socket.close()
            snackbarDispatch.OPEN({message: 'Order Failed', variant: 'error'})
            contextPanelDispatch.set_left_panel(this.props.context.left_failed)
            contextPanelDispatch.set_right_panel(this.props.context.right_failed)
          }
      };

      socket.onclose = (e) => {

      };

      socket.onopen = async (e) => {
        const tryLimit = 10;
        const wait = 5000;
        let tries = 0;

        Async.begin_request()
        while(!this.props.order.processed && this.props.order.status !== 'failed' && tries < tryLimit) {
          socket.send(JSON.stringify({
              'message': 'get_order'
          }));
          tries = tries + 1;
          await sleep(wait);
        }

        if (!this.props.order.processed && this.props.order.status !== 'failed' && tries == tryLimit) {
          Async.end_request()
          snackbarDispatch.OPEN({message: 'There was an issue processing your order.', variant: 'error'})
        }
        socket.close()
      }
    }

  }

  retryPayment = async (e) => {
    const {result, response} = await window.IDA.api.orders.retry_payment({id: this.props.order.id})
    switch (response.status) {
      case 200:
        if (result.status === 'unpaid') {
          window.location = `/orders/${this.props.order.id}/`
        }
        break;
      default:
        break;
    }
  }

  render() {
    const order = this.props.order
    const transaction = order.squaretransaction && order.squaretransaction[0]

    const headers = [
      {
        name: 'Warranty #',
        get: (row) => {
          return `${row.warranty_number}`
        },
        grid: {xs: 6, md: 2}
      },
      {
        name: 'VIN',
        get: (row) => {
          return row.vehicle.vin
        },
        grid: {xs: 12, md: 3}
      },
      {
        name: 'Vehicle',
        get: (row) => {
          return <div>{row.vehicle.year} {row.vehicle.make} {row.vehicle.model} {row.vehicle.trim}</div>
        },
        grid: {xs: 12, md: 4}
      },
      {
        name: 'Amount',
        get: (row) => {
          return row.price
        },
        grid: {xs: 12, md: 2}
      },
      {
        name: 'PDF',
        get: (row) => {
          const href = row.document && row.document.file
          return (
            <FormButton
             type='button'
             //variant="contained"
             color="secondary"
             disabled={!href}
             href={href}
             target='_blank'
             //onClick={this.handleOrder}
           >
             <CloudDownloadIcon/>
           </FormButton>
         )
        },
        grid: {xs: 12, md: 1}
      },
    ]

    let message = `
      Thank you for your order.  Your payment is currently processing.
    `
    let submessage = `
      Your warranty documents will be sent to your e-mail and specified parties .
      You may also view your documents in “My Warranties” once processed.
    `

    let charged = 'Currently Processing...'

    if (transaction && order.status != 'pending') {
      if (!transaction.error) {
        message = 'Payment Successful.  Thank you for your order.  You may download your warranty documents below.'
        submessage = `
          Your warranty documents will be sent to your e-mail and specified parties.
          You may also view your documents in “My Warranties”.
        `
        charged = `${transaction.card_brand} ...${transaction.card_last_4}`
      }
      else {
        message = 'Payment failed.  There was an issue processing your payment.'
        const error_messages = []

        for (const error of transaction.error_message.errors) {
          switch ( error.category ) {
            case 'PAYMENT_METHOD_ERROR':
              error_messages.push(error.detail)
              break
            default:
              error_messages.push(`
                We could not process your card due to a technical issue.
                Your card was not charged.
                Please contact support.
              `)
          }
        }

        error_messages.push(
          <Button onClick={this.retryPayment} color='secondary'>Retry Payment</Button>
        )

        submessage = error_messages.map((message, index) => (
          <div key={index}>{message}</div>
        ))

        charged = `Not Charged`
      }
    }

    let completedDate = new Date(order.completed)
    completedDate = completedDate.toLocaleDateString()

    return (
      <Grid container spacing={16} alignItems='center' justify='center'>
        <Grid item xs={12}>
          <h5>Order #{this.props.order.client_order_number}</h5>
        </Grid>
        <Grid item xs={10}>
          <Caption>
            {message}
          </Caption>
        </Grid>
        <Grid item xs={10}>
          <Caption>
            {submessage}
          </Caption>
        </Grid>
        <Grid item xs={12}>
          <Panel>
            <Grid container spacing={16}>
              <Grid item md={2} className={style['warranty-column-1']}>
                <Caption>Order #</Caption>
                <Caption>Date</Caption>
                <Caption>Amount</Caption>
                <Caption>Charged To</Caption>
              </Grid>
              <Grid item md={4} className={style['warranty-column-2']}>
                <Caption>{order.client_order_number}</Caption>
                <Caption>{order.completed ? completedDate : 'Not Yet Processed'}</Caption>
                <Caption>${Number.parseFloat(order.total).toFixed(2)}</Caption>
                <Caption>{charged}</Caption>
              </Grid>
              <Grid item xs={12}>
                <Table data={{results: this.props.order.warranties}} headers={headers} simple noBorder/>
              </Grid>
            </Grid>
          </Panel>
        </Grid>

      </Grid>
    )
  }
}

export default connect(mapState, mapDispatch)(withRouter(OrderReceiptComponent))
