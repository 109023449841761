import React from 'react'

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import {Subtitle, FormCaption as Caption} from 'assets/Text'

import style from './form.module.scss'

const types = {
  'subtitle': Subtitle,
  'caption': Caption
}

const FormTextComponent = ({
  xs,
  sm,
  md,
  lg,
  xl,
  type,
  ...props
}) => {
  const breakpoints = {xs, sm, md, lg, xl}
  const Component = types[type]

  return (
    <Grid item {...breakpoints}>
      <Component className={style['form-control']} {...props}/>
    </Grid>
  )
}

export const FormSubtitle = (props) => (
  <FormTextComponent type='subtitle' {...props}/>
)

export const FormCaption = (props) => (
  <FormTextComponent type='caption' {...props}/>
)
