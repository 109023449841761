import React from 'react';
import ReactDOMServer from 'react-dom/server';
import {createModule} from 'main/utils/reducers';
import commonReducers from '../commonReducers'

import {form, schema, defaults as formDefaults} from './form'


const markup = ReactDOMServer.renderToStaticMarkup

const module = createModule({
  slice: 'FormConsumer',
  initialState: {
    name: 'Consumer',
    nextText: 'Proceed to Step 4: Modifications',
    active: false,
    completed: false,
    error: false,
    disabled: true,
    locked: false,
    skip: false,
    message: '',
    optional: false,
    entries: {},
    valueDefaults: formDefaults,
    values: formDefaults,
    context: {
      left: {
        title: markup(<p>Step 3: <br/>Consumer</p>),
        content: markup(
          <div>
            <p>Enter the Consumer information for whom the vehicle was sold to.</p>
            <p>The Consumer’s information will be attached to your warranty.</p>
          </div>
        )
      },
      right: {
        title: markup('No Consumer?'),
        content: markup(
  <p>Go back to Step 2 and indicate the vehicle was not sold.</p>
        )
      }
    }
  },
  reducers: {
    ...commonReducers(schema),
  },
  extraReducers: {
    'FormVehicle/set_value': (state, action) => {
      if (action.payload.field == 'vehicle_sold') {
        state.skip = !action.payload.value
        state.disabled = !action.payload.value
      }
    }
  }
})

export const { mapState, mapDispatch, slice, reducer, selector } = module
export {form, schema, formDefaults}

export default module.reducer
