import {createModule} from 'main/utils/reducers';

const parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(window.atob(base64));
}


const module = createModule({
  slice: 'Auth',
  setAuthState: true,
  setAsyncState: true,
  initialState: {
    access: undefined,
    refresh: undefined,
    errors: [],
    messages: [],
    user: {
      company: '',
      account: '',
      street: '',
      city: '',
      state: '',
      zip: '',
      email: ''
    },
    'version': process.env.REACT_APP_VERSION,
    'versionMismatch': false
  },
  reducers: {
    version_mismatch(state, action) {
      state.versionMismatch = true
    },
    clear_version_mismatch(state, action) {
      state.versionMismatch = false
    },
    login_success(state, action) {
      const access = action.payload.access
      const refresh = action.payload.refresh
      state.access = {
        token: access,
        ...parseJwt(access)
      }

      state.refresh = {
        token: refresh,
        ...parseJwt(refresh)
      }
      return state
    },
    token_received(state, action) {
      const access = action.payload.access
      state.access = {
        token: access,
        ...parseJwt(access)
      }
      return state
    },
    login_failure(state, action) {
      state.access = undefined
      state.refresh = undefined
      state.errors = [action.payload.detail]
      return state
    },
    logout(state, action) {
      state.access = undefined
      state.refresh = undefined
      state.errors = []
      return state
    },
    set_user(state, action) {
      const {client} = action.payload
      state.user = {
        company: client.name,
        account: client.account,
        street: client.location.street,
        city: client.location.city,
        state: client.location.state,
        zip: client.location.zip,
        email: action.payload.username,
        alternate_emails: client.alternate_emails,
        phone: client.phone
      }
      return state
    },
    set_messages(state, action) {
      state.messages = action.payload
    },
    clear_messages(state, action) {
      state.messages = []
    }
  }
});

export const { mapState, mapDispatch, slice, reducer, selector } = module

export default module.reducer
