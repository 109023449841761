import React, { Component } from 'react';
import { connect } from 'react-redux'
import store from 'main/store/store'

import {mapDispatch as contextPanelMapDispatch} from 'main/utils/contextPanel/module'

import { mapState, mapDispatch } from './module';
import style from '../warranties.module.scss'

import Grid from '@material-ui/core/Grid';

import {Table, Row, Header, Cell} from 'assets/tables/Table'

import FormButton from 'assets/forms/FormButton';
import {FatNavButton, NavButton} from 'assets/Button'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import {Caption, Subtitle2} from 'assets/Text'

import moment from 'moment'
import _ from 'lodash'

const contextPanelDispatch = contextPanelMapDispatch(store.dispatch)

class WarrantyListComponent extends Component {
  componentDidMount = async () => {
    contextPanelDispatch.set_left_panel(this.props.context.left)
    contextPanelDispatch.set_left_header('Warranties')
    contextPanelDispatch.set_right_panel(this.props.context.right)
    const request = await window.IDA.api.warranties.list({offset: 0, limit: this.props.warranties.limit})
    this.props.set_warranties(request.result)
  }
  getParams = () => {
    const {offset, limit, search, ordering, created_before, created_after} = {...this.props.warranties}
    return {offset, limit, search, ordering, created_before, created_after}
  }

  onPaginate = async ({offset, limit}) => {
    const params = {...this.getParams(), offset, limit}
    const request = await window.IDA.api.warranties.list(params)
    this.props.set_warranties(request.result)
    this.props.set_page({offset, limit})
  }

  onSearchDebounced = _.debounce(async (value) => {

    const params = {...this.getParams(), offset: 0, search: value}

    const request = await window.IDA.api.warranties.list(params)
    this.props.set_page({offset: 0, limit: params.limit})
    this.props.set_warranties(request.result)

  }, 500)

  onSearch = (e) => {
    const value = e.target.value
    this.props.set_search(value)
    this.onSearchDebounced(value)
  }

  onFromDate = async (moment) => {
    this.props.set_from_date(moment)
    const params = {...this.getParams(), created_after: moment && moment.startOf('day').toISOString(true)}
    const request = await window.IDA.api.warranties.list(params)
    this.props.set_warranties(request.result)
  }

  onToDate = async (moment) => {
    this.props.set_to_date(moment)
    const params = {...this.getParams(), created_before: moment && moment.endOf('day').toISOString(true)}
    const request = await window.IDA.api.warranties.list(params)
    this.props.set_warranties(request.result)
  }

  onOrder = async (newOrderingString) => {
    const params = {...this.getParams(), ordering: newOrderingString}
    const request = await window.IDA.api.warranties.list(params)
    this.props.set_warranties(request.result)
    this.props.set_ordering(newOrderingString)
  }

  onClear = async () => {
    this.props.clear_search()
    const request = await window.IDA.api.warranties.list({offset: 0, limit: this.props.warranties.limit})
    this.props.set_warranties(request.result)
  }

  onExport = async () => {
    this.props.set_exporting(true)
    const params = this.getParams()
    const request = await window.IDA.api.warranties.report(params)
    const blob = await request.response.blob()
    const objectURL = URL.createObjectURL(blob);

    var a = document.createElement("a");
    a.style = "display: none";
    document.body.appendChild(a);
    a.href = objectURL
    a.download = 'report.csv'
    a.click()
    URL.revokeObjectURL(objectURL)
    this.props.set_exporting(false)
  }


  render() {
    const headers = [
      {
        name: 'Date',
        get: (row) => {
          const created = moment(new Date(row.created))
          return created.format('L');
        },
        orderByField: 'created',
        grid: {xs: 6, md: 2}
      },
      {
        name: 'Warranty #',
        get: (row) => {
          return `${row.warranty_number}`
        },
        grid: {xs: 6, md: 2}
      },
      {
        name: 'Vehicle',
        get: (row) => {
          return <div>{row.vehicle.vin}<br/>{row.vehicle.year} {row.vehicle.make} {row.vehicle.model} {row.vehicle.trim}</div>
        },
        grid: {xs: 12, md: 4}
      },
      {
        name: 'Dealer',
        get: (row) => {
          return row.dealer.name
        },
        orderByField: 'dealer__name',
        grid: {xs: 12, md: 3}
      },
      {
        name: 'PDF',
        get: (row) => {
          const href = row.document && row.document.file
          return (
            <FormButton
             type='button'
             color="secondary"
             disabled={!href}
             href={href}
             target='_blank'
             //onClick={this.handleOrder}
           >
             <CloudDownloadIcon/>
           </FormButton>
         )
        },
        grid: {xs: 12, md: 1}
      },
    ]

    return (
      <Grid container direction="row" justify="center" spacing={16}>
        <Grid item xs={12}>
          <h5>My Warranties</h5>
        </Grid>
        <Grid item xs={12}>
          <Table
            headers={headers}
            data={this.props.warranties}
            onSearch={this.onSearch}
            onFromDate={this.onFromDate}
            onToDate={this.onToDate}
            onPaginate={this.onPaginate}
            onOrder={this.onOrder}
            onClear={this.onClear}
            onExport={this.onExport}
          />
        </Grid>
      </Grid>
    )
  }
}

export default connect(mapState, mapDispatch)(WarrantyListComponent)
