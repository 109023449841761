import React from 'react'
import Radio from '@material-ui/core/Radio';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import {FormCaption} from 'assets/Text'

import style from './input.module.scss'

const RadioComponent = ({
  value,
  ...props
}) => {
  const options = {
    'control': <Radio className={style['radio']} value={value} {...props} />,
    'label': <FormCaption>{props.children}</FormCaption>
  }
  return (
    <FormControlLabel classes={{root: props.rootclassname, label: props.labelclassname}} {...options} />
  )
}


export default RadioComponent
