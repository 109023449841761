import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import style from './faq.module.scss'
// Control Context Panel
import store from 'main/store/store'
import {mapDispatch as contextPanelMapDispatch} from 'main/utils/contextPanel/module'

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

const contextPanelDispatch = contextPanelMapDispatch(store.dispatch)
const markup = ReactDOMServer.renderToStaticMarkup


class Faq extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: []
    }
    contextPanelDispatch.set_left_header('')
    contextPanelDispatch.set_left_panel({
      title: markup(<p>FAQ</p>),
      content:  markup(
<p>Commonly asked questions and answers for our clients.
</p>
      ),
    })

    contextPanelDispatch.set_right_panel({
      title: markup('Question not listed?'),
      content: markup(
<p>
Contact us now:<br/>
support@innovativedealeralliance.com<br/>
844.IDA.0568</p>
      )
    })
  }


  componentDidMount = async () => {
    const {response, result} = await window.IDA.api.faq()
    this.setState({
      data: result
    })
  }

  render () {
    return (
      <Grid container direction="row" justify="center" spacing={8}>
        <Grid item xs={12}>
          <h5>Frequently Asked Questions</h5>
          <br/>
        </Grid>

          {this.state.data.map((item, index) => (
            <Grid item xs={12} key={index}>
              <ExpansionPanel className={style['panel']}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={style['question']}>{index + 1}. {item.question}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography className={style['answer']}>
                    {item.answer}
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>
        ))}
      </Grid>
    )
  }
}

export default Faq;
