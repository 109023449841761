
export default [
  {value: 'AL', label: 'AL', alt: 'Alabama'},
  {value: 'AK', label: 'AK', alt: 'Alaska'},
  {value: 'AZ', label: 'AZ', alt: 'Arizona'},
  {value: 'AR', label: 'AR', alt: 'Arkansas'},
  {value: 'CA', label: 'CA', alt: 'California'},
  {value: 'CO', label: 'CO', alt: 'Colorado'},
  {value: 'CT', label: 'CT', alt: 'Connecticut'},
  {value: 'DE', label: 'DE', alt: 'Delaware'},
  {value: 'DC', label: 'DC', alt: 'District Of Columbia'},
  {value: 'FL', label: 'FL', alt: 'Florida'},
  {value: 'GA', label: 'GA', alt: 'Georgia'},
  {value: 'HI', label: 'HI', alt: 'Hawaii'},
  {value: 'ID', label: 'ID', alt: 'Idaho'},
  {value: 'IL', label: 'IL', alt: 'Illinois'},
  {value: 'IN', label: 'IN', alt: 'Indiana'},
  {value: 'IA', label: 'IA', alt: 'Iowa'},
  {value: 'KS', label: 'KS', alt: 'Kansas'},
  {value: 'KY', label: 'KY', alt: 'Kentucky'},
  {value: 'LA', label: 'LA', alt: 'Louisiana'},
  {value: 'ME', label: 'ME', alt: 'Maine'},
  {value: 'MD', label: 'MD', alt: 'Maryland'},
  {value: 'MA', label: 'MA', alt: 'Massachusetts'},
  {value: 'MI', label: 'MI', alt: 'Michigan'},
  {value: 'MN', label: 'MN', alt: 'Minnesota'},
  {value: 'MS', label: 'MS', alt: 'Mississippi'},
  {value: 'MO', label: 'MO', alt: 'Missouri'},
  {value: 'MT', label: 'MT', alt: 'Montana'},
  {value: 'NE', label: 'NE', alt: 'Nebraska'},
  {value: 'NV', label: 'NV', alt: 'Nevada'},
  {value: 'NH', label: 'NH', alt: 'New Hampshire'},
  {value: 'NJ', label: 'NJ', alt: 'New Jersey'},
  {value: 'NM', label: 'NM', alt: 'New Mexico'},
  {value: 'NY', label: 'NY', alt: 'New York'},
  {value: 'NC', label: 'NC', alt: 'North Carolina'},
  {value: 'ND', label: 'ND', alt: 'North Dakota'},
  {value: 'OH', label: 'OH', alt: 'Ohio'},
  {value: 'OK', label: 'OK', alt: 'Oklahoma'},
  {value: 'OR', label: 'OR', alt: 'Oregon'},
  {value: 'PA', label: 'PA', alt: 'Pennsylvania'},
  {value: 'RI', label: 'RI', alt: 'Rhode Island'},
  {value: 'SC', label: 'SC', alt: 'South Carolina'},
  {value: 'SD', label: 'SD', alt: 'South Dakota'},
  {value: 'TN', label: 'TN', alt: 'Tennessee'},
  {value: 'TX', label: 'TX', alt: 'Texas'},
  {value: 'UT', label: 'UT', alt: 'Utah'},
  {value: 'VT', label: 'VT', alt: 'Vermont'},
  {value: 'VA', label: 'VA', alt: 'Virginia'},
  {value: 'WA', label: 'WA', alt: 'Washington'},
  {value: 'WV', label: 'WV', alt: 'West Virginia'},
  {value: 'WI', label: 'WI', alt: 'Wisconsin'},
  {value: 'WY', label: 'WY', alt: 'Wyoming'},
];
