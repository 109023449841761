import Joi from 'joi-browser';
import {processForm} from 'warranties/forms/formUtilities'

const form = {
  email_send_to_consumer: {
    label: 'Email Consumer',
    joi: Joi.boolean(),
  },
  email_send_to_dealer: {
    label: 'Email Consumer',
    joi: Joi.boolean(),
  },
  email_additional_emails: {
    label: 'Additional E-Mails',
    joi: Joi.array().items(Joi.string().email({ minDomainAtoms: 2 }).allow('')),
    default: [],
    message: 'Optional'
  }
}

// Create Joi validation schema and form defaults for redux
const {schema, defaults} = processForm(form)

export {form, schema, defaults}
