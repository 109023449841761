import Joi from 'joi-browser';
import {processForm} from 'warranties/forms/formUtilities'
import states from 'assets/utility/states'

const form = {
  account_name: {
    label: 'Company Name',
    joi: Joi.string().min(3).max(30).required().error(
      (errors) => {
        return {
          message: "Company Name Required."
        }
      }
    ),
  },
  account_street: {
    label: 'Street Address',
    joi: Joi.string().min(3).max(60).required().error(
      (errors) => {
        return {
          message: "Street Address Required."
        }
      }
    ),
  },
  account_city: {
    label: 'City',
    joi: Joi.string().min(3).max(30).required().error(
      (errors) => {
        return {
          message: "US City Required."
        }
      }
    ),
  },
  account_state: {
    label: 'State',
    joi: Joi.string().alphanum().min(2).max(2).required().error(
      (errors) => {
        return {
          message: "US State Required."
        }
      }
    ).tags('auto'),
    suggestions: states
  },
  account_zip: {
    label: 'Zip',
    joi: Joi.string().regex(/^[0-9]{5}(?:-[0-9]{4})?$/).required().error(
      (errors) => {
        return {
          message: "US Zip Code Required."
        }
      }
    ),
  },
  account_email: {
    label: 'Additional E-Mails',
    joi: Joi.array().items(Joi.string().email({ minDomainAtoms: 2 }).allow('')),
    message: 'Optional'
  },
  account_phone: {
    label: 'Phone',
    joi: Joi.string().regex(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/).required().error(
      (errors) => {
        return {
          message: "Valid Phone Required."
        }
      }
    ),
  }
}

// Create Joi validation schema and form defaults for redux
const {schema, defaults} = processForm(form)

export {form, schema, defaults}
