import Joi from 'joi-browser';

const getDefaultByJoi = (joi) => {
  const type = joi.describe().type

  switch(type) {
    case 'boolean':
      return false
      break;
    default:
      return '';
      break;
  }
}

const processForm = (form) => {
  // Create our validation schema and apply labels to validators
  const validationKeys = {}
  for (const [key, validation] of Object.entries(form)) {
    // Set our keys for VALIDATE_FORM schema
    validationKeys[key] = validation.joi.label(validation.label)

    // Set our key label for VALIDATE_FIELD
    form[key].joi = validation.joi.label(validation.label)
  }
  const schema = Joi.object().keys(validationKeys);

  // Initialize default values for our state
  const defaults = {}
  for (const [key, entry] of Object.entries(form)) {
    defaults[key] = {
      value: entry.hasOwnProperty('default') ? entry.default : getDefaultByJoi(entry.joi),
      message: entry.message || '',
      error: false,
    }
  }

  return {schema, defaults}
}

export {processForm}
