
// Control Context Panel
import store from 'main/store/store'
import {mapDispatch as contextPanelMapDispatch} from 'main/utils/contextPanel/module'
import {mapDispatch as stepperMapDispatch} from 'warranties/stepper/module'

const contextPanelDispatch = contextPanelMapDispatch(store.dispatch)

const setContext = (context) => {
  contextPanelDispatch.set_left_panel(context.left)
  contextPanelDispatch.set_right_panel(context.right)
}

const initializeForm = (state) => {
  setContext(state.context)
}


export {initializeForm}
