import React from 'react';
import ReactDOMServer from 'react-dom/server';
import {createModule} from 'main/utils/reducers';
import commonReducers from '../commonReducers'

import {form, schema, defaults as formDefaults} from './form'


const markup = ReactDOMServer.renderToStaticMarkup

const module = createModule({
  slice: 'FormDealership',
  initialState: {
    name: 'Dealership',
    nextText: 'Proceed to Step 2: Vehicle Information',
    active: false,
    completed: false,
    error: false,
    disabled: true,
    locked: false,
    skip: false,
    message: '',
    optional: false,
    entries: {},
    valueDefaults: formDefaults,
    values: formDefaults,
    context: {
      left: {
        title: markup(<p>Step 1: <br/>Dealership</p>),
        content: markup(
          <div>
            <p>Enter your Dealer name and address information.</p>
            <p>Your chosen dealership will be connected to your warranty, and listed on the warranty document.</p>
          </div>
        )
      },
      right: {
        title: markup('Your Dealerships Are Saved'),
        content: markup(
  <p>Begin typing the name of a previously entered dealership to automatically select it.</p>
        )
      }
    }
  },
  reducers: {
    ...commonReducers(schema),
  },
  extraReducers: {

  }
})


export const { mapState, mapDispatch, slice, reducer, selector } = module
export {form, schema, formDefaults}
export default module.reducer
