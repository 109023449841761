import React, {Component} from 'react';
import { connect } from 'react-redux'
import { mapState, mapDispatch } from './module';
import * as schemaModule from 'warranties/schema/module'
import store from 'main/store/store'

import {mapDispatch as contextPanelMapDispatch} from 'main/utils/contextPanel/module'

import Async from 'main/utils/async/Async'

import { withTheme } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepButton from '@material-ui/core/StepButton';
import Hidden from '@material-ui/core/Hidden';
import ConfirmDialog from 'assets/dialogs/Confirm'

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { Switch, withRouter } from 'react-router-dom'
import Route from 'main/utils/route/Route'

import {FormSubtitle} from 'assets/forms/FormText'
import {FormStepperNext} from 'assets/forms/FormButton'

import * as snackbarModule from 'main/utils/snackbar/module'

import style from './stepper.module.scss'
import classNames from 'classnames'

const contextPanelDispatch = contextPanelMapDispatch(store.dispatch)
const snackbarDispatch = snackbarModule.mapDispatch(store.dispatch)

class WarrantyStepper extends Component {

  componentDidMount = () => {
    this.validatePath()
    const header = !!this.props.editDraft.id ? 'Edit Warranty' : 'Create New Warranty'
    contextPanelDispatch.set_left_header(header)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname){
      this.validatePath()
    }
  }

  validatePath = () => {
    /*
      Ensures Stepper and its forms are in sync when navigating
        its urls.
    */
    let step = this.formPaths.indexOf(this.props.location.pathname)
    const currentState = store.getState()
    let lastCompletedStep = null;

    // Compute last completed step
    this.props.routes.forEach(({module}, index) => {
      const state = module.selector(currentState)
      if (state.completed) {
        if (lastCompletedStep === null) {
          lastCompletedStep = index
        }
        else if (index < lastCompletedStep) {
          lastCompletedStep = index
        }
      }
    })

    // Target step should be the following step after the last completed step
    //  If that step is not the requested step, reassign and correct url
    if (lastCompletedStep && lastCompletedStep < step - 1) {
      step = lastCompletedStep + 1
      this.props.history.push(this.props.routes[step].path)
    }
    //  If no completed steps, go back to step 1
    else if (lastCompletedStep === null) {
      step = 0
      this.props.history.push(this.props.routes[step].path)
    }
    //  Ensure active step states are correct
    this.props.routes.forEach(({module}, index) => {
      const actions = module.mapDispatch(store.dispatch)
      if (index !== step) {
        actions.set_inactive()
      }
    })
    this.props.setActiveStep(step)
  }

  showSnack = ({messages, variant='error', duration=null}) => {
    snackbarDispatch.OPEN(
      {message: <ul>
        {messages.map((message, index) => (
          <li>{message}</li>
        ))}
      </ul>,
       variant: 'error',
       duration: null
     }
   )
  }

  validate = async (values) => {
    /*
      Validates all form data and returns either program information or
        reasons why the data is invalid.
      Triggers a snackbar message if invalid messages are relevant to current
        form.
    */
    let data = {}
    const state = store.getState()
    this.props.routes.forEach((route) => {
      const formData = route.module.selector(state)
      data = {...formData.entries, ...data}
    })

    if (this.props.editDraft) {
      data['editDraft'] = this.props.editDraft.id
    }

    const {response, result} = await window.IDA.api.warranties.create(data)
    const validated = result

    // if entire form is validated, set valid to true, otherwise set a msg
    this.props.setDraft(validated)
    if (!validated.valid && validated.messages) {
      this.showSnack({messages: validated.messages})
      return validated.valid
    }
    if (!validated.valid && validated.errors) {
      const currentFormKeys = Object.keys(values)
      const errorKeys = Object.keys(validated.errors)
      const relevantKeys = errorKeys.filter((key) => (currentFormKeys.includes(key)))
      if (relevantKeys.length > 0) {
        const relevantMessages = relevantKeys.map((key) => (
          validated.errors[key]
        ))
        this.showSnack({messages: relevantMessages})
        return false
      }
      else {
        return true
      }
    }
    return true
  }

  clearForm = () => {
    this.props.routes.forEach((route, index) => {
      const formDispatch = route.module.mapDispatch(store.dispatch)
      formDispatch.clear_state()
    })
    this.props.clear_state()
  }

  formPaths = this.props.routes.map((route, i) => {
    return route.path
  })

  formContainers = this.props.routes.map((route, index) => {
    const stepper = this
    return connect(route.module.mapState, route.module.mapDispatch)(
      class FormContainer extends Component {
        onSubmit = async (event) => {
          event.preventDefault()
          stepper.nextStep()
        }
        render = () => {
          return (
            <form noValidate autoComplete="new-password" onSubmit={this.onSubmit}>
              {this.props.children}
            </form>
          )
        }
      }
    )
  })
  formNextButtons = this.props.routes.map((route, index) => {
    const stepper = this;
    return connect(route.module.mapState, route.module.mapDispatch)(
      class NextButton extends Component {
        render = () => {
          let nextText = this.props.nextText;
          if (stepper.isFinalStep(index) && !!stepper.props.editDraft.id) {
            nextText = 'Apply Changes to Warranty'
          }
          return (
            <FormStepperNext xs={12} lg={5}>{nextText}</FormStepperNext>
          )
        }
      }
    )
  })

  formSteps = this.props.routes.map((route, index) => {
    return connect(route.module.mapState, route.module.mapDispatch)(
      (props) => {
        const {alternativeLabel, last, orientation, index, connector} = props
        const {active, completed, error, disabled} = props
        const moduleProps = {
          active,
          completed,
          disabled
        }
        const stepperProps = {
          alternativeLabel,
          last,
          orientation,
          connector
        }
        const onStepClick = async () => {
          this.goToStep(index)()
        }

        return (
          <Step
            key={props.key}
            index={index}
            className={classNames(
              style['step'],
              active && style['step-active'],
              completed && style['step-completed'],
              error && style['step-error'],
              disabled && style['step-disabled']
            )}
            {...stepperProps}
            {...moduleProps}>
            <StepButton
              onClick={onStepClick}
              >
              <StepLabel
                {...moduleProps}
                error={props.error}
                StepIconProps={{
                  ...moduleProps,
                  classes: {
                    root: style['step-icon'],
                    text: style['step-icon-text']
                  }
                }}
                classes={{
                  root: style['step-label'],
                  label: style['step-label-label'],
                }}
              >
                <FormSubtitle className={style['step-label']}>{props.name}</FormSubtitle>
              </StepLabel>
            </StepButton>
          </Step>
        )
      }
    )
  })

  isFinalStep = (step) => {
    return step === this.props.routes.length - 1;
  }

  goToStep = (step) => async () => {
    /*
      Manages validation sequence of entire form when a step is changed.
    */
    const activeStepModule = this.props.routes[this.props.activeStep].module
    const activeStep = {
      state: () => (activeStepModule.selector(store.getState())),
      schema: () => (schemaModule.selector(store.getState()).schemas[activeStepModule.slice]),
      path: this.props.routes[this.props.activeStep].path,
      dispatch: activeStepModule.mapDispatch(store.dispatch)
    }

    activeStep.dispatch.VALIDATE_FORM(activeStep.schema())
    activeStep.dispatch.SET_ALL_ERRORS()

    const activeStepState = activeStep.state()

    // Only do something if the current step is complete
    if (activeStepState.completed || this.props.activeStep > step) {
      // Try to find a valid program with current data
      const validProgram = await this.validate(activeStep.state().values)

      // if is last step, add to order
      if (this.isFinalStep(this.props.activeStep) && this.props.activeStep < step) {
        const editDraft = this.props.editDraft.id
        const {response, result} = await window.IDA.api.orders.create({editDraft})
        if (response.status === 200) {
          this.props.history.push(`/orders/${result.id}`)
          this.clearForm()
        }
        return
      }

      // Declare data for the designated step
      const nextStepModule = this.props.routes[step].module
      const nextStep = {
        state: () => (nextStepModule.selector(store.getState())),
        schema: () => (schemaModule.selector(store.getState()).schemas[nextStepModule.slice]),
        path: this.props.routes[step].path,
        dispatch: nextStepModule.mapDispatch(store.dispatch)
      }

      const nextStepState = nextStep.state()

      if (nextStepState.skip) {
        this.goToStep(step+1)()
      }
      else if (!nextStepState.locked) {
        this.props.setActiveStep(step)
        this.props.history.push(nextStep.path)
      }
    }
  }

  nextStep = () => {
    this.goToStep(this.props.activeStep+1)()
  }

  previousStep = () => {
    this.goToStep(this.props.activeStep-1)()
  }

  onKeyPress = (event) => {
    if (event.key === "Enter") {
      this.nextButton.current.click()
    }
  }

  resetForm = () => {
    this.clearForm()
    this.props.history.push(`/warranties/new`)
  }

  render() {
    const {activeStep } = this.props;

    const NextButton = this.formNextButtons[activeStep]
    const FormContainer = this.formContainers[activeStep]

    return (
        <Async>
        <FormContainer>
          <Grid container direction="row" justify="flex-end">
            <Grid item xs={12}>
              <Hidden mdDown>
                <Stepper className={style['stepper']} activeStep={activeStep} alternativeLabel nonLinear>
                  {this.formSteps.map((FormStep, index) => (
                    <FormStep key={index}/>
                  ))}
                </Stepper>
              </Hidden>
            </Grid>
            <Grid item xs={12}>
              <Switch>
                {this.props.routes.map((route, i) => {
                  return <Route key={i} {...route} ></Route>
                })}
              </Switch>
            </Grid>
            { activeStep !== 0 &&
              <Grid item md={1} className="text-align-left">
                <Button
                  disabled={activeStep === 0}
                  variant="text"
                  color="primary"
                  onClick={this.previousStep}
                >
                  Back
                </Button>
              </Grid>
            }

            <NextButton/>
            <Grid item xs={12} className="text-align-left">
              <ConfirmDialog
                title='Reset Warranty Form?'
                onConfirm={this.resetForm}
                component={(props)=>(
                  <Button
                    color="secondary"
                    {...props}
                  >
                    Reset Form
                  </Button>
                )}
                >
                  Are you sure you want to reset the warranty form?
              </ConfirmDialog>

            </Grid>
          </Grid>
        </FormContainer>
      </Async>
    );
  }
}

export default connect(mapState, mapDispatch)(withTheme()(withRouter(WarrantyStepper)))
