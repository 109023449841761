import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {persistor} from 'main/store/store';

import { withTheme } from '@material-ui/core/styles';
import Button from 'assets/Button';
import Grid from '@material-ui/core/Grid';

import Logo from 'assets/Logo';
import { mapState, mapDispatch } from './module';
import fonts from 'assets/scss/fonts.module.scss'
import style from './auth.module.css'

import Async from 'main/utils/async/Async'

import {Caption} from 'assets/Text'

class LogoutComponent extends Component {

  state = {
    isMounted: false
  }

  componentDidMount = async () => {
    this.setState({isMounted: true})
    this.props.logout()
    await persistor.purge()
    this.props.history.push('/')
    window.location.reload(true)
  }

  componentWillUnmount = () => {
    this.setState({isMounted: false})
  }

  render() {
    return (
      <Grid
        container direction="row" alignItems="stretch" justify="center"
        >
        <Grid item xs={10} sm={6} md={4} lg={3} xl={2}>
          <Async>
              <h5> Logging out...</h5>
          </Async>
        </Grid>
      </Grid>
    );
  }
}

export default connect(mapState, mapDispatch)(withRouter(withTheme()(LogoutComponent)));
