import {createModule} from 'main/utils/reducers';

const module = createModule({
  slice: 'Async',
  initialState: {
    requests: [],
    active: false,
    timer: 0,
    error: {},
    response: {},
  },
  reducers: {
    begin_request(state, action) {
      state.active = true
    },
    end_request(state, action) {
      state.active = false
      if (action.payload) {
        const {response, error} = action.payload
        state.response = response
        state.error = error
      }
    },
  }
});

export const { mapState, mapDispatch, slice, reducer, selector } = module

export default module.reducer
