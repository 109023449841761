import React, {Component} from 'react';
import {initializeForm} from 'warranties/forms/utilities'
import _ from 'underscore'

import FormGroup from 'assets/forms/FormGroup'
import {FormText, FormEmail, FormCheckbox, FormRadio, FormDate, FormAutocomplete, FormSelect} from 'assets/forms/FormInput'
import MomentUtils from '@date-io/moment';

import store from 'main/store/store'
import * as stepperModule from 'warranties/stepper/module';

const generateFormComponent = (formSpecs, schema) => {

  const FormComponent = class extends Component {

    state = {formSpecs, schema}

    constructor(props) {
      super(props)

      // Prevent a potential error-causing scenario where the back button is
      //  pressed and no draft is loaded (such as from the Order Summary)
      const currentState = store.getState()
      const stepperData = stepperModule.selector(currentState).data
      const stepperDispatch = stepperModule.mapDispatch(store.dispatch)

      if (props.history.action == "POP" && !stepperData.draft) {
          stepperDispatch.setActiveStep(0)
      }
    }

    componentDidMount() {
      this.props.set_active()
      // Set panel context
      initializeForm(this.props)

      // Create debounced validation functions for each field
      this.validationDebouncers = {}
      for (const [field, fieldSpec] of Object.entries(this.state.formSpecs)) {
        this.validationDebouncers[field] = _.debounce((resolve) => {
          this.props.VALIDATE_FORM()
          this.props.SET_FIELD_ERRORS(field)
          resolve(this.props.values[field])
        }, 250)
      }
    }

    componentWillUnmount() {
      this.props.set_inactive()
    }

    validateField = async (field) => {
      return new Promise((resolve) => (
        this.validationDebouncers[field](resolve)
      ))
    }

    onTextChange = (field) => async (event) => {
      const value = event.target.value
      this.props.set_value({field, value})
      return await this.validateField(field)
    }

    onEmailChange = (field) => async (event) => {
      const value = event.target.value
      let list = value.split(',')
      list = list.map((email) => (
        email.trim()
      ))
      list = list.filter((email) => (
        !!email
      ))

      this.props.set_email_value({field, value, list})
      return await this.validateField(field)
    }

    onCheckboxChange = (field) => async (event) => {
      const value = event.target.checked
      this.props.set_value({field, value})
      return await this.validateField(field)
    }

    onRadioChange = (field, value) => async (event) => {
      this.props.set_value({field, value})
      return await this.validateField(field)
    }

    onRadioClick = (field, value, nullable) => async (event) => {
      if (nullable && this.props.values[field].value === value) {
        this.props.set_value({field, value: null})
      }
      return await this.validateField(field)
    }

    onDateChange = (field) => async (date) => {
      const value = date
      this.props.set_date_value({field, value})
      return await this.validateField(field)
    }

    onDateInputChange = (field) => async (event) => {
      const moment = new MomentUtils()
      const value = event.target.value && moment.parse(event.target.value, 'MM/DD/YYYY')
      if (value && value.isValid()) {
        this.props.set_date_value({field, value})
      }
      else {
        this.props.set_date_value({field, value: null})
      }
      return await this.validateField(field)
    }

    onAutocompleteChange = (field) => async (value) => {
      this.props.set_autocomplete_value({field, value})
      return await this.validateField(field)
    }

    onSelectChange = (field) => async (event) => {
      const value = event.target.value
      this.props.set_select_value({field, value})
      return await this.validateField(field)
    }


    TextField = ({name, component=FormText,...props}) => {
      const Component = component
      const required = Object.values(this.state.formSpecs[name].joi.describe().flags).includes('required')
      return (
        <Component
          label={this.state.formSpecs[name].label}
          name={name}
          error={this.props.values[name].error}
          helperText={this.props.values[name].message}
          onChange={this.onTextChange(name)}
          value={this.props.values[name].value}
          required={required}
          {...props}
        />
      )
    }

    EmailField = ({name, component=FormEmail,...props}) => {
      const Component = component
      const required = Object.values(this.state.formSpecs[name].joi.describe().flags).includes('required')
      return (
        <Component
          label={this.state.formSpecs[name].label}
          name={name}
          error={this.props.values[name].error}
          helperText={this.props.values[name].message}
          onChange={this.onEmailChange(name)}
          value={this.props.values[name].value}
          required={required}
          {...props}
        />
      )
    }


    CheckboxField = ({name,...props}) => {
      const required = Object.values(this.state.formSpecs[name].joi.describe().flags).includes('required')
      return (
        <FormCheckbox
          checked={this.props.values[name].value}
          label={this.state.formSpecs[name].label}
          name={name}
          onChange={this.onCheckboxChange(name)}
          required={required}
          {...props}
        />
      )
    }

    RadioField = ({name, value, nullable, ...props}) => {
      const required = Object.values(this.state.formSpecs[name].joi.describe().flags).includes('required')
      return (
        <FormRadio
          checked={this.props.values[name].value === value}
          label={this.state.formSpecs[name].label}
          name={name}
          onClick={this.onRadioClick(name, value, nullable)}
          onChange={this.onRadioChange(name, value)}
          required={required}
          {...props}
        />
      )
    }

    DateField = ({name,...props}) => {
      const required = Object.values(this.state.formSpecs[name].joi.describe().flags).includes('required')
      return (
        <FormDate
          label={this.state.formSpecs[name].label}
          name={name}
          error={this.props.values[name].error}
          helperText={this.props.values[name].message}
          onChange={this.onDateChange(name)}
          onInputChange={this.onDateInputChange(name)}
          value={this.props.values[name].value}
          required={required}
          {...props}
        />
      )
    }

    AutocompleteField = ({name, ...props}) => {
      const required = Object.values(this.state.formSpecs[name].joi.describe().flags).includes('required')

      return (
        <FormAutocomplete
          label={this.state.formSpecs[name].label}
          name={name}
          error={this.props.values[name].error}
          helperText={this.props.values[name].message}
          onChange={this.onAutocompleteChange(name)}
          value={this.props.values[name].value}
          required={required}
          suggestions={this.state.formSpecs[name].suggestions}
          {...props}
        />
      )
    }

    SelectField = ({name, ...props}) => {
      const required = Object.values(this.state.formSpecs[name].joi.describe().flags).includes('required')
      return (
        <FormSelect
          value={this.props.values[name].value}
          helperText={this.props.values[name].message}
          error={this.props.values[name].error}
          label={this.state.formSpecs[name].label}
          name={name}
          onChange={this.onSelectChange(name)}
          required={required}
          {...props}
        />
      )
    }
  }

  return FormComponent
}

export default generateFormComponent
