import {createModule} from 'main/utils/reducers';

const module = createModule({
  slice: 'ContextPanel',
  initialState: {
    leftPanel: {
      title: '',
      content: ''
    },
    rightPanel: {
      title: '',
      content: ''
    },
    leftHeader: '',
    pendingOrders: []
  },
  reducers: {
    set_left_panel(state, action) {
      const {title, content} = action.payload

      state.leftPanel.title = title
      state.leftPanel.content = content
    },
    set_right_panel(state, action) {
      const {title, content} = action.payload

      state.rightPanel.title = title
      state.rightPanel.content = content
    },
    set_left_header(state, action) {
      state.leftHeader = action.payload
    }
  },
  extraReducers: {
    'Auth/set_user': (state, action) => {
      const {config} = action.payload
      if (config) {
        const orders = config.open_orders
        state.pendingOrders = orders
      }
    }
  }
})

export const { mapState, mapDispatch, slice, reducer, selector } = module

export default module.reducer
