import React from 'react';
import store from 'main/store/store'

import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import FastForwardIcon from '@material-ui/icons/FastForward';

import {setWarrantyFromDraft} from 'warranties/stepper/utility'

import style from 'assets/drawers/drawers.module.scss'

class DevDrawer extends React.Component {
  state = {
    open: false,
  };

  toggleDrawer = (open) => () => {
    this.setState({
      open: open,
    });
  };

  setRandomWarranty = async () => {

    const {response, result} = await window.IDA.api.getRandomWarranty()
    const draft = result;
    setWarrantyFromDraft({draft})
  }

  render() {
    const isDevEnv = process.env.REACT_APP_DEV_PANEL_ON === 'true'
    const sideList = (
      <div>
        <ListSubheader>Developer Tools</ListSubheader>
        <List subheader={<ListSubheader>Warranty Form</ListSubheader>}>
            <ListItem id='ida-tools-fill-data' onClick={this.setRandomWarranty} button>
              <ListItemIcon><FastForwardIcon/></ListItemIcon>
              <ListItemText primary='Fill Random Form Data' />
            </ListItem>
        </List>
      </div>
    );

    if (isDevEnv) {
      return (
        <div>
          <Button id='ida-tools' onClick={this.toggleDrawer(true)}>IDA Tools</Button>

          <Drawer
            classes={{
              'paper': style['sidebar']
            }}
            anchor="right"
            variant="persistent"
            open={this.state.open}
            onClose={this.toggleDrawer(false)}
           >
            <div
              className={style['sidebar-list']}
              tabIndex={0}
              role="button"
              onClick={this.toggleDrawer(false)}
              onKeyDown={this.toggleDrawer(false)}
            >
              {sideList}
            </div>
          </Drawer>
        </div>
      );
    }
    else {
      return null;
    }
  }
}


export default (DevDrawer);
