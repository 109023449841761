import React from 'react';
import { connect } from 'react-redux'
import * as module from './module';
import * as summaryModule from 'warranties/forms/summary/module'
import store from 'main/store/store'

import Grid from '@material-ui/core/Grid';

import style from '../forms.module.scss'

import {form as formSpecs, schema} from './form'
import generateFormComponent from 'warranties/forms/FormComponent'
import {setWarrantyFromDraft} from 'warranties/stepper/utility'


const summaryDispatch = summaryModule.mapDispatch(store.dispatch)
const FormComponent = generateFormComponent(formSpecs, schema)


class DealershipFormComponent extends FormComponent {

  onDealerChange = async (value) => {
    const field = 'dealer_name'
    await this.onAutocompleteChange(field)(value)
    // Update state with new info

    if (!value.__isNew__) {
      const values = {
        'dealer_street': value.location.street,
        'dealer_city': value.location.city,
        'dealer_zip': value.location.zip,
        'dealer_state': value.location.state,
        'dealer_email': value.emails.join(',')
      }
      setWarrantyFromDraft({draft: {...this.props.entries, ...values}, onlySpecified: true})
      summaryDispatch.set_value({field: 'email_send_to_dealer', value: value.send_warranty_emails})
      this.props.VALIDATE_FORM()
      this.props.SET_ALL_ERRORS()
    }
  }

  render = () => {
    const TextField = this.TextField
    const StatesField = this.AutocompleteField
    const DealerField = this.AutocompleteField
    const EmailField = this.EmailField

    const promiseOptions = async (inputValue) => {
      const {response, result } = await window.IDA.api.dealers.getByName({name: inputValue})
      return result
    }

    return (
      <Grid container spacing={24} className={style['form']}>
        <Grid item md={12}>
          <h6>Enter Dealership Information</h6>
        </Grid>
        <DealerField
          async
          creatable
          required
          loadOptions={promiseOptions}
          xs={12}
          lg={8}
          name='dealer_name'
          onChange={this.onDealerChange}
          formatCreateLabel={(value) => (`Create New Dealer: ${value}`)}
          isValidNewOption={(value, current, options) => {
            const currentValues = options.map(({value}) => (value.trim().toLowerCase()))
            const formattedValue = value.trim().toLowerCase()

            return !currentValues.includes(formattedValue) && !!formattedValue && formattedValue.length > 2
          }}
        />
        <TextField required xs={6} lg={5} name='dealer_street'/>
        <TextField required xs={6} lg={3} name='dealer_city'/>
        <StatesField required xs={6} lg={2} name='dealer_state'/>
        <TextField required xs={6} lg={2} name='dealer_zip'/>
        <EmailField xs={12} lg={5} name='dealer_email'/>

      </Grid>
    )
  }
}
const form = connect(module.mapState, module.mapDispatch)(DealershipFormComponent)
export {module, form}
export default form
