import React from 'react';
import ReactDOMServer from 'react-dom/server';
import {createModule} from 'main/utils/reducers';
import commonReducers from '../commonReducers'

import {form, schema, defaults as formDefaults} from './form'


const markup = ReactDOMServer.renderToStaticMarkup

const module = createModule({
  slice: 'FormModifications',
  initialState: {
    name: 'Modifications',
    nextText: 'Proceed to Step 5: Warranty',
    active: false,
    completed: false,
    error: false,
    disabled: true,
    locked: false,
    skip: false,
    message: '',
    optional: false,
    entries: {},
    valueDefaults: formDefaults,
    values: formDefaults,
    context: {
      left: {
        title: markup(<p>Step 4: <br/>Modifications</p>),
        content: markup(
          <div>
            <p>Select the Modifications that were done to the vehicle.</p>
            <p>Choose from Suspension, Engine, Tire, Wheel, Exhaust, and Accessory Modifications.</p>
            <p>Modification information will be attached to your warranty.</p>
          </div>
        )
      },
      right: {
        title: markup('Modification Not Listed?'),
        content: markup(
  <p>Contact IDA to see if we can add your modification to our coverage.</p>
        )
      }
    }
  },
  reducers: {
    ...commonReducers(schema),

  }
})

export const { mapState, mapDispatch, slice, reducer, selector } = module
export {form, schema, formDefaults}

export default module.reducer
