import React, { Component } from 'react';
import { connect } from 'react-redux'

import { Redirect, Switch } from 'react-router-dom'

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import { mapState, mapDispatch } from './module';

import style from './warranties.module.scss'
import {H6, Caption} from 'assets/Text'

import Paper from 'assets/panels/Panel'

// Control Context Panel
import store from 'main/store/store'
import {mapDispatch as contextPanelMapDispatch} from 'main/utils/contextPanel/module'


// Stepper
import Stepper from './stepper/Stepper'

const contextPanelDispatch = contextPanelMapDispatch(store.dispatch)


class CreateWarrantyComponent extends Component {
  constructor(props) {
    super(props)
    contextPanelDispatch.set_left_header('Create New Warranty')
  }

  page = "Create New Warranty"

  render() {
    return (
      <Grid className={style['container']} container direction="row" justify="flex-start" spacing={40}>
        <Grid item xs={12}>
          <Stepper activeStep={this.activeStep} routes={this.props.routes}>
          </Stepper>
        </Grid>
      </Grid>
    );
  }
}

export default connect( mapState, mapDispatch)(CreateWarrantyComponent);
