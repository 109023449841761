import React from 'react'

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import {ButtonText} from 'assets/Text'

import style from './form.module.scss'

const types = {
  'button': ButtonText,
  'submit': ButtonText,
}

const FormButtonComponent = ({
  xs,
  sm,
  md,
  lg,
  xl,
  type,
  children,
  ...props
}) => {
  const breakpoints = {xs, sm, md, lg, xl}
  const Component = types[type]

  return (
    <Grid item {...breakpoints}>
      <Button type={type} className={style['form-button']} {...props}>
        <Component>{children}</Component>
      </Button>
    </Grid>
  )
}

export const FormStepperNext = (props) => (
  <FormButtonComponent
    type='submit'
    variant="contained"
    color="secondary"
    fullWidth={true}
    variant='contained'
    className={style['stepper-next-button']}
    {...props}
  />
)

export default FormButtonComponent
