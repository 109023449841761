import React, { Component } from 'react';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';

import { connect } from 'react-redux'
import { mapState, mapDispatch } from './module';

import {snackbarStyle} from 'main/utils/theme'

class IDASnackComponent extends Component {
  variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
  }

  render = () => {
    const { classes, message, variant, duration } = this.props;
    const Icon = this.variantIcon[variant];

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={this.props.open}
        autoHideDuration={duration}
        onClose={this.props.CLOSE}
      >
        <SnackbarContent
          className={classes[variant]}
          message={
            <Grid container className={classes.message}>
              <Grid item xs={2}>
                <Icon className={classNames(classes.icon, classes.iconVariant)} />
              </Grid>
              <Grid item xs={9}>
                {message}
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  className={classes.close}
                  onClick={this.props.CLOSE}
                >
                  <CloseIcon className={classes.icon} />
                </IconButton>
              </Grid>
            </Grid>
          }
        />
      </Snackbar>
    )
  }
}

export default connect(mapState, mapDispatch)(withStyles(snackbarStyle)(IDASnackComponent))
