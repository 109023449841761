import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import { mapState, mapDispatch } from './module';

import { withTheme } from '@material-ui/core/styles';
import Button from 'assets/Button';
import Grid from '@material-ui/core/Grid';
import style from './auth.module.css'
import Logo from 'assets/Logo';
import Async from 'main/utils/async/Async'
import PasswordField from 'assets/input/PasswordField'
import {Caption} from 'assets/Text'

class PasswordResetConfirmComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pass: '',
      errors: [],
      success: false
    }
  }

  onPasswordChange = (e) => {
    this.setState({pass: e.target.value})
  }

  onSubmit = async (e) => {
    e.preventDefault()
    const new_password = this.state.pass
    const token = this.props.match.params.token
    const uid = this.props.match.params.uid
    const {response, result} = await window.IDA.api.passwordResetConfirm({new_password, token, uid})
    switch (response.status) {
      case 400:
        const errors_pass = result['new_password'] || []
        const errors_token = (result['token'] && ['Invalid reset link.  Please click Forgot Password again to refresh.']) || []
        const errors_uid = result['uid'] && ['Invalid reset link.  Please click Forgot Password again to refresh.'] || []
        const errors = errors_pass.concat(errors_token).concat(errors_uid)
        this.setState({errors})
        break;
      case 204:
        this.props.set_messages(['Your password has been successfully reset.  You may login with your new password.'])
        this.setState({success: true})
        break;
      default:
        this.setState({errors: ['Unable to reset Password']})
        throw(`Unable to reset password.  Response ${response.status}.`)
        break;
    }
  }

  render() {
    if (this.state.success) {
      return <Redirect push={false} to='/login/showmessage' />
    }
    return (
      <Grid
        container direction="row" alignItems="stretch" justify="center"
        >
        <Grid item xs={12} className="App-header">
          <Button href='/'>
            <Logo />
          </Button>
        </Grid>
        <Grid item xs={10} sm={6} md={4} lg={3} xl={2}>
          <Async>
            <form className={style.form} onSubmit={this.onSubmit} autoComplete="new-password">
              <h5> Password Reset </h5>
              {this.state.errors.map((message, key) => (
                <Caption key={key} style={{color: this.props.theme.palette.primary.error}}>
                  {message}
                </Caption>
              ))}
              <Grid item>
                <PasswordField
                  name='pass'
                  required={true}
                  onChange={this.onPasswordChange}
                  value={this.state.pass}
                  disabled={this.props.asyncState.active}
                  label='New Password'
                />
              </Grid>
              <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-end"
                >
                <Button disabled={this.props.asyncState.active} type="submit" color="primary">Change Password</Button>
              </Grid>
            </form>
          </Async>
        </Grid>
      </Grid>
    );
  }
}

export default connect(mapState, mapDispatch)(withRouter(withTheme()(PasswordResetConfirmComponent)));
