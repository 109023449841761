import React from 'react';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import style from 'assets/panels/panel.module.scss'
import {PanelTitle} from 'assets/Text.jsx'
import classNames from 'classnames'

const PaperComponent = ({title, noBg, noBorder, accentBorder, ...props}) => (
  <Paper className={classNames(style['container'], noBg ? style['no-bg'] : '', noBorder && style['no-border'], accentBorder && style['accent-border'])}>
    {title &&
      <div>
        <PanelTitle className={style['title']}>{title}</PanelTitle>
        <Divider />
      </div>
    }
    <div className={style['content']}>
      {props.children}
    </div>
  </Paper>
)

export default PaperComponent
