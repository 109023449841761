import {createModule} from 'main/utils/reducers';
import produce from "immer"

const module = createModule({
  slice: 'WarrantySteps',
  initialState: {
    activeStep: 0,
    data: {
      valid: false
    },
    editDraft: {}
  },
  reducers: {
    clear_state(state, action) {
      state.activeStep = 0
      state.data = {
        valid: false
      }
      state.editDraft = {}
    },
    setActiveStep(state, action) {
      state.activeStep = action.payload
    },
    setDraft(state, action) {
      state.data = action.payload
    },
    set_edit_draft(state, action) {
      state.editDraft = action.payload
    }
  },
  extraReducers: {
    'FormDealership/set_active': (state, action) => {

    }
  }
})

export const { mapState, mapDispatch, slice, reducer, selector } = module

export default module.reducer
