import { createSlice } from 'redux-starter-kit'
import { bindActionCreators } from 'redux'

const createModule = ({
  setAsyncState=false,
  ...options
}) => {
  /*
    A wrapper for automagically configuring a redux-connected module
    Options:
      slice (str): The string index for the redux store that state
        will be stored under.
      initialState (object): Initial state to be initialized in redux
      reducers (object):  An object of reducer functions in the format:

        key : function(state, action)

        where state is an "immer'd" copy of the immutable state that is to be
          updated with the new state
        where action contains the action name and payload
        and the return value is not needed.  (modify the state directly)

    Returns:
      This returns an object containing:
        slice: The named redux index where state is stored
        reducer:  Combined reducer function for all reducers
        mapState:  Returns the redux state.  Needed for connect()
        mapDispatch:  Returns an action mapping.  Needed for connect()
        selector:  Returns the relative slice'd state from the redux store
  */
  const {extraReducers={}, initialState={}, ...otherOptions} = options
  const logout = (state, action) => {
    return initialState
  }
  extraReducers['Auth/logout'] = logout

  const module = createSlice({extraReducers, initialState, ...otherOptions})
  const { actions, reducer, selectors, slice } = module
  const selector = selectors['get' + slice]

  const mapState = (state, ownState) => {
    const nextState = selector(state)
    const returnState = {...nextState}

    if (setAsyncState) {
      returnState.asyncState = state.Async
    }

    return returnState
  }

  const mapDispatch = (dispatch, ownState) => {
    const actionCreators = bindActionCreators(actions, dispatch)

    return actionCreators
  }

  return {slice, reducer, mapState, mapDispatch, selector}
}

export { createModule }
