import React, { Component } from 'react';
import { mapState, mapDispatch } from 'auth/module';
import { connect } from 'react-redux'

import styles from './nav.module.scss'
import classes from 'classnames'

import Grid from '@material-ui/core/Grid';
import logo from 'static/logo_small.png';

import { NavLink } from 'react-router-dom'
import { withRouter } from "react-router";


import font from 'assets/scss/fonts.module.scss'

import UserWidget from './user'

import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';

import {H6, Caption} from 'assets/Text'

import AccountCircle from '@material-ui/icons/AccountCircle';

import routes from 'main/routes'

const NavButton = ({to, children, exact, disabled}) => (
  <Button color="primary" className={styles['nav-button']} component={NavLink} disabled={disabled} exact={exact} to={to}>{children}</Button>
)

const navList = routes.filter((route) => (route.nav)).map((route) => {
  return {text: route.name, url: route.path, exact:!!route.exact, disabled: route.disabled}
})

const menu = navList.map((link, index) => {
  return <NavButton key={index} exact={link.exact} to={link.url} disabled={link.disabled} >{link.text}</NavButton>
})


const MobileDrawer = (props) => (
  <List {...props}>
    {navList.map((link, index) => (
      <ListItem key={index}>
        <NavButton to={link.url}>{link.text}</NavButton>
      </ListItem>
    ))}
  </List>
)

class Navigation extends Component {

  state = {
    mobileMenuOpen: false
  }



  toggleMobileMenu = (open) => () => {
    this.setState({
      mobileMenuOpen: open
    })
  }

  render() {
    return (
      <div>
        <Grid className={styles['nav-desktop']} container alignItems="stretch" justify="center">
          <Grid item xs={3}>
            <Grid container direction="row" alignItems="center" justify="center">
              <NavLink to='/'>
                <img src={logo} className={styles.appLogo} alt="logo" />
              </NavLink>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid className={styles.navLinks} container alignItems="center" justify="space-around">
              {menu}
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <UserWidget/>
          </Grid>
        </Grid>
        <AppBar className={styles['nav-mobile']} position="static" color="secondary">
          <Toolbar>
            <IconButton onClick={this.toggleMobileMenu(true)} color="inherit" aria-label="Menu">
              <MenuIcon />
            </IconButton>
            <H6>IDA</H6>
            <div className="flex-grow"/>
            <div>
              <IconButton color="inherit">
                <Caption>{this.props.user.company}</Caption>
                <AccountCircle />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer open={this.state.mobileMenuOpen} onClose={this.toggleMobileMenu(false)}>
          <MobileDrawer onClick={this.toggleMobileMenu(false)}/>
        </Drawer>
      </div>
    )
  }
}

export default connect(mapState, mapDispatch)(withRouter(Navigation))
