import React, {Component} from 'react';

import SyncSelect  from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';

import style from './input.module.scss'
import fonts from 'assets/scss/fonts.module.scss'


const styles = theme => ({
  root: {
    flexGrow: 1,
    //height: 250,
  },
  input: {
    display: 'flex',
    //padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    '& div': {
      padding: 0,
      margin: 0
    }
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    'line-height': 'inherit',
    'white-space': 'nowrap'
  },
  paper: {
    position: 'absolute',
    zIndex: 5,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
  disabled: {

  }
});


class AutocompleteFieldComponent extends Component {
  NoOptionsMessage(props) {
    return (
      <Typography
        color="textSecondary"
        className={props.selectProps.classes.noOptionsMessage}
        {...props.innerProps}
      >
        {props.children}
      </Typography>
    );
  }

  inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props}/>;
  }

  state = {
    controlValue: ''
  }

  onControlChange = (event) => {
    const controlValue = event.target.value
    this.setState({controlValue})
  }
  onControlBlur = (event) => {
    const controlValue = ''
    this.setState({controlValue})
  }

  Control = (props) => {
    return (
      <TextField
        margin = "normal"
        variant = "outlined"
        className = {props.selectProps.className}
        fullWidth
        error={props.selectProps.error}
        helperText={props.selectProps.helperText}
        label={props.selectProps.label}
        InputLabelProps = {{className: fonts['textfield-label'], shrink: !!this.state.controlValue || !!props.selectProps.value}}
        FormHelperTextProps={{className: style['formhelper-text']}}
        value={this.state.controlValue}
        required={props.selectProps.required}
        name={props.selectProps.name}
        onChange={this.onControlChange}
        onBlur={this.onControlBlur}
        InputProps={{
          inputComponent: this.inputComponent,
          className: style['textfield'],
          inputProps: {
            className: classNames(fonts['textfield'], props.selectProps.classes.input),
            inputRef: props.innerRef,
            children: props.children,
            ...props.innerProps,
          },
          ...props.selectProps.InputProps

        }}
        {...props.selectProps.textFieldProps}
      />
    );
  }

  Option(props) {
    return (
      <MenuItem
        buttonRef={props.innerRef}
        selected={props.isFocused}
        component="div"
        style={{
          fontWeight: props.isSelected ? 500 : 400,
        }}
        {...props.innerProps}
      >
        {props.children}
      </MenuItem>
    );
  }

  Placeholder(props) {
    return null
  }

  SingleValue(props) {
    return (
      <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
        {props.children}
      </Typography>
    );
  }

  ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
  }

  MultiValue(props) {
    return (
      <Chip
        tabIndex={-1}
        label={props.children}
        className={classNames(props.selectProps.classes.chip, {
          [props.selectProps.classes.chipFocused]: props.isFocused,
        })}
        onDelete={props.removeProps.onClick}
        deleteIcon={<CancelIcon {...props.removeProps} />}
      />
    );
  }

  Menu(props) {
    return (
      <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
        {props.children}
      </Paper>
    );
  }

  render = () => {
    const { classes, theme } = this.props;
    const {disabled, otherClasses} = classes;
    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',

        },
      }),
      indicatorsContainer: (base) => ({
        ...base,
        '& div': {
          padding: '0 8px'
        }
      }),
      indicatorSeparator: (base) => ({
        ...base,
        margin: 0,
      })
    };


    const components = {
      Control: this.Control,
      Menu: this.Menu,
      MultiValue: this.MultiValue,
      NoOptionsMessage: this.NoOptionsMessage,
      Option: this.Option,
      Placeholder: this.Placeholder,
      SingleValue: this.SingleValue,
      ValueContainer: this.ValueContainer,
    };

    const AsyncField = this.props.creatable ? AsyncCreatableSelect : AsyncSelect
    return (
      <FormControl variant="outlined" fullWidth={true}>
        <NoSsr>
          {(this.props.async &&
            <AsyncField
              classes={classes}
              styles={selectStyles}
              //options={this.props.suggestions}
              loadOptions={this.props.loadOptions}
              defaultOptions
              cacheOptions
              formatCreateLabel={this.props.formatCreateLabel}
              isValidNewOption={this.props.isValidNewOption}
              components={components}
              value={this.props.value}
              required={this.props.required}
              error={this.props.error}
              helperText={this.props.helperText}
              onChange={this.props.onChange}
              name={this.props.name}
              label={this.props.label}
              className={this.props.className}
              state={this.props.state}
              isDisabled={this.props.disabled}
              InputProps={this.props.InputProps}
             >
            </AsyncField>
          ) ||
          <SyncSelect
            classes={classes}
            styles={selectStyles}
            options={this.props.suggestions}
            components={components}
            value={this.props.value}
            required={this.props.required}
            error={this.props.error}
            helperText={this.props.helperText}
            onChange={this.props.onChange}
            name={this.props.name}
            label={this.props.label}
            className={this.props.className}
            state={this.props.state}
            isDisabled={this.props.disabled}
            InputProps={this.props.InputProps}
           >
          </SyncSelect>
        }
        </NoSsr>
      </FormControl>
    )
  }
}

export default withStyles(styles, { withTheme: true })(AutocompleteFieldComponent)
