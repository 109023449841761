import React from 'react'
import TextField from './TextField'

const PasswordFieldComponent = ({
  label = 'Password',
  type = 'password',
  name = 'password',
  autoComplete = 'password',
  ...props
}) => (<TextField {...{label, type, name, autoComplete, ...props}} />)


export default PasswordFieldComponent
