import settings from './settings'
import store from 'main/store/store'
import {mapDispatch as asyncMapDispatch} from 'main/utils/async/module'
import {mapDispatch as authMapDispatch} from 'auth/module'

import {selector} from 'auth/module'
import * as token from 'auth/token'

const hostname = window.location.hostname
const port = settings.wsPort ? `:${settings.wsPort}` : ''

const protocol = window.location.protocol.includes('https') ? 'wss://' : 'ws://'
const WSbase = `${protocol}${hostname}${port}/${settings.wsBase}/${settings.version}`
const Async = asyncMapDispatch(store.dispatch)


const getAccessToken = async () => {
  let authState = selector(store.getState())
  const isAuthenticated = await token.isAuthenticated(authState)
  if (isAuthenticated) {
    authState = selector(store.getState())
    return token.accessToken(authState)
  }
  else {
    console.error('websocket not authorized')
  }
}

const doGet = async ({url, data, options, auth=true}) => {
  let result
  let response
  //Async.begin_request()


  if (auth) {
    const authToken = await getAccessToken()
    var socket = new WebSocket(
      `${WSbase}${url}?${authToken}`
    );

    return socket
  }
}

const wsGet = ({url, data={}, auth=true}) => {
  const options = {

  }
  return doGet({url, data, options, auth})
}


export {wsGet}
