import {createModule} from 'main/utils/reducers';

const module = createModule({
  slice: 'FormSchema',
  initialState: {
    schemas: {}
  },
  reducers: {
    set_schema(state, action) {
      const {form, schema} = action.payload
      state['schemas'][form] = schema
    }
  }
})

export const { mapState, mapDispatch, slice, reducer, selector } = module

export default module.reducer
