import React, { Component } from 'react';
import { connect } from 'react-redux'
import store from 'main/store/store'
import {mapDispatch as contextPanelMapDispatch} from 'main/utils/contextPanel/module'

import { mapState, mapDispatch } from './module';
import style from '../orders.module.scss'

import Grid from '@material-ui/core/Grid';

import {Table, Row, Header, Cell} from 'assets/tables/Table'

import Button from 'assets/Button'
import FormButton from 'assets/forms/FormButton';
import {NavButton} from 'assets/Button'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import {Caption, Subtitle2} from 'assets/Text'

import moment from 'moment'
import _ from 'lodash'

const contextPanelDispatch = contextPanelMapDispatch(store.dispatch)

class OrderListComponent extends Component {
  componentDidMount = async () => {
    contextPanelDispatch.set_left_panel(this.props.context.left)
    contextPanelDispatch.set_left_header('Orders')
    contextPanelDispatch.set_right_panel(this.props.context.right)
    const request = await window.IDA.api.orders.list({offset: 0, limit: this.props.orders.limit})
    this.props.set_orders(request.result)
  }

  getParams = () => {
    const {offset, limit, search, ordering, completed_before, completed_after} = {...this.props.orders}
    return {offset, limit, search, ordering, completed_before, completed_after}
  }

  onPaginate = async ({offset, limit}) => {
    const params = {...this.getParams(), offset, limit}
    const request = await window.IDA.api.orders.list(params)
    this.props.set_orders(request.result)
    this.props.set_page({offset, limit})
  }

  onSearchDebounced = _.debounce(async (value) => {

    const params = {...this.getParams(), offset: 0, search: value}

    const request = await window.IDA.api.orders.list(params)
    this.props.set_page({offset: 0, limit: params.limit})
    this.props.set_orders(request.result)

  }, 500)

  onSearch = (e) => {
    const value = e.target.value
    this.props.set_search(value)
    this.onSearchDebounced(value)
  }

  onFromDate = async (moment) => {
    this.props.set_from_date(moment)
    const params = {...this.getParams(), completed_after: moment && moment.startOf('day').toISOString(true)}
    const request = await window.IDA.api.orders.list(params)
    this.props.set_orders(request.result)
  }

  onToDate = async (moment) => {
    this.props.set_to_date(moment)
    const params = {...this.getParams(), completed_before: moment && moment.endOf('day').toISOString(true)}
    const request = await window.IDA.api.orders.list(params)
    this.props.set_orders(request.result)
  }

  onOrder = async (newOrderingString) => {
    const params = {...this.getParams(), ordering: newOrderingString}
    const request = await window.IDA.api.orders.list(params)
    this.props.set_orders(request.result)
    this.props.set_ordering(newOrderingString)
  }

  onClear = async () => {
    this.props.clear_search()
    const request = await window.IDA.api.orders.list({offset: 0, limit: this.props.orders.limit})
    this.props.set_orders(request.result)
  }

  onExport = async () => {
    this.props.set_exporting(true)
    const params = this.getParams()
    const request = await window.IDA.api.orders.report(params)
    const blob = await request.response.blob()
    const objectURL = URL.createObjectURL(blob);

    var a = document.createElement("a");
    a.style = "display: none";
    document.body.appendChild(a);
    a.href = objectURL
    a.download = 'report.csv'
    a.click()
    URL.revokeObjectURL(objectURL)
    this.props.set_exporting(false)
  }

  render() {
    const headers = [
      {
        name: 'Date',
        get: (row) => {
          const completed = moment(row.completed || row.created)
          return completed.format('L');
        },
        orderByField: 'completed',
        grid: {xs: 4, md: 2}
      },
      {
        name: 'Order #',
        get: (row) => {
          return `#${row.client_order_number}`
        },
        orderByField: 'id',
        grid: {xs: 4, md: 2}
      },
      {
        name: '# of Warranties',
        get: (row) => {
          return row.warranties.length
        },
        orderByField: 'warranties_item_count',
        grid: {xs: 4, md: 2}
      },
      {
        name: 'Total',
        get: (row) => {
          return row.total
        },
        orderByField: 'total',
        grid: {xs: 4, md: 2}
      },
      {
        name: 'Status',
        get: (row) => {
            switch(row.status) {
              case 'paid':
                return (
                  <NavButton color="secondary" to={`/orders/${row.id}/receipt`}>
                    <Caption>PAID</Caption>
                  </NavButton>
                )
                break;
              case 'pending':
                return (
                  <NavButton disabled color="secondary" to={`/orders/${row.id}`}>
                    <Caption>PROCESSING</Caption>
                  </NavButton>
                )
                break;
              case 'failed':
                return (
                  <NavButton color="secondary" to={`/orders/${row.id}/receipt`}>
                    <Caption>FAILED PAYMENT</Caption>
                  </NavButton>
                )
                break;
              case 'unpaid':
                return (
                  <NavButton color="secondary" to={`/orders/${row.id}`}>
                    <Caption>OPEN ORDER</Caption>
                  </NavButton>
                )
                break;

              default:
                break;
          }
        },
        orderByField: 'status',
        grid: {xs: 4, md: 2}
      },
      {
        name: 'Receipt',
        get: (row) => {
          return (row.status == 'paid' &&
            <NavButton
              color="secondary"
              to={`/orders/${row.id}/receipt`}
              //onClick={this.handleOrder}
            >
              <CloudDownloadIcon/>
            </NavButton>
          )
        },
        grid: {xs: 6, md: 2}
      },
    ]

    return (
      <Grid container direction="row" justify="center" spacing={16}>
        <Grid item xs={12}>
          <h5>My Orders</h5>
        </Grid>
        <Grid item xs={12}>
          <Table
            headers={headers}
            data={this.props.orders}
            onSearch={this.onSearch}
            onFromDate={this.onFromDate}
            onToDate={this.onToDate}
            onPaginate={this.onPaginate}
            onOrder={this.onOrder}
            onClear={this.onClear}
            onExport={this.onExport}
          />
        </Grid>
      </Grid>
    )
  }
}

export default connect(mapState, mapDispatch)(OrderListComponent)
