import {createModule} from 'main/utils/reducers';
import React from 'react';
import ReactDOMServer from 'react-dom/server';

const markup = ReactDOMServer.renderToStaticMarkup

const module = createModule({
  slice: 'OrderDetail',
  initialState: {
    order: {
      total: 0,
      warranties: []
    },
    payment: {
      open: false,
      messages: [],
      error: false,
      squareFormLoaded: false,
      squareCard: undefined
    },
    context: {
      left: {
        title: markup(<p>Order<br/>Summary</p>),
        content: markup(
          <div>
            <p>All your pending warranties are listed to the right.</p>
            <p>Confirm the warranty order information is correct and click "Proceed to Payment".</p>
          </div>
        )
      },
      right: {
        title: markup('Need to Make Changes?'),
        content: markup(
  <p>Click "Details" to view warranty details, edit a warranty, or to remove a warranty order.</p>
        )
      }
    }
  },
  reducers: {
    set_order: (state, action) => {
      state.order = action.payload
    },
    open_payment:  (state, action) => {
      state.payment.open = true
    },
    close_payment:  (state, action) => {
      state.payment.open = false
      state.messages = []
      state.error = false
      state.payment.squareFormLoaded = false
      state.payment.squareCard && state.payment.squareCard.destroy()
      state.payment.squareCard = undefined
    },
    set_error: (state, action) => {
      state.payment.error = true
      state.payment.messages = action.payload
    },
    clear_error: (state, action) => {
      state.payment.error = false
      state.payment.messages = []
    },
    load_square_form: (state, action) => {
      state.payment.squareCard = action.payload
      state.payment.squareFormLoaded = true
    }
  }
})

export const { mapState, mapDispatch, slice, reducer, selector } = module

export default module.reducer
